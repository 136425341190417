import { AppMetadata } from '../entities/app';
import { createHeaders, AuthTokenSet } from './utils';
import { z } from 'zod';
import { appZod, parseBackendApp } from '../entities/app';
import { env } from '@/env';
import { TagSlug } from '../entities/tags';

interface AppSearchFilter {
  queryText?: string;
  tags?: TagSlug[];
}

const searchResponseZod = z.array(appZod);

export const searchApp = async (
  auth: AuthTokenSet,
  filter?: AppSearchFilter,
): Promise<AppMetadata[]> => {
  const getParams = new URLSearchParams();
  if (filter?.queryText) {
    getParams.append('search', filter?.queryText);
  }
  for (const tag of filter?.tags || []) {
    getParams.append('tags', tag);
  }
  const response = await fetch(`${env.catalogUrl}v1/application/?${getParams}`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (response.status >= 400) {
    throw response;
  }
  const data = searchResponseZod.parse(await response.json());
  return data.map(parseBackendApp);
};
