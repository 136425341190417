import errorSvg from '@/assets/error.svg';
import { forwardRef, ReactNode } from 'react';
import { P } from '@/components/text/P';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';

interface Props {
  title?: string;
  message?: string;
  cta?: ReactNode;
}

export const FatalErrorModalWindow = forwardRef(
  ({ title, message, cta }: Props, forwardedRef): ReactNode => {
    return (
      <ModalWindow ref={forwardedRef}>
        <ModalContent>
          <img src={errorSvg} alt="icon" />
          <ModalTitle>{title}</ModalTitle>
          <P variant="sm">{message}</P>
        </ModalContent>
        {cta && <ModalCta>{cta}</ModalCta>}
      </ModalWindow>
    );
  },
);

export const FatalErrorModal = createModal(FatalErrorModalWindow);
