import { ReactNode, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

type ModalWindowProps = {
  className?: string;
  children?: ReactNode;
};

// Base modal window.
// You can use standard modal objects from `./inner-components.tsx` in it.
// Then you can use `createModal()` HOC to wrap it inside an overlay and n show transition.
export const ModalWindow = forwardRef(
  ({ className, children }: ModalWindowProps, forwardedRef): ReactNode => {
    const modalRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => modalRef.current);
    return (
      <Root className={className} ref={modalRef}>
        {children}
      </Root>
    );
  },
);

const Root = styled.div`
  position: relative;
  background: #222739;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  height: auto !important;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 460px;
  gap: 20px;
`;
