import { StateCreator } from 'zustand';
import { v6 as generateUuid } from 'uuid';
import { CombinedStoreSlices } from './store';
import { PersistSlice } from './types';
import { DeviceRendererApi } from '@genymotion/device-web-player';
import { z } from 'zod';
import { ExpandedAppId } from '@/pages/home/AppThumbnailScrollListView';
import { AppMetadata, appSlugZod, AppUuid, appUuidZod } from '@/api/entities/app';
import { Experience, ExperienceState } from '@/api/entities/experience';
import { TicketId, ticketIdZod } from '@/api/entities/ticket';

type AppSessionKey = string;

export type StoredTicket = {
  id: TicketId;
  lastInteractionDate: Date;
  appUuid: AppUuid;
  appSlug: string;
};

export type ExperienceWithApp = Experience & { app: AppMetadata };

export type AppSessionStoreSlice = {
  appSessionKey: AppSessionKey;
  lastTicket?: StoredTicket;
  experience?: ExperienceWithApp;
  experienceState?: ExperienceState;
  deviceRendererApi?: DeviceRendererApi;
  showShutDownConfirmationModal?: boolean;
  fullScreen?: boolean;
  afk?: boolean;
  afkDisconnect?: boolean;
  deletedExperiences: Set<TicketId>;
  homePageExpandedApp?: ExpandedAppId;
  homePageCarouselApp?: string;
  resetAppSession: () => void;
  setLastTicket: (lastTicket: StoredTicket | undefined) => void;
  setExperience: (experience: ExperienceWithApp, experienceState: ExperienceState) => void;
  setExperienceState: (experienceState: ExperienceState) => void;
  setShowShutDownConfirmationModal: (showShutDownConfirmationModal: boolean) => void;
  setDeviceRendererApi: (deviceRendererApi: DeviceRendererApi) => void;
  setAfk: (afk: boolean) => void;
  setAfkDisconnect: (afkDisconnect: boolean) => void;
  setFullScreen: (fullScreen: boolean) => void;
  setExperienceDeleted: (ticketId: TicketId) => void;
  wasExperienceDeleted: (ticketId: TicketId) => boolean;
  setHomePageExpandedApp: (selectedApp: ExpandedAppId | undefined) => void;
  setHomePageCarouselApp: (homePageCarouselApp: string | undefined) => void;
};

const generateAppSessionKey = () => generateUuid();

const create: StateCreator<CombinedStoreSlices, [], [], AppSessionStoreSlice> = (set, get) => ({
  appSessionKey: generateAppSessionKey(),
  setLastTicket: (lastTicket: StoredTicket | undefined) => set({ lastTicket }),
  setExperience: (experience: ExperienceWithApp, experienceState: ExperienceState) =>
    set({ experience, experienceState }),
  setExperienceState: (experienceState: ExperienceState) => set({ experienceState }),
  resetAppSession: () => {
    return set({
      appSessionKey: generateAppSessionKey(),
      experience: undefined,
      experienceState: undefined,
      deviceRendererApi: undefined,
      showShutDownConfirmationModal: undefined,
      fullScreen: undefined,
      afk: undefined,
      afkDisconnect: undefined,
      homePageExpandedApp: undefined,
      homePageCarouselApp: undefined,
    });
  },
  setShowShutDownConfirmationModal: (showShutDownConfirmationModal) =>
    set({ showShutDownConfirmationModal }),
  setDeviceRendererApi: (deviceRendererApi) => set({ deviceRendererApi }),
  setAfk: (afk) => set({ afk }),
  setAfkDisconnect: (afkDisconnect) => set({ afkDisconnect }),
  setFullScreen: (fullScreen) => set({ fullScreen }),
  deletedExperiences: new Set(),
  setExperienceDeleted: (ticketId: TicketId) => get().deletedExperiences.add(ticketId),
  wasExperienceDeleted: (ticketId: TicketId) => get().deletedExperiences.has(ticketId),
  setHomePageExpandedApp: (homePageExpandedApp) => set({ homePageExpandedApp }),
  setHomePageCarouselApp: (homePageCarouselApp) => set({ homePageCarouselApp }),
});

export type AppSessionStorePersistedState = {
  lastTicket?: StoredTicket;
};

const partialize = ({ lastTicket }: AppSessionStoreSlice): AppSessionStorePersistedState => ({
  lastTicket,
});

const ticketTraceZod = z.object({
  appSlug: appSlugZod,
  appUuid: appUuidZod,
  id: ticketIdZod,
  lastInteractionDate: z.string().transform((str) => new Date(str)),
});

const storePersistedStateZod = z.object({ lastTicket: ticketTraceZod.optional() }).optional();

const merge = (
  unsafePersistedState: unknown,
  currentState: CombinedStoreSlices,
): CombinedStoreSlices => {
  const parseResult = storePersistedStateZod.safeParse(unsafePersistedState);
  if (!parseResult.success) {
    console.warn(`Invalid persistant store: ${parseResult.error.message}`);
    return currentState;
  }
  if (!parseResult.data) {
    // No storage available
    return currentState;
  }
  const persistantState = parseResult.data;
  return {
    ...currentState,
    lastTicket: persistantState.lastTicket,
  };
};

export const appSessionStorePersistOptions: PersistSlice<
  CombinedStoreSlices,
  AppSessionStoreSlice,
  AppSessionStorePersistedState
> = { create, partialize, merge };
