import { Fragment, ReactNode, useCallback, useEffect } from 'react';
import { VirtualMachinePage } from './VirtualMachinePage/VirtualMachinePage';
import { QueuePage } from './QueuePage/QueuePage';
import { useStore } from '@/store/store';
import { useBoolState } from '@/lib/hooks/useBoolState';
import { useVideoBackgroundSrc } from '@/components/Background/background-hooks';
import { useDefinedAppFromPath } from '../utils';
import { AlreadyRunningAppGuard } from './AlreadyRunningAppGuard';
import { UseQueryResult } from '@tanstack/react-query';
import { timeoutAnticipationTimeSec } from './timeout';
import { TimeOutModal } from './VirtualMachinePage/Modals/TimeOutModal';
import { homePagePath } from '@/routes/routePaths';
import { useGetTimeRemainingForApp, useGetViableExperiences } from '@/api/services/timeKeeper';
import { AppMetadata } from '@/api/entities/app';
import {
  ExperienceWithState,
  ExperienceStatus,
  isBillableExperience,
} from '@/api/entities/experience';
import { useNavigate } from 'react-router-dom';

export const AppRunPage = (): ReactNode => {
  const appSessionKey = useStore((s) => s.appSessionKey);
  const resetAppSession = useStore((s) => s.resetAppSession);
  useEffect(() => {
    return () => {
      resetAppSession();
    };
  }, [resetAppSession]);

  return (
    <Fragment key={appSessionKey}>
      <AlreadyRunningAppGuard>
        <Inner />
      </AlreadyRunningAppGuard>
    </Fragment>
  );
};

const Inner = () => {
  useVideoBackgroundSrc(null);
  const app = useDefinedAppFromPath();
  const setExperience = useStore((s) => s.setExperience);
  const setLastTicket = useStore((state) => state.setLastTicket);
  const [showVirtualMachinePage, setShowVirtualMachinePage] = useBoolState(false);
  const handleExperienceReady = useCallback(
    ({ state, ...experience }: ExperienceWithState) => {
      if (app.uuid !== experience.appUuid) {
        throw Error(
          `Received experience for app ${experience.uuid}, but we are on page for app ${app.uuid}`,
        );
      }
      setExperience({ app, ...experience }, state);
      setShowVirtualMachinePage();
    },
    [app, setExperience, setShowVirtualMachinePage],
  );

  const getTimeRemainingQuery = useGetTimeRemainingForApp(app, { staleTime: 1000 });
  const isAppTimeExpired = getTimeRemainingQuery.data
    ? getTimeRemainingQuery.data.remainingTimeSec < timeoutAnticipationTimeSec
    : undefined;

  const billableExpQuery = useGetBillableExperienceForApp(app);

  useEffect(() => {
    const { data: experience, isFetched } = billableExpQuery;
    if (isFetched && experience && !isAppTimeExpired) {
      // An experience for this app is already billable
      setLastTicket({
        id: experience.ticketId,
        lastInteractionDate: experience.createdAt,
        appUuid: app.uuid,
        appSlug: app.nameSlug,
      });
      handleExperienceReady(experience);
    }
  }, [app, billableExpQuery, handleExperienceReady, isAppTimeExpired, setLastTicket]);

  if (isAppTimeExpired) {
    return <TimeoutModalController />;
  }

  if (isAppTimeExpired === undefined || billableExpQuery.isLoading) {
    return null; // Still loading
  }

  if (showVirtualMachinePage) {
    return <VirtualMachinePage />;
  }

  if (billableExpQuery.isFetched && billableExpQuery.data) {
    return null; // Billable experience was found. UseEffect will trigger.
  }

  // ( billableExperience === null )
  return <QueuePage onExperienceReady={handleExperienceReady} />;
};

const useGetBillableExperienceForApp = ({
  uuid: appUuid,
}: AppMetadata): UseQueryResult<ExperienceWithState<ExperienceStatus.Billable> | undefined> => {
  const select = useCallback(
    (experiences: ExperienceWithState[]) =>
      experiences.find(
        (e): e is ExperienceWithState<ExperienceStatus.Billable> =>
          e.appUuid === appUuid && isBillableExperience(e),
      ),
    [appUuid],
  );
  const billableExperienceQuery = useGetViableExperiences({ select, staleTime: 1000 });
  if (billableExperienceQuery.error) {
    throw billableExperienceQuery.error;
  }
  return billableExperienceQuery;
};

const TimeoutModalController = () => {
  const navigate = useNavigate();
  const handleEndSession = () => {
    navigate(homePagePath);
  };
  return <TimeOutModal show={true} onEndSessionButtonClick={handleEndSession} />;
};
