import { useBoolState } from '@/lib/hooks/useBoolState';
import { useWindowEventListener } from '@/lib/hooks/eventListeners/useWindowEventListener';

export const useIsTabFocused = (): boolean => {
  const [isTabFocused, setTabFocused, setTabNotFocused] = useBoolState(document.hasFocus());

  useWindowEventListener('focus', setTabFocused);
  useWindowEventListener('blur', setTabNotFocused);

  return isTabFocused;
};
