import styled from 'styled-components';
import { ReactNode } from 'react';
import { P } from '@/components/text/P';
import { H6 } from '@/components/text/H';

interface RowProps {
  keyVal: string;
  description: string;
}

export const KeyboardMappingRow = ({ keyVal, description }: RowProps): ReactNode => {
  return (
    <$KeyboardMappingRow>
      <IconContainer>
        <KeyFrame>
          <P>{keyVal}</P>
        </KeyFrame>
      </IconContainer>
      <P variant="sm">{description}</P>
    </$KeyboardMappingRow>
  );
};

const $KeyboardMappingRow = styled.li`
  height: 60px;
  display: flex;
  align-items: center;
  gap: 20px;
  &:not(:last-child) {
    border-image: linear-gradient(90deg, #9ba9e652 0%, #9ba9e600 100%);
    border-image-slice: 1;
    border-image-width: 0 0 1px 0;
  }
  & + & {
    margin-top: 10px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const KeyFrame = styled.div`
  border: 1px solid #777777;
  border-radius: 4px;
  padding: 1px 6px;
  min-width: 27px;
  text-align: center;
`;

export const KeyMappingPanelTitle = styled(H6)`
  margin-bottom: 20px;
`;
