import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  imgSrc: string;
  show?: boolean;
}

export const Badge = ({ imgSrc, show }: Props): ReactNode => {
  return <Img src={imgSrc} $show={show} />;
};

const Img = styled.img<{ $show?: boolean }>`
  position: absolute;
  transition: all 0.3s ease-out;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  z-index: 100;
  margin-right: 100px;
  pointer-events: none;
  filter: drop-shadow(0px 0px 10px white);
`;
