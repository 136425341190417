import { z } from 'zod';
import { AppUuid, appUuidZod } from './app';
import { userIdZod } from './user';

export type TicketId = string;
type Region = string;
type UserId = string;

export type Ticket = {
  id: TicketId;
  appUuid: AppUuid;
  queuedAt: Date;
  region: Region;
  userId: UserId;
};

export enum TicketStatus {
  Queued = 'ticket_queued',
  Dropped = 'ticket_dropped',
  Unqueued = 'ticket_unqueued',
  Invalid = 'ticket_invalid',
  Inactive = 'ticket_inactive',
}

export type TicketState = {
  position: number;
  status: TicketStatus;
};

export const ticketIdZod = z.string().min(1);

const ticketStatusCoercion: Record<string, TicketStatus> = {
  ticket_staged: TicketStatus.Queued,
};

const backendTicketZod = z.object({
  application_uuid: appUuidZod,
  position: z.number(),
  queued_at: z.string(),
  region: z.string(),
  status: z
    .string()
    .nullable()
    .optional()
    .transform((str) => (str && ticketStatusCoercion[str]) || str),
  user_uuid: userIdZod,
  uuid: ticketIdZod,
});

export type TicketWithState = Ticket & { state: TicketState };

export const parseTicket = (backendTicket: unknown): TicketWithState => {
  const safeBackendTicket = backendTicketZod.parse(backendTicket);
  return {
    id: safeBackendTicket.uuid,
    appUuid: safeBackendTicket.application_uuid,
    queuedAt: new Date(safeBackendTicket.queued_at),
    region: safeBackendTicket.region,
    userId: safeBackendTicket.user_uuid,
    state: {
      position: Math.max(0, safeBackendTicket.position),
      status: safeBackendTicket.status as TicketStatus,
    },
  };
};
