import { z } from 'zod';
import { appImageIdZod, AppUuid, appUuidZod } from './app';
import { userIdZod } from './user';
import { TicketId, ticketIdZod } from './ticket';

export enum ExperienceStatus {
  Associated = 'experience_associated',
  Billable = 'experience_billable',
  Saving = 'experience_saving',
  Terminated = 'experience_terminated',
  Error = 'experience_error',
}

export enum TerminationReason {
  UserInactivity = 'user_inactivity',
  SessionTimeout = 'session_timeout',
  ProviderError = 'provider_error',
  UserEndSession = 'user_end_session',
}

export type ExperienceId = string;
export type GenyToken = string;

export type AppVmCredentials = {
  webRTCurl: string;
  jwt: GenyToken;
};

export type Experience = {
  uuid: ExperienceId;
  appUuid: AppUuid;
  ticketId: TicketId;
  createdAt: Date;
  vm: AppVmCredentials & { uuid: string };
};

export type ExperienceState<S extends ExperienceStatus = ExperienceStatus> = {
  status: S;
  terminationReason?: TerminationReason | string;
  remainingTimeSec: number;
  usedTimeSec: number;
  totalTimeSec: number;
  lastInteraction: Date;
};

export type ExperienceWithState<S extends ExperienceStatus = ExperienceStatus> = Experience & {
  state: ExperienceState<S>;
};

export const isBillableExperience = (
  experience: ExperienceWithState,
): experience is ExperienceWithState<ExperienceStatus.Billable> =>
  experience.state.status === ExperienceStatus.Billable;

export const isAssociatedExperience = (
  experience: ExperienceWithState,
): experience is ExperienceWithState<ExperienceStatus.Associated> =>
  experience.state.status === ExperienceStatus.Associated;

export const isTerminatedExperience = (
  experience: ExperienceWithState,
): experience is ExperienceWithState<ExperienceStatus.Terminated> =>
  experience.state.status === ExperienceStatus.Terminated;

export const isSavingExperience = (
  experience: ExperienceWithState,
): experience is ExperienceWithState<ExperienceStatus.Saving> =>
  experience.state.status === ExperienceStatus.Saving;

export const isErrorExperience = (
  experience: ExperienceWithState,
): experience is ExperienceWithState<ExperienceStatus.Error> =>
  experience.state.status === ExperienceStatus.Error;

const statusCoercion: Record<string, ExperienceStatus> = {
  experience_terminating: ExperienceStatus.Terminated,
};

const terminationReasonCoercion: Record<string, TerminationReason> = {
  no_time_left: TerminationReason.SessionTimeout,
};

export const webRTCurlZod = z.string().min(1);
export const appVmJwtZod = z.string().min(1);

const backendExperienceZod = z.object({
  application_uuid: appUuidZod,
  application_image_id: appImageIdZod,
  created_at: z.string(),
  in_app_time: z.object({
    remaining: z.number(),
    used: z.number().min(0),
  }),
  last_interaction: z.string(),
  status: z.string().transform((str) => (str && statusCoercion[str]) || str),
  termination_reason: z
    .string()
    .nullable()
    .optional()
    .transform((str) => (str && terminationReasonCoercion[str]) || str),
  stream_auth: appVmJwtZod,
  stream_url: webRTCurlZod,
  ticket_uuid: ticketIdZod,
  user_uuid: userIdZod,
  uuid: z.string(),
  vm_uuid: z.string(),
});

export const parseExperience = (backendExperience: unknown): ExperienceWithState => {
  const data = backendExperienceZod.parse(backendExperience);
  const remainingTimeSec = Math.max(0, data.in_app_time.remaining);
  const usedTimeSec = data.in_app_time.used;
  return {
    uuid: data.uuid,
    appUuid: data.application_uuid,
    ticketId: data.ticket_uuid,
    createdAt: new Date(data.created_at),
    vm: {
      uuid: data.vm_uuid,
      jwt: data.stream_auth,
      webRTCurl: data.stream_url,
    },
    state: {
      status: data.status as ExperienceStatus,
      terminationReason: data.termination_reason || undefined,
      remainingTimeSec,
      usedTimeSec,
      totalTimeSec: usedTimeSec + remainingTimeSec,
      lastInteraction: new Date(data.last_interaction),
    },
  };
};

export const parseExperiences = (backendExperiences: unknown): ExperienceWithState[] => {
  const experienceArray = z.array(z.object({}).passthrough()).parse(backendExperiences);
  return experienceArray.map(parseExperience);
};
