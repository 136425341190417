import { useRef, useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import checkPng from '@/assets/feedback/check.png';
import { useFeedbackStore, Status } from './store/store';
import { Button } from '@/components/button/Button';
import { P } from '@/components/text/P';

enum IssueKind {
  Bug = 'Bug',
  Crash = 'Virtual machine or game crashed',
  Controls = 'Bad controls',
  Loading = 'Slow loading',
  Download = 'Download during game',
  Others = 'Others',
}

export const issueKinds: IssueKind[] = [
  IssueKind.Bug,
  IssueKind.Crash,
  IssueKind.Controls,
  IssueKind.Loading,
  IssueKind.Download,
  IssueKind.Others,
];

export const Issue = (): ReactNode => {
  const { setStatus, pushAnswer } = useFeedbackStore();
  const modal = useRef<HTMLDivElement | null>(null);
  const [issueSelected, setIssueSelected] = useState<IssueKind | undefined>();

  useEffect(() => {
    gsap.to(modal.current, { duration: 0.5, opacity: 1, delay: 0.5 });
    gsap.from(modal.current, { y: 100, duration: 0.5, delay: 0.5 });
  }, []);

  const onClickHandler = () => {
    const answer = {
      answerType: 'multiselect',
      questionId: 'issues',
      questionValue: 'Did you have any issues?',
      answer: issueSelected,
    };

    if (!issueSelected) return;
    pushAnswer(answer);
    if (issueSelected === IssueKind.Bug || issueSelected === IssueKind.Crash) {
      setStatus(Status.Crash);
    } else if (issueSelected === IssueKind.Others) {
      setStatus(Status.Other);
    } else {
      setStatus(Status.Recommend);
    }
  };

  return (
    <>
      <Content>
        <IssuesList>
          {issueKinds.map((issue, index) => (
            <ListItemStyled
              key={index}
              $selected={issue === issueSelected}
              onClick={() => setIssueSelected(issue)}
            >
              <P variant="sm">{issue}</P>
              {issue === issueSelected ? (
                <Right>
                  <img src={checkPng} alt="check icon" />
                </Right>
              ) : (
                <Right>
                  <Circle className="circle"></Circle>
                </Right>
              )}
            </ListItemStyled>
          ))}
        </IssuesList>
        <Button onClick={onClickHandler}>Submit</Button>
      </Content>
    </>
  );
};

const Content = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const IssuesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;
`;

const ListItemStyled = styled.li<{ $selected: boolean }>`
  width: 90%;
  height: 40px;
  background: ${(props) => (props.$selected ? '#3653cc' : '#9BA9E610')};
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  flex-direction: row;
  transition: all 0.2s ease 0s;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #9ba9e6;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;
