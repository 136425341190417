import { DeviceRendererApi, DeviceRendererFactory } from '@genymotion/device-web-player';
import { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from '@/store/store';
import styled from 'styled-components';
import { useLastDefined } from '@/lib/hooks/useLastDefined';
import { useEffectUntil } from '@/lib/hooks/useEffectUntil';
import { useResize } from '@/lib/useResize';
import { P } from '@/components/text/P';
import { ExperienceWithApp } from '@/store/appSessionStoreSlice';
import { AppMetadata } from '@/api/entities/app';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { useScreenOrientationEventListener } from '@/lib/hooks/eventListeners/useScreenOrientationEventListener';
import { layoutDefinition } from '@/pages/layoutDefinition';

export const VirtualMachine = (): ReactNode => {
  const playerRef = useRef<HTMLDivElement>(null);
  const setDeviceRendererApi = useStore((s) => s.setDeviceRendererApi);
  const experience = useLastDefined(useStore((s) => s.experience));
  if (!experience) {
    throw Error('Assumed experience');
  }
  useUpdateVideoClasses(experience.app);
  const [api, vmError] = useVirtualMachine(playerRef, experience);
  useEffect(() => {
    if (api) {
      setDeviceRendererApi(api);
    }
  }, [setDeviceRendererApi, api]);

  const afkDisconnect = useStore((s) => s.afkDisconnect);
  return (
    <Root>
      {!vmError && !afkDisconnect && <Genymotion ref={playerRef} />}
      {vmError && <P>{vmError.message}</P>}
    </Root>
  );
};

const useVirtualMachine = (
  playerRef: RefObject<HTMLDivElement>,
  experience: ExperienceWithApp,
): [DeviceRendererApi | undefined, Error | undefined] => {
  const [error, setError] = useState<Error>();
  const [api, setApi] = useState<DeviceRendererApi>();
  useEffectUntil(
    (done) => {
      if (!playerRef.current) {
        return;
      }
      try {
        const newApi = createDeviceRenderer(playerRef.current, experience);
        done();
        setApi(newApi);
        return () => {
          newApi.VM_communication.disconnect();
        };
      } catch (error) {
        setError(error);
      }
    },
    [experience, playerRef],
  );
  return [api, error];
};

const createDeviceRenderer = (targetElement: HTMLDivElement, { vm, app }: ExperienceWithApp) => {
  const factory = new DeviceRendererFactory();
  const renderer = factory.setupRenderer(targetElement, vm.webRTCurl.toString(), {
    template: 'renderer_no_toolbar',
    token: vm.jwt,
    keyboardMapping: true,
  });
  // eslint-disable-next-line no-console
  console.log('Virtual machine renderer:', renderer);
  if (app.keymap) {
    renderer.keyMapping.enable(true);
    renderer.keyMapping.setConfig(app.keymap);
  }
  return renderer;
};

const computeLandscapeVideoStyle = (videoWrapper: HTMLDivElement, fullScreen: boolean) => {
  // Because the css rotate() is performed AFTER positioning,
  // there is no easy way to adjust video width according to container height, and vice-versa.
  // Therefore we do it in JS here.
  const videoHeight = fullScreen
    ? videoWrapper.clientHeight
    : Math.min(720, videoWrapper.clientHeight);

  const videoWidth = videoWrapper.clientWidth;
  const videoPaddingTop = Math.max(0, videoWrapper.clientHeight - videoHeight) / 2;
  return {
    width: `${videoHeight}px`, // Rotated, so height becomes width
    height: `${videoWidth}px`, // Rotated, so width becomes height
    transform: `translate(0, ${videoPaddingTop}px) rotate(-90deg) translate(-100%)`,
  };
};

const useUpdateVideoClasses = (app: AppMetadata) => {
  const deviceRendererApi = useStore((s) => s.deviceRendererApi);
  const isMobile = useIsMobile();
  const fullScreen = useStore((s) => s.fullScreen);
  const updateVideoStyles = useCallback(() => {
    const videoWrapper = document.querySelector<HTMLDivElement>('#genymotion .gm-video-wrapper');
    const video = videoWrapper?.querySelector<HTMLVideoElement>('video');
    if (!videoWrapper || !video) {
      return;
    }
    if (app.orientation === 'landscape') {
      Object.assign(video.style, computeLandscapeVideoStyle(videoWrapper, Boolean(fullScreen)));
    }
    if (isMobile) {
      video.classList.add('mobile');
      if (app.orientation === 'portrait') {
        video.classList.add('mobile-portrait');
      }
    } else {
      video.classList.remove('mobile');
      if (app.orientation === 'portrait') {
        video.classList.remove('mobile-portrait');
      }
    }
  }, [app.orientation, isMobile, fullScreen]);

  useEffect(updateVideoStyles, [updateVideoStyles, deviceRendererApi?.video]);
  useResize(updateVideoStyles, { triggerNow: true });
  useScreenOrientationEventListener(updateVideoStyles);
};

const Root = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Genymotion = styled.div.attrs({
  id: 'genymotion',
  // This class is normally set by the VM library, but somehow when hot-reloads rerenders the component it gets wiped off.
  // Therefore we explicitly add it here, so further rerender will not wipe it off.
  className: 'gm-template-renderer_no_toolbar',
})`
  position: relative !important;
  min-height: unset !important;
  pointer-events: all;
  all: unset;
  background: none !important;
  min-width: 0 !important;
  min-height: 0 !important;
  /* background: magenta !important; */

  .gm-overlay {
    display: none;
  }
  .gm-wrapper {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    /* background: pink !important; */
    .gm-video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      /* background: yellow !important; */
      video {
        transform-origin: top left;
        display: block;
        position: relative;
        &.mobile {
          height: unset;
          width: 100%;
        }
      }
    }
  }
  .gm-click-to-unmute,
  .gm-version-number {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .gm-video-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        &.mobile-portrait {
          width: 100%;
          height: 100%;
          max-height: calc(
            100vh - (${layoutDefinition.headerHeight} + ${layoutDefinition.sidebarHeight})
          );
        }
      }
    }
  }
`;
