import { useDeleteTicketMutation } from '@/api/services/ticket';
import { useDeleteExperienceMutation } from '@/api/services/timeKeeper';
import { useStore } from '@/store/store';
import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const useDeleteTicketAndExperience = (): (() => void) => {
  const [lastTicket, experience, setExperienceDeleted] = useStore(
    useShallow((s) => [s.lastTicket, s.experience, s.setExperienceDeleted]),
  );
  const { mutate: deleteExperience } = useDeleteExperienceMutation();
  const { mutate: deleteTicket } = useDeleteTicketMutation();

  return useCallback(() => {
    const ticketId = experience?.ticketId ?? lastTicket?.id;
    if (ticketId) {
      setExperienceDeleted(ticketId);
      deleteTicket(ticketId);
      if (experience) {
        deleteExperience(experience.ticketId);
      }
    }
  }, [deleteExperience, deleteTicket, setExperienceDeleted, experience, lastTicket?.id]);
};
