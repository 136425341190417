import { HydratedApp } from '@/api/entities/hydrated-app';
import styled from 'styled-components';
import { AppCard } from '../home/appCards/AppCard';
import { ReactNode } from 'react';

export const ResultCardList = ({ apps }: { apps: HydratedApp[] }): ReactNode => {
  return (
    <$ResultCardList>
      {apps.map((app) => (
        <$AppCard key={app.imageId} app={app} />
      ))}
    </$ResultCardList>
  );
};

const $AppCard = styled(AppCard)`
  width: 100%;
`;

const $ResultCardList = styled.div`
  width: 100%;
  padding: 20px 10px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 450px);
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
