import { isHttpError } from '@/lib/isHttpError';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { hasExperienceViableStatus } from '../useGetViableExperience';
import { useStore } from '@/store/store';
import { AuthTokenSet } from '@/api/queries/utils';
import { useApiAuth } from '@/api/auth';
import { AppUuid } from '@/api/entities/app';
import { TicketId, TicketStatus, TicketWithState } from '@/api/entities/ticket';
import { getTicket, FetchTicketResponse, createTicket } from '@/api/queries/ticket';
import { fetchExperience } from '@/api/queries/timeKeeper';
import { StoredTicket } from '@/store/appSessionStoreSlice';

const getTicketIfValid = async (auth: AuthTokenSet, ticketId: TicketId) => {
  try {
    const getTicketResponse = await getTicket(auth, ticketId);
    const status = getTicketResponse.state.status;
    const acceptableStatuses = [TicketStatus.Queued, TicketStatus.Unqueued];
    return acceptableStatuses.includes(status) ? getTicketResponse : undefined;
  } catch (error) {
    if (isHttpError(error) && error.status === 404) {
      return undefined;
    }
    throw error;
  }
};

const fetchHasAValidExperience = async (auth: AuthTokenSet, ticketId: TicketId) => {
  try {
    const experience = await fetchExperience(auth, ticketId);
    return hasExperienceViableStatus(experience);
  } catch (error) {
    if (isHttpError(error) && error.status === 404) {
      return true;
    }
    return false;
  }
};

const getMemorizedTicket = async (
  auth: AuthTokenSet,
  ticketInStorage: StoredTicket,
): Promise<TicketWithState | undefined> => {
  const { lastInteractionDate, id } = ticketInStorage;
  const ticketExpirationDelayMs = 5 * 60 * 1000; // 5 minutes
  const isTicketExpired = lastInteractionDate.getTime() + ticketExpirationDelayMs < Date.now();
  if (isTicketExpired) {
    // eslint-disable-next-line no-console
    console.log(`Ticket ${id} seems old, so we are not using it`);
    return undefined;
  }
  const [validTicket, hasValidExperience] = await Promise.all([
    getTicketIfValid(auth, id),
    fetchHasAValidExperience(auth, id),
  ]);
  if (validTicket) {
    if (hasValidExperience) {
      // eslint-disable-next-line no-console
      console.log(`Ticket ${id} still is valid, so we are using it`);
      return validTicket; // Yay, we have a ticket
    }
    // eslint-disable-next-line no-console
    console.log(
      `Ticket ${id} is still valid, but the experience associated is not valid, so we are not using it`,
    );
  } else {
    // eslint-disable-next-line no-console
    console.log(`Ticket ${id} is not valid, so we are not using it`);
  }
  return undefined;
};

const fetchTicket = async (
  auth: AuthTokenSet,
  appUuid: AppUuid,
  ticketInStorage?: StoredTicket,
): Promise<FetchTicketResponse> => {
  // 1- Do we have a memorized and valid ticket?
  if (ticketInStorage) {
    // eslint-disable-next-line no-console
    console.log('There is a ticket in storage');
    if (ticketInStorage.appUuid === appUuid) {
      const memorizedTicketQuery = await getMemorizedTicket(auth, ticketInStorage);
      if (memorizedTicketQuery) {
        return memorizedTicketQuery; // Yes we do!
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('Ticket is for another appUuid');
    }
  }
  // 2- If not, we create it
  const createdTicket = await createTicket(auth, appUuid);
  // eslint-disable-next-line no-console
  console.log(`Created ticket ${createdTicket.id}`);
  return createdTicket;
};

export const useFetchTicketMutation = (): UseMutationResult<
  FetchTicketResponse,
  Error,
  AppUuid
> => {
  const auth = useApiAuth();
  const lastTicket = useStore((state) => state.lastTicket);
  return useMutation({
    mutationFn: (appUuid: AppUuid) => fetchTicket(auth, appUuid, lastTicket),
  });
};
