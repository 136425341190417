import { ReactElement } from 'react';
import styled from 'styled-components';
import { AnimatedCanvasBackground } from './AnimatedCanvasBackground/AnimatedCanvasBackground';

export const Background = (): ReactElement | null => {
  return (
    <Root>
      <AnimatedCanvasBackground />
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; // Ensure it stays in background
`;
