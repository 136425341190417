import { createBrowserRouter } from 'react-router-dom';
import { HomePageLayout } from '@/pages/home/HomePageLayout';
import {
  oauthRedirectUriPath,
  RedirectToLoginSucessfulPath,
} from '@/components/Auth/ShadowAuthProvider';
import { catalogLoader } from '@/api/loaders/catalogLoader';
import { AppRunPage } from '@/pages/app/run/AppRunPage';
import { RootLayout } from '@/pages/RootLayout';
import { appRootPath, appRunPath, searchPagePath } from './routePaths';
import { TestPage } from '@/pages/TestPage';
import { ErrorPage } from '@/pages/error/ErrorPage';
import { HomePage } from '@/pages/app/HomePage';
import { AppRootPage } from '@/pages/app/AppRootPage';
import { SearchPage } from '@/pages/search/SearchPage';
import { searchLoader } from '@/pages/search/searchLoader';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        element: <HomePageLayout />,
        loader: catalogLoader,
        children: [
          {
            index: true,
            element: <HomePage />,
            loader: catalogLoader,
          },
          {
            path: appRootPath,
            element: <AppRootPage />,
            loader: catalogLoader,
          },
        ],
      },
      {
        path: searchPagePath,
        element: <SearchPage />,
        loader: searchLoader,
      },
      {
        path: appRunPath,
        element: <AppRunPage />,
        loader: catalogLoader,
      },
      {
        path: oauthRedirectUriPath,
        element: <RedirectToLoginSucessfulPath />,
      },
      {
        path: '/test',
        element: <TestPage />,
        loader: catalogLoader,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);
