import { ReactNode, useCallback, useMemo } from 'react';
import { uniqueId } from 'lodash';
import { useStore } from '@/store/store';
import { AppThumbnailScrollListView } from './AppThumbnailScrollListView';
import { HydratedApp } from '@/api/entities/hydrated-app';

export const AppThumbnailScrollList = ({
  title,
  catalog,
  displayTime,
}: {
  title: string;
  catalog: HydratedApp[];
  displayTime?: boolean;
}): ReactNode => {
  const scrollId = useMemo(() => uniqueId('catalog_'), []);

  const selectedApp = useStore((state) => state.homePageExpandedApp);
  const setHomePageExpandedApp = useStore((state) => state.setHomePageExpandedApp);

  const handleClick = useCallback(
    (app: HydratedApp) => {
      const isSelected = selectedApp?.scrollId === scrollId && selectedApp?.appUuid === app.uuid;
      setHomePageExpandedApp(isSelected ? undefined : { scrollId, appUuid: app.uuid });
    },
    [selectedApp, setHomePageExpandedApp, scrollId],
  );

  return (
    <AppThumbnailScrollListView
      title={title}
      catalog={catalog}
      expandedApp={selectedApp}
      displayTime={displayTime}
      onAppExpandRequest={handleClick}
      scrollId={scrollId}
    />
  );
};
