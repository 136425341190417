import { ReactNode } from 'react';
import styled from 'styled-components';
import badDefault from '@/assets/feedback/faces/bad-default.png';
import badSelected from '@/assets/feedback/faces/bad-selected.png';
import goodDefault from '@/assets/feedback/faces/good-default.png';
import goodSelected from '@/assets/feedback/faces/good-selected.png';
import neutralDefault from '@/assets/feedback/faces/neutral-default.png';
import neutralSelected from '@/assets/feedback/faces/neutral-selected.png';
import veryBadDefault from '@/assets/feedback/faces/very-bad-default.png';
import veryBadSelected from '@/assets/feedback/faces/very-bad-selected.png';
import veryGoodDefault from '@/assets/feedback/faces/very-good-default.png';
import veryGoodSelected from '@/assets/feedback/faces/very-good-selected.png';
import { P } from '@/components/text/P';

interface FaceProps {
  value: number | undefined;
  onChange?: (rating: number) => void;
}

interface Face {
  default: string;
  selected: string;
  text?: string;
}

const faces: Face[] = [
  { default: veryBadDefault, selected: veryBadSelected, text: 'Very bad' },
  { default: badDefault, selected: badSelected },
  { default: neutralDefault, selected: neutralSelected },
  { default: goodDefault, selected: goodSelected },
  { default: veryGoodDefault, selected: veryGoodSelected, text: 'Very good' },
];

export const FaceSelect = ({ value, onChange }: FaceProps): ReactNode => {
  return (
    <FaceSelection>
      {faces.map((face, index) => (
        <FaceComponent
          key={index}
          selected={value === index}
          face={face}
          onClick={() => onChange?.(index)}
        />
      ))}
    </FaceSelection>
  );
};

interface FaceComponentProps {
  selected: boolean;
  face: Face;
  onClick: () => void;
}

const FaceComponent = ({ selected, face, onClick }: FaceComponentProps): ReactNode => {
  return (
    <$Face onClick={onClick} selected={selected}>
      <img src={selected ? face.selected : face.default} alt={`${face.text} face`} />
      <P>{face.text}</P>
    </$Face>
  );
};

const FaceSelection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const $Face = styled.div<{ selected: boolean }>`
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  img {
    transition: all 0.2s ease 0s;
    transform: scale(${(props) => (props.selected ? 1.2 : 1)});
    &:hover {
      transform: scale(1.2);
    }
  }
`;
