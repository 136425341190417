import { useState, ChangeEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { useFeedbackStore, Status } from './store/store';
import { Button } from '@/components/button/Button';
import { P } from '@/components/text/P';

export const OtherIssue = (): ReactNode => {
  const { setStatus, pushAnswer } = useFeedbackStore();

  const maxCharacters = 240;
  const [text, setText] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length <= maxCharacters) {
      setText(value);
    }
  };

  const answer = {
    answerType: 'text',
    questionId: 'other',
    questionValue: 'Please describe the issue you had',
    answer: text,
  };

  const onClickHandler = () => {
    if (text) pushAnswer(answer);
    setStatus(Status.Share);
  };

  return (
    <>
      <Content>
        <InputSection>
          <Explanation variant="sm">Please describe the issue you had </Explanation>
          <InputContainer>
            <textarea
              rows={5}
              cols={380}
              placeholder="Write your issue here..."
              value={text}
              onChange={handleInputChange}
            />
          </InputContainer>
          <Explanation variant="sm">{maxCharacters - text.length} characters remaining</Explanation>
        </InputSection>
        <$Button onClick={onClickHandler}>SUBMIT</$Button>
      </Content>
    </>
  );
};

const Explanation = styled(P)`
  opacity: 0.5;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 90%;
  text-align: center;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const InputContainer = styled.div`
  width: 90%;
  height: 94px;
  display: flex;
  justify-content: flex-start;
  background: #9ba9e620;
  border-radius: 8px;
  textarea {
    background: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 10px;
    resize: none;
    &::placeholder {
      font-family: Nexa Text;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
      opacity: 0.5;
    }
  }
`;

const $Button = styled(Button)`
  width: 100%;
`;
