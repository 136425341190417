import { z } from 'zod';
import { createHeaders, AuthTokenSet } from './utils';
import { env } from '@/env';
import {
  ExperienceWithState,
  Experience,
  parseExperience,
  parseExperiences,
} from '../entities/experience';
import { AppTimeMetadata, appTimeMetadataZod } from '../entities/app-time-metadata';
import { TicketId, Ticket } from '../entities/ticket';
import { mapValues } from 'lodash';

export type TimeMetadataPerApp = Record<string, AppTimeMetadata>;

const fetchTimeRemainingResponseZod = z.object({
  durations: z.record(z.string(), appTimeMetadataZod).optional(),
});

export const getTimeRemaining = async (auth: AuthTokenSet): Promise<TimeMetadataPerApp> => {
  const response = await fetch(`${env.timekeeperUrl}v1/time/remaining/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (response.status >= 400) {
    throw response;
  }
  const data = fetchTimeRemainingResponseZod.parse(await response.json());
  return mapValues(data.durations || {}, (item) => ({
    remainingTimeSec: item.remaining_time,
    usedTimeSec: item.used_time,
  }));
};

export const fetchExperience = async (
  auth: AuthTokenSet,
  ticketId: TicketId,
): Promise<ExperienceWithState> => {
  const response = await fetch(`${env.timekeeperUrl}v1/experience/${ticketId}/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (!response.ok) {
    throw response;
  }
  const json = await response.json();
  return parseExperience(json);
};

export const deleteExperience = async (auth: AuthTokenSet, ticketId: TicketId): Promise<void> => {
  const response = await fetch(`${env.timekeeperUrl}v1/experience/${ticketId}/`, {
    headers: createHeaders(auth),
    method: 'DELETE',
  });
  if (!response.ok) {
    throw response;
  }
};

export const fetchActiveExperiences = async (
  auth: AuthTokenSet,
): Promise<ExperienceWithState[]> => {
  const response = await fetch(`${env.timekeeperUrl}v1/experience/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (!response.ok) {
    throw response;
  }
  const json = await response.json();
  return parseExperiences(json);
};

class ExperienceError extends Error {
  constructor(
    private readonly ticket: Ticket,
    message?: string,
  ) {
    super(message || 'Experience encountered an error');
    this.name = ExperienceError.name;
  }
}

abstract class ExistingExperienceError extends ExperienceError {
  constructor(
    ticket: Ticket,
    private readonly experience: Experience,
    message?: string,
  ) {
    super(ticket, message);
  }
}

export class TerminatedExperienceError extends ExistingExperienceError {
  constructor(ticket: Ticket, experience: Experience) {
    super(ticket, experience, 'Experience was terminated');
  }
}

export class UnknownExperienceError extends ExistingExperienceError {
  constructor(ticket: Ticket, experience: Experience, message?: string) {
    super(ticket, experience, message ?? 'Unknown error in experience');
  }
}
