import { Button } from '@/components/button/Button';
import afkPng from '@/assets/afk.png';
import { P } from '@/components/text/P';
import { modalWindowWithTransition, wrappedInOverlay } from '@/components/modal/hoc';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { forwardRef, ReactNode } from 'react';

interface Props {
  isAfkDisconnected?: boolean;
  onEndSessionButtonClick?: () => void;
}

export const AfkModalWindow = ({
  isAfkDisconnected,
  onEndSessionButtonClick,
}: Props): ReactNode => {
  return isAfkDisconnected ? (
    <EndedSessionNoticeModal
      onEndSessionButtonClick={onEndSessionButtonClick}
      show={isAfkDisconnected}
    />
  ) : (
    <AfkWarning />
  );
};

export const AfkModal = wrappedInOverlay(AfkModalWindow);

const EndedSessionNoticeModalWindow = forwardRef(
  (
    {
      onEndSessionButtonClick,
    }: {
      onEndSessionButtonClick?: () => void;
    },
    forwardedRef,
  ) => {
    return (
      <ModalWindow ref={forwardedRef}>
        <ModalContent>
          <img src={afkPng} alt="user afk" />
          <ModalTitle>It seems that you&apos;re not here...</ModalTitle>
          <P variant="sm">
            Your inactivity exceeded 2 minutes. <br />
            your game session has been stopped.
          </P>
        </ModalContent>
        <ModalCta>
          <Button onClick={onEndSessionButtonClick}>Back to game selection</Button>
        </ModalCta>
      </ModalWindow>
    );
  },
);

const EndedSessionNoticeModal = modalWindowWithTransition(EndedSessionNoticeModalWindow);

const AfkWarning = () => (
  <ModalContent>
    <img src={afkPng} alt="user afk" />
    <P variant="md">
      We detected that you are not here with us anymore. <br />
      Please do any action to prevent being disconnected.
    </P>
  </ModalContent>
);
