import { P } from '@/components/text/P';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { SearchResultDisplaySortKind } from './searchOptions';
import { Select, SelectProps } from '@/components/Select';

interface AppSortSelectInput {
  value?: SearchResultDisplaySortKind;
  onChange?: (value: SearchResultDisplaySortKind) => void;
}

export const AppSortSelectInput = ({ value, onChange }: AppSortSelectInput): ReactNode => {
  return <$Select value={value} onChange={onChange} isSearchable={false} options={options} />;
};

const TypedSelect = (p: SelectProps<SearchResultDisplaySortKind>) => <Select {...p} />;

const $Select = styled(TypedSelect)`
  width: 150px;
  .select__menu {
    width: 140px;
  }
`;

type Option = { value: SearchResultDisplaySortKind; label: ReactNode };

const OptionP = styled(P).attrs({ variant: 'md' })`
  text-align: right;
`;

const options: Option[] = [
  {
    value: SearchResultDisplaySortKind.AtoZ,
    label: (
      <OptionP>
        <b>A</b> to <b>Z</b>
      </OptionP>
    ),
  },
  {
    value: SearchResultDisplaySortKind.ZtoA,
    label: (
      <OptionP>
        <b>Z</b> to <b>A</b>
      </OptionP>
    ),
  },
  { value: SearchResultDisplaySortKind.MostPopular, label: <OptionP>Most popular</OptionP> }, // TODO When available on backend
  { value: SearchResultDisplaySortKind.MostRecent, label: <OptionP>Most recent</OptionP> },
];
