import styled, { css } from 'styled-components';
import { ReactNode, useRef, useState } from 'react';
import gsap from 'gsap';
import { KeyboardList } from './KeyboardList';
import { MouseController } from './MouseController';
import { TrackpadList } from './TrackpadList';
import { H6 } from '@/components/text/H';
import { KeyMappingConfig } from '@genymotion/device-web-player';

// TODO Figure out what to display in Trackpad and Mouse

const startingIndex = 1;

interface Props {
  mapping: KeyMappingConfig;
}

export const ControlSideBar = ({ mapping }: Props): ReactNode => {
  const [selectedIndex, setSelectedIndex] = useState(startingIndex);
  const [listContentIndex, setListContentIndex] = useState(startingIndex);
  const listRef = useRef(null);
  const goToPanel = (targetPanelIndex: number) => {
    if (!listRef.current || targetPanelIndex === listContentIndex) return;
    setSelectedIndex(targetPanelIndex);
    const directionFactor = targetPanelIndex > listContentIndex ? -1 : +1;
    gsap.to(listRef.current, {
      duration: 0.5,
      x: directionFactor * 10,
      opacity: 0,
      onComplete: () => {
        setListContentIndex(targetPanelIndex);
        gsap.fromTo(
          listRef.current,
          { x: -directionFactor * 10 },
          { duration: 0.5, x: 0, opacity: 1 },
        );
      },
    });
  };
  return (
    <Root>
      <Title>Game Controls</Title>
      <PanelContainer ref={listRef}>
        {listContentIndex === 0 ? (
          <TrackpadList />
        ) : listContentIndex === 1 ? (
          <KeyboardList mapping={mapping} />
        ) : (
          <MouseController />
        )}
      </PanelContainer>
      <ElipseContainer>
        {/* <Elipse $selected={selectedIndex === 0} onClick={() => goToPanel(0)} /> */}
        <Elipse $selected={selectedIndex === 1} onClick={() => goToPanel(1)} />
        {/* <Elipse $selected={selectedIndex === 2} onClick={() => goToPanel(2)} /> */}
      </ElipseContainer>
    </Root>
  );
};

const Root = styled.div`
  align-self: stretch;
  width: 300px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.692);
  backdrop-filter: blur(20px) saturate(120%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
  border-radius: 0 8px 8px 0;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.161);
`;

const Title = styled(H6)`
  margin-top: 20px;
  margin-bottom: 60px;
  border-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.434) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
  border-image-width: 0 0 1px 0;
  padding-bottom: 20px;
`;

const PanelContainer = styled.div`
  all: unset;
  align-self: stretch;
  padding: 0 40px;
`;

const ElipseContainer = styled.div`
  margin: 18px;
  display: flex;
  gap: 18px;
`;

const Elipse = styled.div<{ $selected: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff50;
  transition: all 0.2s ease 0s;
  &:hover {
    background: #ffffff;
    filter: drop-shadow(0px 0px 4px #ffffff);
  }
  cursor: pointer;
  ${(p) =>
    p.$selected &&
    css`
      background: #ffffff;
      filter: drop-shadow(0px 0px 4px #ffffff);
      cursor: default;
      &:before {
        content: '';
        border-radius: 50%;
        background: #ffffff00;
        animation: scaleAndFade 0.3s ease-in-out both;
      }
    `}
`;
