import { IamToken } from '@/components/Auth/types';
import { QueryObserverOptions } from '@tanstack/react-query';
import { SocisJwt } from '../entities/socis-jwt';

interface Options {
  socisJwt?: SocisJwt;
  iamToken?: IamToken;
}

export const createHeaders = (options?: Options): HeadersInit => {
  return {
    'Content-Type': 'application/json',
    ...(options?.socisJwt ? { 'x-socis-authorization': options?.socisJwt } : {}),
    ...(options?.iamToken ? { Authorization: `Bearer ${options?.iamToken}` } : {}),
  };
};

// Helper to create custom useQuery's options
export type ReactQueryOptions<TQueryData, TData = TQueryData> = Partial<
  QueryObserverOptions<TQueryData, Error, TData>
>;

export type AuthTokenSet = { socisJwt?: SocisJwt; iamToken?: IamToken };
