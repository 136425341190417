import { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/button/Button';
import chevronRightWhite from '@/assets/chevron-right-white.svg';
import { AppMetadata } from '@/api/entities/app';
import { P } from '@/components/text/P';
import { Link } from 'react-router-dom';
import { getAppRunPageUrl } from '@/routes/routePaths';

interface AppCardProps {
  app: AppMetadata;
  className?: string;
}

export const AppCard = ({ app, className }: AppCardProps): ReactNode => {
  return (
    <$Card className={className}>
      <UpperImg $bgImgSrc={app.banner} />
      <Bottom>
        <GameInfo>
          <img src={app.icon} alt="app icon" />
          <TextContainer>
            <Title>{app.name}</Title>
            <Editor>{app.editorName}</Editor>
          </TextContainer>
        </GameInfo>
        <Link to={getAppRunPageUrl(app.nameSlug)}>
          <PlayButton>
            <P variant="md">
              Play <img src={chevronRightWhite} alt="chevron right" />
            </P>
          </PlayButton>
        </Link>
      </Bottom>
    </$Card>
  );
};

const $Card = styled.div`
  min-height: 280px;
  min-width: 434px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex: 0 0 auto;
`;

const UpperImg = styled.div<{ $bgImgSrc: string }>`
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-image: url(${(p) => p.$bgImgSrc});
  background-size: cover;
  height: calc(284px - 90px);
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #10142200 0%, #10142200 50%, #101422 100%);
    z-index: 2;
    border-radius: 8px 8px 0 0;
  }
`;

const Bottom = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background: #101422;
  border-radius: 0 0 8px 8px;
  gap: 10px;
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  img {
    width: 54px;
    height: 54px;
    border-radius: 8px;
  }
`;

const Title = styled.p`
  font-family: 'Nexa Text';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Editor = styled.p`
  font-family: 'Nexa Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #87a0f9;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlayButton = styled(Button)`
  width: 87px;
  img {
    margin-left: 8px;
  }
`;
