import { useRef, RefObject, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useBoolState } from '@/lib/hooks/useBoolState';

/**
 *
 * @param show Whether the element should show (= be rendered + be visible)
 * @param fromVars Initial state of your element
 * @param toVars Final state of your element
 * @returns [0] Whether you should render the element
 * @returns [1] A ref to bind the element to
 */
export const useShowTransition = <E extends HTMLElement = HTMLDivElement>(
  show: boolean,
  fromVars: gsap.TweenVars,
  toVars: gsap.TweenVars,
): [boolean, RefObject<E>] => {
  const eltRef = useRef<E>(null);
  const [showOverride, setShowOverrideTrue, setShowOverrideFalse] = useBoolState(show);
  const timelineRef = useRef<gsap.core.Timeline>();
  const animationVarsRef = useRef({ fromVars, toVars });

  // Purposefully using useLayoutEffect instead of useGSAP, because we do
  // not want GSAP to automatically cleanup, we do it ourselves.
  useLayoutEffect(() => {
    if (!eltRef.current) {
      return;
    }
    if (show) {
      setShowOverrideTrue();
      const { fromVars, toVars } = animationVarsRef.current;
      timelineRef.current = gsap.timeline().fromTo(eltRef.current, fromVars, {
        ...toVars,
        onReverseComplete: () => {
          setShowOverrideFalse();
          toVars.onReverseComplete?.();
        },
      });
    } else {
      timelineRef.current?.reverse();
    }
  }, [setShowOverrideFalse, setShowOverrideTrue, show]);
  return [show || showOverride, eltRef];
};
