import { ReactNode, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { QueueIsFullErrorModal } from './QueueIsFullErrorModal';
import { QueueIsFullError } from '../useTicketLoader';
import { useDefinedAppFromPath } from '../../utils';
import {
  ExperienceLoaderState,
  ExperienceLoaderStatus,
  useExperienceLoader,
} from '../useExperienceLoader';
import { ControlSideBar } from './ControlSideBar/ControlSideBar';
import { QueueStatusDisplay } from './QueueStatusDisplay';
import { homePagePath } from '@/routes/routePaths';
import { useNavigate } from 'react-router-dom';
import { useDeleteTicketAndExperience } from '../useDeleteTicketAndExperience';
import { useEffectUntil } from '@/lib/hooks/useEffectUntil';
import { useStore } from '@/store/store';
import { QueueErrorModal } from './QueueErrorModal';
import { TimeOutModal } from '../VirtualMachinePage/Modals/TimeOutModal';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { ExperienceWithState, TerminationReason } from '@/api/entities/experience';

interface WaitingLinePanelProps {
  onExperienceReady: (experience: ExperienceWithState) => void;
}

export const QueuePage = ({ onExperienceReady }: WaitingLinePanelProps): ReactNode => {
  const app = useDefinedAppFromPath();

  const expLoaderState = useExperienceLoader(app);

  const isMobile = useIsMobile();

  useEffectUntil(
    (done) => {
      if (expLoaderState.status === ExperienceLoaderStatus.Billable) {
        done();
        onExperienceReady(expLoaderState.experience);
      }
    },
    [expLoaderState, onExperienceReady],
  );

  const handleBackButtonClick = useBackButtonClickHandler();

  if (
    expLoaderState.status === ExperienceLoaderStatus.Error &&
    expLoaderState.error instanceof QueueIsFullError
  ) {
    return (
      <Root>
        <QueueIsFullErrorModal />
      </Root>
    );
  }
  return (
    <Root>
      {!isMobile && app.keymap && <ControlSideBar mapping={app.keymap} />}
      <QueueStatusDisplayContainer>
        <QueueStatusDisplay
          app={app}
          experienceLoaderState={expLoaderState}
          onBackButtonClick={handleBackButtonClick}
        />
      </QueueStatusDisplayContainer>
      <QueueErrorModalController expLoaderState={expLoaderState} />
    </Root>
  );
};

const QueueErrorModalController = ({
  expLoaderState,
}: {
  expLoaderState: ExperienceLoaderState;
}) => {
  const deleteTicketAndExperience = useDeleteTicketAndExperience();
  const resetAppSession = useStore((s) => s.resetAppSession);
  const handleClick = () => {
    deleteTicketAndExperience();
    resetAppSession();
  };
  const error =
    expLoaderState.status === ExperienceLoaderStatus.Error ? expLoaderState.error : undefined;
  const isTimeout =
    expLoaderState.status === ExperienceLoaderStatus.Error &&
    expLoaderState.experience?.state.terminationReason === TerminationReason.SessionTimeout;
  useEffect(() => {
    if (error && !isTimeout) {
      console.error('Error in queue:', error);
    }
  }, [error, isTimeout]);

  return (
    <>
      <TimeOutModal show={isTimeout} />
      <QueueErrorModal onGameRestartButtonClick={handleClick} show={Boolean(!isTimeout && error)} />
    </>
  );
};

const useBackButtonClickHandler = () => {
  const navigate = useNavigate();
  const deleteTicketAndExperience = useDeleteTicketAndExperience();
  return useCallback(() => {
    navigate(homePagePath);
    deleteTicketAndExperience();
  }, [deleteTicketAndExperience, navigate]);
};

const Root = styled.main`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const QueueStatusDisplayContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
