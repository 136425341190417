import { HydratedApp } from '@/api/entities/hydrated-app';
import { ReactNode } from 'react';
import styled from 'styled-components';
import clockTimeOutSvg from '@/assets/clock-time-out.svg';
import clockSvg from '@/assets/clock.svg';
import { P } from '@/components/text/P';
import { timeoutAnticipationTimeSec } from '../app/run/timeout';

const isAppTimeExpiring = (app: HydratedApp) => app.remainingTimeSec <= timeoutAnticipationTimeSec;

export const ClockBadge = ({ app }: { app: HydratedApp }): ReactNode => {
  return (
    <$ClockBadge $remainingTimeRatio={app.remainingTimeSec / app.totalTime}>
      <img src={isAppTimeExpiring(app) ? clockTimeOutSvg : clockSvg} />
      <P variant="sm">{Math.floor(app.remainingTimeSec / 60)}min</P>
    </$ClockBadge>
  );
};

const getClockBgColor = (remainingTimeRatio: number) => {
  if (remainingTimeRatio < 0.25) {
    return '#ff0a0a';
  }
  if (remainingTimeRatio < 0.5) {
    return '#dba400';
  }
  return '#3653cc';
};

const $ClockBadge = styled.div<{ $remainingTimeRatio: number }>`
  z-index: 5;
  width: 60%;
  height: 29px !important;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  align-items: center;
  gap: 7px;
  background: ${(p) => getClockBgColor(p.$remainingTimeRatio)};
  border-radius: 10px 0 0 10px;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  position: absolute;
  top: 30%;
  left: 46px;
  img {
    height: 22px;
  }
`;
