import { useShowBackground, useVideoBackgroundSrc } from '@/components/Background/background-hooks';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { SearchParameterBar } from './SearchParameterBar';
import { ResultList } from './ResultList';

export const SearchPage = (): ReactNode => {
  useVideoBackgroundSrc(null);
  useShowBackground(true);
  return (
    <$SearchPageRoot>
      <SearchParameterBar />
      <Separator />
      <ResultList />
    </$SearchPageRoot>
  );
};

const $SearchPageRoot = styled.div``;

const Separator = styled.hr`
  margin: 5px 0;
  border: 1px solid white;
  opacity: 0.2;
`;
