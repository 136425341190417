import styled from 'styled-components';
import searchIcon from '@/assets/search.png';
import { ReactNode, useRef } from 'react';
import { v6 } from 'uuid';
import { AppSearchInput } from './AppSearchInput';
import { SearchBarProps } from './SearchBar';
import { useMatch } from 'react-router-dom';
import { searchPagePath } from '@/routes/routePaths';

export const DesktopSearchBar = ({ value, onChange, onSubmit }: SearchBarProps): ReactNode => {
  const id = useRef(v6()).current;
  const isOnSearchPage = Boolean(useMatch(searchPagePath));
  return (
    <InputContainer htmlFor={id}>
      <img src={searchIcon} />
      <AppSearchInput
        id={id}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
        onBlur={isOnSearchPage ? onSubmit : undefined}
      />
    </InputContainer>
  );
};

const InputContainer = styled.label`
  display: flex;
  justify-content: stretch;
  align-items: center;
  max-width: 340px;
  width: 340px;
  height: 36px;
  gap: 16px;
  padding: 0 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 150px;
  backdrop-filter: blur(40px);
  cursor: text;
  @media screen and (max-width: 768px) {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    display: block;
    flex-grow: 0;
  }
`;
