import { useEffect } from 'react';

export const useDocumentEventListener = <K extends keyof DocumentEventMap>(
  event: K,
  handler: (this: Document, ev: DocumentEventMap[K]) => void,
): void => {
  useEffect(() => {
    document.addEventListener(event, handler);
    return () => {
      document.removeEventListener(event, handler);
    };
  }, [event, handler]);
};
