import { ReactNode, useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

export const MenuButton = ({
  onClick,
  show,
}: {
  onClick: () => void;
  show: boolean;
}): ReactNode => {
  const line1Ref = useRef<HTMLDivElement>(null);
  const line2Ref = useRef<HTMLDivElement>(null);
  const line3Ref = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    if (!timelineRef.current) {
      timelineRef.current = gsap
        .timeline()
        .to(line1Ref.current, { duration: 0.5, rotate: 405, y: 7, width: 20 }, 0)
        .to(line2Ref.current, { duration: 0.5, opacity: 0 }, 0)
        .to(line3Ref.current, { duration: 0.5, rotate: -405, y: -7, width: 20 }, 0);
    }
    if (show) {
      timelineRef.current.play();
    } else {
      timelineRef.current.reverse();
    }
  }, [show]);

  return (
    <$MenuButton onClick={onClick}>
      <Line ref={line1Ref} />
      <Line ref={line2Ref} />
      <Line ref={line3Ref} />
    </$MenuButton>
  );
};

const $MenuButton = styled.li`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  pointer-events: all;
  position: relative;
  z-index: 4;
  width: 40px;
  cursor: pointer;
`;

const Line = styled.div`
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: white;
`;
