import { ReactNode } from 'react';
import styled from 'styled-components';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { getSearchPageUrl } from '@/routes/routePaths';
import { TagLinkView, TagLink } from '@/components/tag/Tag';

export const TagLinkScrollList = (): ReactNode => {
  const { tags } = useLoadedCatalog();
  const list = [
    <TagLinkView key="all" to={getSearchPageUrl()}>
      All
    </TagLinkView>,
    ...tags.map((tag) => <TagLink tag={tag} key={tag.slug} />),
  ];
  return (
    <TagScrollList>
      {list.map((tag) => (
        <ScrollWrapper key={tag.key}>{tag}</ScrollWrapper>
      ))}
    </TagScrollList>
  );
};

const TagScrollList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 150px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  pointer-events: all;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollWrapper = styled.div`
  height: 50px;
  width: fit-content;
  padding: 0 0 0 32px;
  scroll-snap-align: start;
`;
