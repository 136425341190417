import { useEffect, useState } from 'react';
import { isHttpError } from '@/lib/isHttpError';
import { useStore } from '@/store/store';
import { useEffectUntil } from '@/lib/hooks/useEffectUntil';
import { useFetchTicketMutation } from './useFetchTicketMutation';
import { sleep } from '@/lib/sleep';
import { useGetTicket } from '@/api/services/ticket';
import { AppMetadata, AppUuid } from '@/api/entities/app';
import { TicketWithState, TicketStatus, Ticket } from '@/api/entities/ticket';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

const ticketPollIntervalMs = 3000;

export enum TicketLoaderStatus {
  Disabled = 'Disabled',
  Loading = 'Loading',
  Error = 'Error',
  Queued = 'Queued',
  Unqueued = 'Unqueued',
}

export type TicketLoaderDisabledState = {
  status: TicketLoaderStatus.Disabled;
  ticket?: undefined;
};
export type TicketLoaderLoadingState = {
  status: TicketLoaderStatus.Loading;
  ticket?: undefined;
};
export type TicketLoaderErrorState = {
  status: TicketLoaderStatus.Error;
  ticket?: undefined;
  error: TicketLoaderError;
};
export type TicketLoaderQueuedState = {
  status: TicketLoaderStatus.Queued;
  ticket: TicketWithState;
  position: number;
};
export type TicketLoaderUnqueuedState = {
  status: TicketLoaderStatus.Unqueued;
  ticket: TicketWithState;
};

export type TicketLoaderState =
  | TicketLoaderDisabledState
  | TicketLoaderLoadingState
  | TicketLoaderErrorState
  | TicketLoaderQueuedState
  | TicketLoaderUnqueuedState;

type TocketLoaderOptions = {
  enable?: boolean;
};

export const useTicketLoader = (
  app: AppMetadata,
  options?: TocketLoaderOptions,
): TicketLoaderState => {
  const enable = options?.enable ?? true;
  const { data: ticket, error: ticketError } = useFetchAndRefreshTicket(app, enable);
  if (!enable) {
    return { status: TicketLoaderStatus.Disabled };
  }
  if (isHttpError(ticketError) && [418, 503].includes(ticketError.status)) {
    return {
      status: TicketLoaderStatus.Error,
      error: new QueueIsFullError(),
    };
  }
  if (ticketError) {
    return { status: TicketLoaderStatus.Error, error: ticketError };
  }
  if (!ticket) {
    return { status: TicketLoaderStatus.Loading };
  }
  switch (ticket.state.status) {
    case TicketStatus.Dropped:
      return { status: TicketLoaderStatus.Error, error: new DroppedTicketError(ticket) };
    case TicketStatus.Invalid:
      return { status: TicketLoaderStatus.Error, error: new InvalidTicketError(ticket) };
    case TicketStatus.Inactive:
      return { status: TicketLoaderStatus.Error, error: new InactiveTicketError(ticket) };
    case TicketStatus.Queued:
      return { status: TicketLoaderStatus.Queued, ticket, position: ticket.state.position };
    case TicketStatus.Unqueued:
      return { status: TicketLoaderStatus.Unqueued, ticket };
  }
};

const useFetchAndRefreshTicket = (
  app: AppMetadata,
  enable: boolean,
): UseMutationResult<TicketWithState, Error, AppUuid> | UseQueryResult<TicketWithState> => {
  const setLastTicket = useStore((state) => state.setLastTicket);
  const fetchTicketMutation = useFetchTicketMutation();
  const ticketId = fetchTicketMutation.data?.id;
  const [isTicketPollable, setIsTicketPollable] = useState(false);
  const [isTicketUnqueued, setIsTicketUnqueued] = useState(false);
  const [errorWasFound, setErrorWasFound] = useState(false);
  const getTicketQuery = useGetTicket(ticketId ?? '', {
    enabled: enable && !errorWasFound && isTicketPollable && !isTicketUnqueued,
    refetchInterval: ticketPollIntervalMs,
  });
  useEffectUntil(
    (done) => {
      if (!enable) {
        return;
      }
      done();
      (async () => {
        // 1- Fetch a ticket
        const ticket = await fetchTicketMutation.mutateAsync(app.uuid);
        setLastTicket({
          id: ticket.id,
          lastInteractionDate: ticket.queuedAt,
          appUuid: app.uuid,
          appSlug: app.nameSlug,
        });
        // 2- Wait a bit...
        await sleep(ticketPollIntervalMs);
        // 3- Then periodically refresh the state
        setIsTicketPollable(true);
      })();
    },
    [app, enable, fetchTicketMutation, setLastTicket],
  );
  const isUnqueued = getTicketQuery.data?.state.status === TicketStatus.Unqueued;
  useEffect(() => {
    if (isUnqueued) {
      // We are out of the queue, so we stop polling.
      setIsTicketUnqueued(true);
    }
  }, [isUnqueued]);

  const query =
    isTicketPollable && !getTicketQuery.isLoading ? getTicketQuery : fetchTicketMutation;
  const queryError = query.error;

  useEffect(() => {
    if (queryError) {
      setErrorWasFound(true);
    }
  }, [queryError]);

  return query;
};

export class TicketLoaderError extends Error {
  constructor(message?: string) {
    super(message || 'Ticket loader error');
    this.name = TicketLoaderError.name;
  }
}
export class QueueIsFullError extends TicketLoaderError {
  constructor() {
    super('Ticket queue is full');
  }
}
export abstract class TicketLoaderWithTicketError extends TicketLoaderError {
  constructor(
    public readonly ticket: Ticket,
    message?: string,
  ) {
    super(message);
  }
}
export class DroppedTicketError extends TicketLoaderWithTicketError {
  constructor(public readonly ticket: Ticket) {
    super(ticket, `Ticket ${ticket.id} was dropped`);
  }
}
export class InvalidTicketError extends TicketLoaderWithTicketError {
  constructor(public readonly ticket: Ticket) {
    super(ticket, `Ticket ${ticket.id} is invalid`);
  }
}
export class InactiveTicketError extends TicketLoaderWithTicketError {
  constructor(public readonly ticket: Ticket) {
    super(ticket, `Ticket ${ticket.id} is inactive`);
  }
}
