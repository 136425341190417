import { TagSlug } from '@/api/entities/tags';
import { generatePath } from 'react-router-dom';

// /
export const homePagePath = '/';

// /game/:appSlug
export const appRootPath = `/game/:appSlug`;
export const getAppRootPageUrl = (appSlug: string): string => {
  return generatePath(appRootPath, { appSlug });
};

// /game/:appSlug/play
export const appRunPath = `${appRootPath}/play`;
export const getAppRunPageUrl = (appSlug: string): string => {
  return generatePath(appRunPath, { appSlug });
};

// /search/:queryText?tags=:tags
export const searchPagePath = `/search/:queryText?`;
export const getSearchPageUrl = ({
  queryText,
  ...filters
}: { queryText?: string; tags?: TagSlug[] } = {}): string => {
  const getParams = new URLSearchParams();
  for (const tag of filters.tags || []) {
    getParams.append('tag', tag);
  }
  const path = generatePath(searchPagePath, { queryText: queryText || null });
  return `${path}?${getParams.toString()}`;
};
