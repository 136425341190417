import { ReactNode, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import styled from 'styled-components';
import { ExternalLinks } from './ExternalLinks';

export const HamburgerMenu = ({ show }: { show: boolean }): ReactNode => {
  const navRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (!navRef.current) return;
    gsap.to(
      navRef.current,
      show
        ? { duration: 0.5, opacity: 1, top: '64px' }
        : { duration: 0.5, opacity: 0, top: '-100%' },
    );
  }, [show]);

  return (
    <Nav ref={navRef}>
      <Ul>
        <ExternalLinks />
      </Ul>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: 104px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 64px);
  backdrop-filter: blur(70px) saturate(70%);
  opacity: 0;
  top: -100%;
  z-index: 9999;
  &:before {
    content: '';
    position: fixed;
    z-index: 2;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
  }
`;

const Ul = styled.ul`
  color: white;
  padding: 50px 34px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 4;
`;
