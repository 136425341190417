import { z } from 'zod';

export type AppTimeMetadata = {
  remainingTimeSec: number;
  usedTimeSec: number;
};

export const appTimeMetadataZod = z.object({
  remaining_time: z.number(),
  used_time: z.number().min(0),
});
