import { z } from 'zod';
import { KeyMappingConfig } from '@genymotion/device-web-player';
import { keyMappingZod } from './keymapping';
import { TagSlug } from './tags';

export type AppId = number;
export type AppUuid = string;
export type ImageId = string;
export type Url = string;

export type Orientation = 'landscape' | 'portrait';

export type AppMetadata = {
  id: AppId;
  uuid: AppUuid;
  imageId: ImageId;
  name: string;
  nameSlug: string;
  createdAt: Date;
  updatedAt: Date;
  icon: Url;
  banner: Url;
  video?: Url;
  editorName: string;
  disabled: boolean;
  orientation: Orientation;
  tagSlugs: TagSlug[];
  keymap?: KeyMappingConfig;
};

export const appUuidZod = z.string().min(1);
export const appImageIdZod = z.string().min(1);
export const appSlugZod = z.string().min(1);

export const appZod = z.object({
  id: z.number(),
  uuid: appUuidZod,
  image_id: appImageIdZod,
  name: z.string().min(1),
  slug: appSlugZod,
  content: z.any().optional(), // Is validated separately
  created_at: z.string().min(1),
  updated_at: z.string().min(1),
  icon: z.string().min(1),
  banner: z.string().min(1),
  video: z.string().min(1).nullable(),
  tags: z.array(z.string().min(1)),
  editor_name: z.string().min(1),
  disabled: z.boolean(),
});

type BackendApp = z.infer<typeof appZod>;

export const parseBackendApp = (app: BackendApp): AppMetadata => ({
  id: app.id,
  uuid: app.uuid,
  imageId: app.image_id,
  name: app.name,
  nameSlug: app.slug,
  createdAt: new Date(app.created_at),
  updatedAt: new Date(app.updated_at),
  icon: app.icon,
  banner: app.banner,
  video: app.video || undefined,
  editorName: app.editor_name,
  disabled: app.disabled,
  orientation: parseOrientation(app),
  tagSlugs: app.tags,
  keymap: parseKeymap(app),
});

const parseKeymap = (responseRecord: BackendApp): KeyMappingConfig | undefined => {
  // Keymap comes from an unsafe JSON. Therefore we validate.
  const contentZod = z.object({ keymap: keyMappingZod.optional() });
  const res = contentZod.safeParse(responseRecord.content);
  if (res.success) {
    return res.data.keymap;
  }
  console.warn(
    `${responseRecord.name} provides invalid keymap: ${JSON.stringify(responseRecord.content, null, 2)}`,
  );
  return undefined;
};

const parseOrientation = (responseRecord: BackendApp): Orientation => {
  // Orientation comes from an unsafe JSON. Therefore we validate and provide a fallback value.
  const contentZod = z.object({
    orientation: z.enum(['landscape', 'portrait']),
  });
  const res = contentZod.safeParse(responseRecord.content);
  if (res.success) {
    return res.data.orientation;
  }
  console.warn(
    `${responseRecord.name} provides invalid orientation: ${JSON.stringify(responseRecord.content, null, 2)}`,
  );
  return 'landscape';
};
