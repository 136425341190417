import { LinkButton } from '@/components/button/Button';
import { H6 } from '@/components/text/H';
import { P } from '@/components/text/P';
import { AppMetadata } from '@/api/entities/app';
import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  app: AppMetadata;
  text?: string;
  className?: string;
} & ({ to?: To; onClick?: undefined } | { to?: undefined; onClick?: () => void });

export const AppLink = forwardRef(
  ({ app, to, onClick, text, className }: Props, forwardedRef): ReactNode => {
    const rootRef = useRef<HTMLAnchorElement>(null);
    useImperativeHandle(forwardedRef, () => rootRef.current);
    return (
      <Root ref={rootRef} to={(!onClick && to) || ''} onClick={onClick} className={className}>
        <Icon src={app.icon} />
        <DetailsContainer>
          <Text>{text}</Text>
          <Name>{app.name}</Name>
        </DetailsContainer>
      </Root>
    );
  },
);

const Root = styled(LinkButton)`
  height: 100px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  width: 400px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-out;
  &:hover {
    filter: drop-shadow(0px 4px 20px #3653cc);
    transform: scale(1.1);
  }
`;

const Icon = styled.img`
  display: block;
  max-height: 100%;
  margin: 10px;
  border-radius: 15px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Text = styled(P)`
  display: block;
  min-height: 20px;
`;

const Name = styled(H6)`
  display: block;
`;
