import { H6, H3 } from '@/components/text/H';
import { P } from '@/components/text/P';
import { UnexpectedPathError } from '@/lib/errors';
import { AppMetadata } from '@/api/entities/app';
import { useGSAP } from '@gsap/react';
import chevronleftSvg from '@/assets/chevron-left.svg';
import gsap from 'gsap';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { ExperienceLoaderState, ExperienceLoaderStatus } from '../useExperienceLoader';
import { TicketLoaderState, TicketLoaderStatus, TicketLoaderQueuedState } from '../useTicketLoader';

type Props = {
  app: AppMetadata;
  experienceLoaderState: ExperienceLoaderState;
  onBackButtonClick?: () => void;
};

export const QueueStatusDisplay = ({
  app,
  experienceLoaderState,
  onBackButtonClick,
}: Props): ReactNode => {
  return (
    <Root>
      <AppLogo src={app.icon} />
      <Title>Waiting line</Title>
      <ExplainationContainer>
        <ExperienceStateExplainations state={experienceLoaderState} />
      </ExplainationContainer>
      <ProgressionBar />
      <BackButton onClick={onBackButtonClick} />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 375px;
  height: 425px;
  display: flex;
`;

const Title = styled(H6)`
  text-align: center;
`;

const ExplainationContainer = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExperienceStateExplainations = ({ state }: { state: ExperienceLoaderState }) => {
  switch (state.status) {
    case ExperienceLoaderStatus.LastExperienceSave:
      return <LastExperienceIsSavingExplainations />;
    case ExperienceLoaderStatus.Ticket:
      return <TicketStateExplainations state={state.ticketLoaderState} />;
    case ExperienceLoaderStatus.Loading:
      return <LoadingExplainations />;
    case ExperienceLoaderStatus.Associated:
    case ExperienceLoaderStatus.Billable:
      return <ImminentStartExplaination />;
    case ExperienceLoaderStatus.Error:
      return <ErrorExplaination />;
  }
};

const TicketStateExplainations = ({ state }: { state: TicketLoaderState }) => {
  switch (state.status) {
    case TicketLoaderStatus.Loading:
      return <LoadingExplainations />;
    case TicketLoaderStatus.Error:
      return <ErrorExplaination />;
    case TicketLoaderStatus.Queued:
      return <QueuedExplaination state={state} />;
    case TicketLoaderStatus.Unqueued:
      return <ImminentStartExplaination />;
    case TicketLoaderStatus.Disabled:
      throw new UnexpectedPathError();
  }
};

const LastExperienceIsSavingExplainations = () => {
  return (
    <ExplainationText>
      Your previous game is still saving.
      <br />
      <br />
      Please wait patiently.
    </ExplainationText>
  );
};

const LoadingExplainations = () => {
  // This micro-state doesnt last long enough to display an interesting message.
  // In addition, it usually happens between two main states, so we dont want to see a flickering text.
  return null;
};

const ErrorExplaination = () => {
  return <ExplainationText>Encountered an error...</ExplainationText>;
};

const cardinalSuffixes = ['st', 'nd', 'rd'];
const QueuedExplaination = ({ state: { position } }: { state: TicketLoaderQueuedState }) => {
  const cardinalSuffix = cardinalSuffixes[position] || 'th';
  return (
    <>
      <H3>
        {position + 1}
        <CardinalSuffix>{cardinalSuffix}</CardinalSuffix>
      </H3>
      <ExplainationText>
        You are currently {position + 1}
        {cardinalSuffix} in the waiting list.
        <br />
        Your game session will start automatically when space is available.
      </ExplainationText>
    </>
  );
};

const CardinalSuffix = styled(P).attrs({ variant: 'lg' })`
  display: inline;
  margin: 3px;
`;

const ImminentStartExplaination = () => {
  return (
    <ExplainationText>
      Your gaming environment is starting.
      <br />
      <br />
      Please wait patiently.
    </ExplainationText>
  );
};

const ProgressionBar = () => {
  const rootRef = useRef(null);
  const loadRef = useRef(null);
  useGSAP(() => {
    const loadBar = loadRef.current;
    const container = rootRef.current;
    const tl = gsap.timeline({ repeat: -1 });
    tl.to(loadBar, { duration: 1, width: '100%', ease: 'power1.inOut' })
      .to(container, { duration: 0, justifyContent: 'flex-end' })
      .to(loadBar, { duration: 1, width: 0, ease: 'power1.inOut' })
      .to(container, { duration: 0, justifyContent: 'flex-start' });
  }, []);
  return (
    <$ProgressionBar ref={rootRef}>
      <div ref={loadRef} />
    </$ProgressionBar>
  );
};

const $ProgressionBar = styled.div`
  display: flex;
  align-self: stretch;
  height: 46px;
  background: #9ba9e61a;
  border-radius: 8px;
  justify-content: flex-start;
  filter: drop-shadow(0px 4px 40px #3653cc66);
  div {
    background-image: radial-gradient(
      156.17% 174.11% at -9.65% 97.25%,
      #34baf4 0%,
      #3653cc 57.29%,
      #4324bf 100%
    );
    border-radius: 8px;
  }
`;

const BackButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <$BackButton onClick={onClick}>
      <img src={chevronleftSvg} alt="" />
      <P>Back</P>
    </$BackButton>
  );
};

const AppLogo = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 8px;
  display: block;
  align-self: center;
`;

const ExplainationText = styled(P).attrs({ variant: 'sm' })`
  opacity: 0.75;
  text-align: center;
`;

const $BackButton = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
  pointer-events: all;
  ${P} {
    font-weight: 400;
  }
`;
