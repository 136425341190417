import { useStore } from '@/store/store';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { H6 } from '@/components/text/H';
import heartIcon from '@/assets/heart-icon.svg';
import { CloseButton } from '@/components/modal/inner-components';
import { EditorLink } from './AppDisplay';
import { P } from '@/components/text/P';
import { LinkButton } from '@/components/button/Button';
import { getAppRunPageUrl } from '@/routes/routePaths';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { HydratedApp } from '@/api/entities/hydrated-app';
import { TagBadge } from '@/components/tag/Tag';
import { timeoutAnticipationTimeSec } from '../app/run/timeout';

export const AppInfo = (): ReactNode => {
  const setHomePageExpandedApp = useStore((state) => state.setHomePageExpandedApp);
  const selectedAppUuid = useStore((state) => state.homePageExpandedApp);
  const { catalog } = useLoadedCatalog();
  const app = catalog.find((app: HydratedApp) => app.uuid === selectedAppUuid?.appUuid);
  const rootRef = useRef<HTMLDivElement>(null);
  if (!app) {
    return null;
  }
  const isAppTimeExpired = app.remainingTimeSec <= timeoutAnticipationTimeSec;
  return (
    <Root ref={rootRef}>
      <Video key={app.id} src={app.video} />
      <Content>
        <Row>
          <Title>
            <FavoriteIcon /> <$H6>{app.name}</$H6>
          </Title>
          <Close onClick={() => setHomePageExpandedApp(undefined)} />
        </Row>
        <EditorAndTags>
          <EditorLink to="">
            <P variant="md">{app.editorName}</P>
          </EditorLink>
          <$TagList>
            {app.tags.map((tag) => (
              <TagBadge key={tag.slug} tag={tag} variant="small" />
            ))}
          </$TagList>
        </EditorAndTags>
        <PlayAndCarousel>
          <PlayLinkButton to={getAppRunPageUrl(app.nameSlug)} disabled={isAppTimeExpired}>
            Play Now
          </PlayLinkButton>
        </PlayAndCarousel>
        <Description>
          <P variant="sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure sed totam neque optio,
            voluptates provident, ab illum nulla laudantium reprehenderit a eius earum omnis dolorem
            officia molestiae non deleniti illo vero ipsa aliquid fugit enim. Nulla beatae rem
            aliquam ipsam dolore, quo voluptate nisi neque. Cumque corporis odio earum ipsa ratione
            distinctio ipsum eligendi accusantium, at fuga eos adipisci asperiores quos, accusamus
            numquam ex.
          </P>
        </Description>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 25px;
  transition: all 0.3s ease-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background: none;
  position: relative;
  background: linear-gradient(92.88deg, #101322 29.05%, rgba(16, 19, 34, 0) 85.64%);
  height: 100%;
`;
const $H6 = styled(H6)`
  margin-left: 20px;
`;
const Video = styled.video.attrs({
  autoPlay: true,
  loop: true,
  muted: true,
  controls: true,
  playsInline: true,
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  filter: brightness(0.8);
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: none;
  position: relative;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-family: 'Nexa Text';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Close = styled(CloseButton)`
  all: unset;
  cursor: pointer;
`;

const FavoriteIcon = styled.img.attrs({ src: heartIcon })``;

const EditorAndTags = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const PlayAndCarousel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const PlayLinkButton = styled(LinkButton)`
  padding-left: 40px;
  padding-right: 40px;
`;

const Description = styled.div`
  margin-top: 40px;
  width: 490px;
`;

const $TagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;
