import { useEffect } from 'react';
import { useStore } from '@/store/store';

export const useShowBackground = (showBackground = true): void => {
  const setShowBackground = useStore((s) => s.setShowBackground);
  useEffect(() => {
    setShowBackground(showBackground);
  }, [setShowBackground, showBackground]);
};

export const useVideoBackgroundSrc = (videoBackgroundSrc: string | undefined | null): void => {
  const setVideoBackgroundSrc = useStore((s) => s.setVideoBackgroundSrc);
  useEffect(() => {
    setVideoBackgroundSrc(videoBackgroundSrc ?? undefined);
  }, [setVideoBackgroundSrc, videoBackgroundSrc]);
};
