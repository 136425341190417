import { ForwardedRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { UserAvatarImg } from './AvatarImg';
import { getNameFromAuthUser } from './utils';
import { useAuth } from '@npm/react-oidc-context';
import { Button, LinkButton } from '../button/Button';
import { P } from '../text/P';
import { shadowWebsite } from '@/lib/shadowUrls';
import { CloseButton } from '../modal/inner-components';
import { ModalWindow } from '../modal/ModalWindow';

interface ProfileModalProps {
  onCloseRequest?: () => void;
}

export const ProfileModal = forwardRef(
  ({ onCloseRequest }: ProfileModalProps, ref: ForwardedRef<HTMLDivElement>): ReactNode => {
    const { user } = useAuth();
    if (!user) {
      return null;
    }
    return (
      <Root ref={ref}>
        <CloseButton onClick={onCloseRequest} />
        <$AvatarImg src={user.profile.picture} />
        <Name>{getNameFromAuthUser(user)}</Name>
        <Email>{user.profile.email}</Email>
        <ManageMyAccountButton />
        <SignOutButton />
      </Root>
    );
  },
);

const Root = styled(ModalWindow)`
  width: 297px;
  background-color: rgba(197, 211, 252, 0.1);
`;

const SignOutButton = () => {
  const auth = useAuth();
  const handleClick = () => {
    auth.removeUser();
  };
  return (
    <LogoutButton onClick={handleClick} variant="white">
      Disconnect
    </LogoutButton>
  );
};

const ManageMyAccountButton = () => {
  return (
    <$ManageMyAccountLink to={shadowWebsite.accountUrl} target="_blank">
      Account preferences
    </$ManageMyAccountLink>
  );
};

const $AvatarImg = styled(UserAvatarImg)`
  max-width: 56px;
  max-height: 56px;
`;

const Name = styled(P).attrs({ variant: 'lg' })`
  font-weight: bold;
`;

const Email = styled(P).attrs({ variant: 'sm' })``;

const $ManageMyAccountLink = styled(LinkButton)`
  width: 200px;
  margin-top: 20px;
`;

const LogoutButton = styled(Button)`
  width: 200px;
  margin-top: 10px;
`;
