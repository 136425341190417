import { PropsWithChildren, ReactNode } from 'react';
import { SwitchTransition, Transition, TransitionGroup } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import {
  addTransitionEndListener,
  dispatchTransitionEndEvent,
  OutletContext,
} from './SwitchTransitionOutlet';

type FadeInOutTransitionComponentProp = PropsWithChildren<{
  mode?: 'out-in' | 'in-out' | 'in-out-at-once';
  outletContext: OutletContext;
}>;

export const FadeInOutSwitchTransition = ({
  children,
  outletContext,
  mode,
}: FadeInOutTransitionComponentProp): ReactNode => {
  const location = useLocation();
  const transition = (
    <Transition
      key={location.pathname}
      nodeRef={outletContext.ref}
      onEnter={() => {
        const node = outletContext.ref.current;
        const onEnter = outletContext.onEnter;
        if (node && onEnter) {
          onEnter(node, () => dispatchTransitionEndEvent(node));
        }
      }}
      onExit={() => {
        const node = outletContext.ref.current;
        const onExit = outletContext.onExit;
        if (node && onExit) {
          onExit(node, () => dispatchTransitionEndEvent(node));
        }
      }}
      addEndListener={(done) => {
        if (outletContext.ref.current) {
          addTransitionEndListener(outletContext.ref.current, done);
        }
      }}
    >
      {children}
    </Transition>
  );
  if (mode === 'in-out-at-once') {
    return <TransitionGroup component={null}>{transition}</TransitionGroup>;
  }
  return <SwitchTransition mode={mode}>{transition}</SwitchTransition>;
};
