import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import { useBoolState } from '@/lib/hooks/useBoolState';
import { useValueRef } from '@/lib/hooks/useValueRef';

type FadeInOutProps = PropsWithChildren<{
  show?: boolean;
  duration?: number;
  className?: string;
}>;

/**
 * Fade-in and fade-out the children.
 * Keep in mind that once show=false, the children will still be shown during the fade-out animation.
 */
export const FadeInOut = ({ show, duration, className, children }: FadeInOutProps): ReactNode => {
  const rootAnimRef = useRef<HTMLDivElement>(null);
  const [keepMounted, forbidMount, allowUnmount] = useBoolState(false);
  const keepMountedRef = useValueRef(keepMounted);
  const durationRef = useValueRef(duration ?? 0.3);
  const tweenRef = useRef<gsap.core.Tween>();
  useGSAP(() => {
    if (show) {
      keepMountedRef.current = true;
      forbidMount();
      tweenRef.current = gsap.from(rootAnimRef.current, {
        opacity: 0,
        duration: durationRef.current,
        onReverseComplete: allowUnmount,
      });
    } else if (keepMountedRef.current) {
      tweenRef.current?.reverse();
    }
  }, [allowUnmount, durationRef, forbidMount, keepMountedRef, show]);
  if (!show && !keepMounted) {
    return null;
  }
  return (
    <div ref={rootAnimRef} className={className}>
      {children}
    </div>
  );
};
