import { Button } from '@/components/button/Button';
import errorSvg from '@/assets/error.svg';
import { forwardRef, ReactNode } from 'react';
import { P } from '@/components/text/P';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';
import { TerminationReason } from '@/api/entities/experience';

interface Props {
  onEndButtonClick?: () => void;
  reason?: TerminationReason | string;
}

export const ExperienceErrorModalWindow = forwardRef(
  (
    { onEndButtonClick, reason = TerminationReason.ProviderError }: Props,
    forwardedRef,
  ): ReactNode => {
    const { icon, title, message } =
      messagesPerReason[reason] || messagesPerReason[TerminationReason.ProviderError];
    return (
      <ModalWindow ref={forwardedRef}>
        <ModalContent>
          <img src={icon} alt="icon" />
          <ModalTitle>{title}</ModalTitle>
          <P variant="sm">{message}</P>
        </ModalContent>
        <ModalCta>
          <Button onClick={onEndButtonClick}>Back to game selection</Button>
        </ModalCta>
      </ModalWindow>
    );
  },
);

export const ExperienceErrorModal = createModal(ExperienceErrorModalWindow);

interface Messages {
  icon: string;
  title: string;
  message: string;
}

const messagesPerReason: Record<string, Messages> = {
  [TerminationReason.ProviderError]: {
    icon: errorSvg,
    title: 'Oh no, the game was terminated!',
    message: 'The game encountered an strange error...',
  },
  [TerminationReason.SessionTimeout]: {
    icon: errorSvg,
    title: 'Your game was terminated',
    message: 'Your game session has timed out.',
  },
  [TerminationReason.UserEndSession]: {
    icon: errorSvg,
    title: 'Your game was terminated',
    message: 'You opened another game, we we shut down this one.',
  },
  [TerminationReason.UserInactivity]: {
    icon: errorSvg,
    title: 'Your game was terminated',
    message: 'We detected that you are not here with us anymore.',
  },
};
