/* eslint-disable @typescript-eslint/no-unused-vars */

import slideSvg from '@/assets/gameController/slide.svg';
import quicktapSvg from '@/assets/gameController/quicktap.svg';
import longpressSvg from '@/assets/gameController/longpress.svg';
import doubletapSvg from '@/assets/gameController/doubletap.svg';
import x2Svg from '@/assets/gameController/x2.svg';
import twofingertapSvg from '@/assets/gameController/twofingertap.svg';
import { ReactNode } from 'react';
import { KeyMappingPanelTitle as Title, KeyboardMappingRow as Row } from './layout';

// TODO Figure out what we want for the trackpad

export const TrackpadList = (): ReactNode => {
  return (
    <>
      <Title>Trackpad</Title>
      <ul>{/* <Row keyVal={key} description={description} /> */}</ul>
    </>
  );
};
