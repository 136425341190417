import styled from 'styled-components';
import { forwardRef, ReactNode } from 'react';
import { Rating } from './Rating';
import { Issue } from './Issue';
import { Recommend } from './Recommend';
import { Email } from './Email';
import { Share } from './Share';
import { Crash } from './Crash';
import { OtherIssue } from './OtherIssue';
import feedbackSVG from '@/assets/feedback/feedback.svg';
import { useFeedbackStore, Status } from './store/store';
import { BackButton } from './BackButton';
import { useStore } from '@/store/store';
import { P } from '@/components/text/P';
import { CloseButton, ModalTitle } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';

const feedbackTitles: Record<Status, string> = {
  [Status.Rating]: 'How would you rate your experience?',
  [Status.Recommend]: 'Do you recommend?',
  [Status.Email]: 'Want to keep in touch?',
  [Status.Share]: 'Share your experience',
  [Status.Crash]: 'Crash',
  [Status.Other]: 'Other issues',
  [Status.Issue]: 'Describe your issue',
};

interface Props {
  onRequestClose: () => void;
}

export const FeedbackModalWindow = forwardRef(
  ({ onRequestClose }: Props, forwardedRef): ReactNode => {
    const { status, setGaveFeedback } = useFeedbackStore();
    const ticketId = useStore((s) => s.lastTicket?.id);

    const title = feedbackTitles[status];
    const handleClose = () => {
      onRequestClose();
      setGaveFeedback(true);
    };
    return (
      <$ModalWindow ref={forwardedRef}>
        <CloseButton onClick={handleClose} />
        <Content>
          <FeedbackIcon src={feedbackSVG} alt="feedback icon" />
          <ModalTitle>
            {title}
            <SessionID variant="sm">Game Session ID: {ticketId}</SessionID>
          </ModalTitle>

          {status === Status.Issue && <Issue />}
          {status === Status.Rating && <Rating />}
          {status === Status.Recommend && <Recommend />}
          {status === Status.Email && <Email />}
          {status === Status.Share && <Share handleClose={handleClose} />}
          {status === Status.Crash && <Crash />}
          {status === Status.Other && <OtherIssue />}

          {status !== Status.Rating && status !== Status.Share && <BackButton />}
        </Content>
      </$ModalWindow>
    );
  },
);

export const FeedbackModal = createModal(FeedbackModalWindow);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
`;

const FeedbackIcon = styled.img``;

const SessionID = styled(P)`
  opacity: 0.5;
  margin-top: 16px;
`;

// TODO Refactor: Extract each class and associate it with its own component.
// TODO StyledFeedBackModal should be replaced by vanilla Modal once this is done.
const $ModalWindow = styled(ModalWindow)`
  width: 500px;
  background: #222739;
  padding: 30px 0;
  @media screen and (max-width: 768px) {
    width: 95vw;
  }
`;
