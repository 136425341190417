interface Options {
  floor?: boolean;
}

export function secToMin(sec: number, options?: Options): number;
export function secToMin(sec: undefined, options?: Options): undefined;
export function secToMin(sec: number | undefined, options?: Options): number | undefined;
export function secToMin(sec: number | undefined, options?: Options): number | undefined {
  const useFloor = options?.floor ?? true;
  if (sec === undefined) return undefined;
  const result = sec / 60;
  return useFloor ? Math.floor(result) : result;
}
