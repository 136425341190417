import { useEffect } from 'react';

export const useScreenOrientationEventListener = (
  handler: (this: ScreenOrientation, ev: Event) => void,
): void => {
  useEffect(() => {
    const orientation = window.screen.orientation;

    orientation.addEventListener('change', handler);

    return () => {
      orientation.removeEventListener('change', handler);
    };
  }, [handler]);
};
