import { shadowWebsite } from '@/lib/shadowUrls';
import { P } from '../text/P';
import { ReactNode } from 'react';
export const ExternalLinks = (): ReactNode => {
  return (
    <>
      <li>
        <a href={shadowWebsite.gameStoreUrl} target={'_blank'} rel="noreferrer">
          <P variant="sm">Game Store</P>
        </a>
      </li>
      <li>
        <a href={shadowWebsite.homePageUrl} target={'_blank'} rel="noreferrer">
          <P variant="sm">Shadow PC Gaming</P>
        </a>
      </li>
    </>
  );
};
