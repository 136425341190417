import { ReactNode, useRef } from 'react';
import { useAuth } from '@npm/react-oidc-context';
import styled from 'styled-components';
import { ProfileModal } from './ProfileModal';
import { useBoolState } from '@/lib/hooks/useBoolState';
import { useOutsideClick } from '@/lib/hooks/useOutsideClick';
import { Badge } from './Badge';
import { getNameFromAuthUser } from './utils';
import { FadeInOut } from '../animation/FadeInOut';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import authPlaceholder from '@/assets/auth-placeholder.png';

export const SignedInBadge = (): ReactNode => {
  const { user } = useAuth();
  const [isModalVisible, , hideModal, toggleModal] = useBoolState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const modalRef = useOutsideClick<HTMLDivElement>(
    hideModal,
    [buttonRef] /* Prevent reopening the modal */,
  );
  if (!user) {
    return null;
  }
  // <img src={authPlaceholder} onClick={toggleModal} />

  return (
    <Root>
      {isMobile ? (
        <img src={authPlaceholder} onClick={toggleModal} />
      ) : (
        <Badge
          ref={buttonRef}
          onClick={toggleModal}
          isActive={isModalVisible}
          title={getNameFromAuthUser(user)}
          subtitle={user.profile.email}
          icon={user.profile.picture}
        />
      )}
      <$FadeInOut show={isModalVisible}>
        <ProfileModal ref={modalRef} onCloseRequest={hideModal} />
      </$FadeInOut>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const $FadeInOut = styled(FadeInOut)`
  z-index: 100;
  position: absolute;
  margin-top: 10px;
  right: 0px;
  backdrop-filter: blur(10px);
`;
