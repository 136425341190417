import ReactSelect, { Props } from 'react-select';
import { P } from '@/components/text/P';
import { ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';

interface Option<OptionValue extends string = string> {
  value: OptionValue;
  label: ReactNode;
}

export type SelectProps<OptionValue extends string> = Omit<
  Props<Option, false>,
  'value' | 'onChange'
> & {
  options?: Option<OptionValue>[];
  value?: OptionValue;
  onChange?: (newValue: OptionValue) => void;
};

export function Select<OptionValue extends string = string>({
  options,
  value,
  onChange,
  ...props
}: SelectProps<OptionValue>): ReactNode {
  const handleChange = useCallback(
    (newOption: unknown) => onChange?.((newOption as Option<OptionValue>).value),
    [onChange],
  );
  return (
    <$SortSelect
      // This lib has super dupper annoying typings so fuck'em.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      value={options?.find((o) => (o as unknown as Option<OptionValue>).value === value)}
      options={options}
      onChange={handleChange}
    />
  );
}

const optionTextCss = css`
  ${P.componentStyle.rules}
  color: white;
`;

const $SortSelect = styled(ReactSelect).attrs({
  classNamePrefix: 'select',
})`
  cursor: pointer;
  .select__control {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  .select__single-value {
    ${optionTextCss}
  }
  .select__indicator-separator {
    display: none;
  }
  .select__dropdown-indicator {
    padding-left: 0;
  }
  .select__menu {
    transition: all 0.5s;
    border-radius: 8px;
    padding: 0;
    overflow: hidden;
    border: 1px solid rgba(155, 169, 230, 0.2);
    right: 26px;
    width: 120px;
    background: #22273a;
    z-index: 10;
    ${optionTextCss}
  }
  .select__menu-list {
    padding: 0;
  }
  .select__option {
    cursor: pointer;
    transition: background 0.2s;
  }
  --idleBgColor: rgba(255, 255, 255, 0.1);
  --selectedBgColor: rgba(59, 104, 255, 1);
  .select__option--is-selected {
    background: var(--selectedBgColor);
  }
  .select__option--is-focused:not(.select__option--is-selected) {
    background: color-mix(in hsl, var(--selectedBgColor), var(--idleBgColor));
  }
`;
