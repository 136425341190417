import { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import twitterPng from '@/assets/feedback/socials/Twitter.png';
import whatsappPng from '@/assets/feedback/socials/Whatsapp.png';
import facebookPng from '@/assets/feedback/socials/Facebook.png';
import mailPng from '@/assets/feedback/socials/Mail.png';
import { useFeedbackStore, FeedbackData } from './store/store';
import { sendFeedback } from './sendFeedback';
import useClipboard from 'react-use-clipboard';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@/components/button/Button';
import { useEffectUntil } from '@/lib/hooks/useEffectUntil';
import { useStore } from '@/store/store';
import { P } from '@/components/text/P';

interface ShareProps {
  handleClose: () => void;
}

export const Share = ({ handleClose }: ShareProps): ReactNode => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const clipboardRef = useRef<HTMLParagraphElement>(null);
  const { answers, setGaveFeedback } = useFeedbackStore();
  const ticketId = useStore((s) => s.lastTicket?.id);
  const [isCopied, setIsCopied] = useClipboard('SHDWXXZB', { successDuration: 1000 });

  const mutation = useMutation({
    mutationFn: sendFeedback,
    onSuccess: () => {
      // eslint-disable-next-line no-console
      console.log('Feedback sent successfully:');
      setGaveFeedback(true);
    },
    onError: (error: Error) => {
      console.error('Failed to send feedback:', error);
    },
  });

  useEffectUntil(
    (done) => {
      if (ticketId && answers.length > 0) {
        const data: FeedbackData = {
          ticket_uuid: ticketId,
          campaign_id: 'campaign_1',
          language: 'en',
          answers,
        };
        mutation.mutate(data);
        done();
      }
    },
    [answers, mutation, ticketId],
  );

  return (
    <>
      <ShareSection>
        <Explanation variant="sm">
          Thank you for your precious feedbacks.
          <br />
          You can share your experiences to the world.
        </Explanation>
        <Bottom>
          <Code>
            <P ref={textRef}>
              <P variant="sm">SHDWXXZB</P>
            </P>
            <Clipboard onClick={setIsCopied}>
              <P ref={clipboardRef} variant="sm">
                {isCopied ? 'Copied!' : 'Copy link'}
              </P>
            </Clipboard>
          </Code>
          <Socials>
            <a href="" title="twitter">
              <img src={twitterPng} alt="Twitter" />
            </a>
            <a href="">
              <img src={whatsappPng} alt="WhatsApp" />
            </a>
            <a href="">
              <img src={facebookPng} alt="Facebook" />
            </a>
            <a href="">
              <img src={mailPng} alt="Mail" />
            </a>
          </Socials>
        </Bottom>
      </ShareSection>
      <$Button onClick={handleClose}>DISMISS</$Button>
    </>
  );
};

const ShareSection = styled.div`
  text-align: center;
`;

const Bottom = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const Code = styled.div`
  width: 196px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #9ba9e620;
  border-radius: 4px;
  padding: 0 8px;
`;

const Explanation = styled(P)`
  text-align: center;
  opacity: 0.5;
`;

const Clipboard = styled.div`
  cursor: pointer;
`;

const Socials = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const $Button = styled(Button)`
  width: 90%;
`;
