import { ReactElement, useEffect } from 'react';
import { useAuth } from '@npm/react-oidc-context';
import styled from 'styled-components';
import { SignedInBadge } from './SignedInBadge';
import { SignedOutBadge } from './SignedOutBadge';

export const AuthPanel = (): ReactElement => {
  return (
    <Root>
      <Inner />
    </Root>
  );
};

const Inner = (): ReactElement | null => {
  const { isAuthenticated, error, removeUser } = useAuth();

  useEffect(() => {
    if (error) {
      console.error(error);
      removeUser();
    }
  }, [error, removeUser]);

  if (error) {
    return null;
  }
  if (isAuthenticated) {
    return <SignedInBadge />;
  }
  return <SignedOutBadge />;
};

const Root = styled.div`
  width: 15em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  @media screen and (max-width: 768px) {
    width: auto;
  }
`;
