import { z } from 'zod';

interface Env {
  isDev: boolean;
  isStoryBook: boolean;
  authUrl: string;
  catalogUrl: string;
  reservationUrl: string;
  timekeeperUrl: string;
  dataUrl: string;
  openidAuthority: string;
  openidClientId: string;
  shadowRootUrl: string;
}

const envZod = z.object({
  DEV: z.boolean(),
  STORYBOOK: z
    .string()
    .optional()
    .transform((str) => str === 'true'),
  VITE_AUTH_URL: z.string().min(1),
  VITE_CATALOG_URL: z.string().min(1),
  VITE_RESERVATION_URL: z.string().min(1),
  VITE_TIMEKEEPER_URL: z.string().min(1),
  VITE_DATA_URL: z.string().min(1),
  VITE_OPENID_AUTHORITY: z.string().min(1),
  VITE_OPENID_CLIENT_ID: z.string().min(1),
  VITE_SHADOW_ROOT_URL: z.string().min(1),
});

const validate = (env: unknown): Env => {
  const res = envZod.safeParse(env);
  if (res.error) {
    throw new InvalidEnvError(res.error);
  }
  return {
    isDev: res.data.DEV,
    isStoryBook: res.data.STORYBOOK,
    authUrl: res.data.VITE_AUTH_URL,
    catalogUrl: res.data.VITE_CATALOG_URL,
    reservationUrl: res.data.VITE_RESERVATION_URL,
    timekeeperUrl: res.data.VITE_TIMEKEEPER_URL,
    dataUrl: res.data.VITE_DATA_URL,
    openidAuthority: res.data.VITE_OPENID_AUTHORITY,
    openidClientId: res.data.VITE_OPENID_CLIENT_ID,
    shadowRootUrl: res.data.VITE_SHADOW_ROOT_URL,
  };
};

class InvalidEnvError extends Error {
  constructor(error: z.ZodError) {
    const prettyIssue = (issue: z.ZodIssue) => `\t${issue.path}: ${issue.message}`;
    super(`Invalid environment:\n${error.issues.map(prettyIssue).join('\n')}`);
    this.name = InvalidEnvError.name;
  }
}

export const env: Env = validate(import.meta.env);
