import { OidcClientSettings, User, WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { AuthProvider as AuthProvider, useAuth } from '@npm/react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { homePagePath } from '@/routes/routePaths';
import { z } from 'zod';
import { useEffectUntil } from '@/lib/hooks/useEffectUntil';
import { IamToken } from './types';
import { env } from '@/env';

export const oauthRedirectUriPath = '/signin-shadow-callback';

export const authSettings: OidcClientSettings = {
  authority: env.openidAuthority,
  client_id: env.openidClientId,
  redirect_uri: `${window.location.origin}${oauthRedirectUriPath}`,
  response_type: 'code',
  scope: 'openid email geny-f2p profile',
};

const userStore = new WebStorageStateStore({ store: localStorage });

const getStoredShadowUser = async (): Promise<User | undefined> => {
  // https://github.com/authts/react-oidc-context#call-a-protected-api
  const payload = await userStore.get(`user:${authSettings.authority}:${authSettings.client_id}`);
  return payload ? User.fromStorageString(payload) : undefined;
};

export const getValidStoredShadowIamToken = async (): Promise<IamToken | undefined> => {
  const user = await getStoredShadowUser();
  if (!user) {
    // console.log('No shadow user found in storage');
    return undefined; // No shadow user logged in
  }
  if (!user.expires_at) {
    console.warn('User IAM payload does not provide a expires_at field.');
    return undefined;
  }
  const nowSec = Date.now() / 1000;
  const maxAllowedExpirationTs = nowSec + 10; // For safety we add a 10 sec margin, so we dont use it even if its still valid for 10sec.
  if (user.expires_at > maxAllowedExpirationTs) {
    // console.log('Shadow user has a valid access token!');
    return user.access_token; // User has a valid access token
  }
  // User's access token is expired
  // Currently the IAM does not provide a refresh token, so we cannot automatically relogin.
  console.warn(`Shadow user's token is expired. Returning undefined`);
  return undefined;
};

export const ShadowAuthProvider = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <AuthProvider {...authSettings} userStore={userStore}>
      <AutoReAuthMiddleware>{children}</AutoReAuthMiddleware>
    </AuthProvider>
  );
};

// Temporary empty page, displayed while we wait for useAuth() to give
// us the path to redirect to.
export const RedirectToLoginSucessfulPath = (): null => {
  const navigate = useNavigate();
  const auth = useAuth();
  const state = auth.user?.state;
  useEffectUntil(
    (done) => {
      if (!state || !auth.isAuthenticated) {
        return; // State is not available yet. It may come later.
      }
      if (window.location.pathname === oauthRedirectUriPath) {
        done();
        const pathOnSuccessfulLogin = isPostLoginState(state) && state.pathOnSuccessfulLogin;
        if (pathOnSuccessfulLogin) {
          navigate(pathOnSuccessfulLogin);
        } else {
          console.warn('Got in RedirectToLoginSucessfulPath but state is invalid');
          navigate(homePagePath);
        }
      }
    },
    [state, navigate, auth],
  );
  return null;
};

const AutoReAuthMiddleware = ({ children }: PropsWithChildren) => {
  const { isLoading } = useAuth();
  const isFirstLoadingRef = useRef(true);
  useReauthIfTokenExpired();
  if (isLoading) {
    if (isFirstLoadingRef.current) {
      // console.log('ShadowAuthProvider middleware: first loading so we return null');
      return null;
    }
    // else, it is loading but its not the first time, so we keep displaying children.
  } else {
    isFirstLoadingRef.current = false;
  }
  // console.log('ShadowAuthProvider middleware return children');
  return children;
};

const useReauthIfTokenExpired = (): void => {
  const { events, signinSilent } = useAuth();
  useEffect(() => {
    const handleTokenExpiring = async () => {
      try {
        // eslint-disable-next-line no-console
        console.log('Attempting signinSilent...');
        await signinSilent();
        // eslint-disable-next-line no-console
        console.log('Succeeded signinSilent!');
      } catch (error) {
        console.error('Failed signinSilent:', error);
        throw error;
      }
    };
    events.addAccessTokenExpiring(handleTokenExpiring);
    return () => {
      events.removeAccessTokenExpiring(handleTokenExpiring);
    };
  }, [events, signinSilent]);
};

const postLoginState = z.object({ pathOnSuccessfulLogin: z.string() });

export type PostLoginState = z.infer<typeof postLoginState>;

const isPostLoginState = (state: unknown): state is PostLoginState => {
  return postLoginState.safeParse(state).success;
};

// To be tried once the shadow server enables refresh tokens
export const DEBUG_RaiseAccessTokenExpiringEventButton = (): ReactNode => {
  const auth = useAuth();
  const handleClick = () => {
    // eslint-disable-next-line no-console
    // console.log('---Raised addAccessTokenExpiring event---');
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    (auth.events as any)._expiringTimer.raise();
  };
  return <button onClick={handleClick}>Raise addAccessTokenExpiring event</button>;
};
