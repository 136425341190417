import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import {
  AppSessionStorePersistedState,
  appSessionStorePersistOptions,
  AppSessionStoreSlice,
} from './appSessionStoreSlice';
import { MainStorePersistedState, mainStorePersistOptions, MainStoreSlice } from './mainStoreSlice';
import { create } from 'zustand';
import { flow } from 'lodash';

export type CombinedStoreSlices = MainStoreSlice & AppSessionStoreSlice;
export type CombinedPersistedSlices = MainStorePersistedState & AppSessionStorePersistedState;

export const useStore = create<CombinedStoreSlices>()(
  persist<CombinedStoreSlices, [], [], CombinedPersistedSlices>(
    (...args) => ({
      ...mainStorePersistOptions.create(...args),
      ...appSessionStorePersistOptions.create(...args),
    }),
    {
      name: 'socis-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state: CombinedStoreSlices) => ({
        ...mainStorePersistOptions.partialize(state),
        ...appSessionStorePersistOptions.partialize(state),
      }),
      merge: (
        unsafePersistedState: unknown,
        currentState: CombinedStoreSlices,
      ): CombinedStoreSlices =>
        flow([
          (s) => mainStorePersistOptions.merge(unsafePersistedState, s),
          (s) => appSessionStorePersistOptions.merge(unsafePersistedState, s),
        ])(currentState),
    } satisfies PersistOptions<CombinedStoreSlices, MainStorePersistedState>,
  ),
);
