import { P } from '@/components/text/P';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { ReactNode } from 'react';
import styled from 'styled-components';
import chevronRightWhite from '@/assets/chevron-right-white.svg';
import { AppMetadata } from '@/api/entities/app';
import { getAppRunPageUrl } from '@/routes/routePaths';
import { LinkButton } from '@/components/button/Button';

export const QuickAccess = (): ReactNode => {
  const { catalog } = useLoadedCatalog();
  return (
    <$QuickAccess>
      <P variant="xl">QUICK ACCESS</P>
      <List>
        {catalog.map((app, index) => (
          <AppThumbnail key={index} app={app} />
        ))}
      </List>
    </$QuickAccess>
  );
};

// TODO Should use the official AppThumbnail component
const AppThumbnail = ({ app }: { app: AppMetadata }): ReactNode => {
  return (
    <Game>
      <Img src={app.icon} alt="app icon" />
      <GameInfo>
        <TextContainer>
          <Title>{app.name}</Title>
          <Editor>{app.editorName}</Editor>
        </TextContainer>
        <$LinkButton to={getAppRunPageUrl(app.nameSlug)}>
          Play <img src={chevronRightWhite} alt="chevron right" />
        </$LinkButton>
      </GameInfo>
    </Game>
  );
};

const List = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  gap: 30px;
  width: 100%;
  height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const $QuickAccess = styled.div`
  background: #101422;
  min-height: 525px;
  width: 100vw;
  pointer-events: all;
  padding: 40px 0;
  padding-left: 40px;
`;

const Game = styled.div`
  display: flex;
  gap: 16px;
  height: 100px;
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: 'Nexa Text';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
`;

const Editor = styled.p`
  font-family: 'Nexa Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #87a0f9;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const $LinkButton = styled(LinkButton)`
  width: 130px;
  font-family: 'Nexa Text';
  img {
    margin-left: 8px;
  }
`;
