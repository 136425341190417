import { ReactNode } from 'react';
import gsap from 'gsap';
import { AppDisplay } from '../home/AppDisplay';
import {
  OutletTransitionContext,
  useSwitchTransitionOutletContext,
} from '@/components/animation/SwitchTransitionOutlet';
import { useDefinedAppFromPath } from './utils';

export const AppRootPage = (): ReactNode => {
  const app = useDefinedAppFromPath();
  const outletMetadata = useSwitchTransitionOutletContext(transitions);
  return <AppDisplay ref={outletMetadata.ref} app={app} />;
};

const transitions: OutletTransitionContext = {
  onEnter: (node, onComplete) => {
    gsap.from(node, {
      opacity: 0,
      y: 10,
      duration: 0.3,
      onComplete,
    });
  },
  onExit: (node, onComplete) => {
    gsap.to(node, {
      opacity: 0,
      duration: 0.3,
      onComplete,
    });
  },
};
