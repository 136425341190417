import { z } from 'zod';
import { FeedbackData } from './store/store';
import { env } from '@/env';

export type Answer = {
  answerType: string;
  questionId: string;
  questionValue: string;
  answer: string | number | boolean | undefined;
};

const AnswerSchema = z.object({
  answerType: z.string().min(1),
  questionId: z.string().min(1),
  questionValue: z.string().min(1),
  answer: z.union([z.string(), z.number(), z.boolean(), z.undefined()]).optional(),
});

const FeedbackDataSchema = z.object({
  ticket_uuid: z.string().min(1),
  campaign_id: z.string().min(1),
  language: z.string().min(1),
  answers: z.array(AnswerSchema),
});

const sendFeedbackResponseZod = FeedbackDataSchema;

const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

// TODO Fix type issues so we dont need to inhibit eslint
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertKeysToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeCaseKey = camelToSnakeCase(key);
      acc[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);
  }
  return obj;
};

export const sendFeedback = async (data: FeedbackData): Promise<void> => {
  const parsedData = FeedbackDataSchema.safeParse(data);
  if (!parsedData.success) {
    throw new Error(`Validation failed: ${parsedData.error.message}`);
  }

  const snakeCaseData = {
    ...data,
    answers: convertKeysToSnakeCase(data.answers),
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakeCaseData),
    redirect: 'follow' as RequestRedirect,
  };

  const response = await fetch(`${env.dataUrl}v1/feedback/`, requestOptions);
  if (response.status >= 400) {
    throw new Error(`HTTP Error: ${response.status}`);
  }

  const result = await response.json();

  const parsedResult = sendFeedbackResponseZod.safeParse(result);
  if (!parsedResult.success) {
    throw new Error(`Response validation failed: ${parsedResult.error.message}`);
  }
};
