import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import clockAroundSvg from '@/assets/clock-around.svg';
import { P } from '../../text/P';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { secToMin } from '@/lib/functions/secToMin';

type ClockDigitsProps = {
  timeRemainingSec: number;
};

export const ClockDigits = ({ timeRemainingSec }: ClockDigitsProps): ReactNode => {
  const counterRef = useRef<HTMLParagraphElement>(null);
  const timeRemainingInMinutes = secToMin(timeRemainingSec, { floor: true });

  useGSAP(() => {
    if (!timeRemainingInMinutes) return;
    if (counterRef.current) {
      gsap.from(counterRef.current, {
        y: -5,
        opacity: 0,
        duration: 0.3,
      });
    }
  }, [timeRemainingInMinutes]);

  return (
    <ImgContainer>
      <AbsoluteText ref={counterRef}>{timeRemainingInMinutes ?? 0}</AbsoluteText>
      <Img src={clockAroundSvg} alt="" />
    </ImgContainer>
  );
};

const Img = styled.img`
  margin: 0 0 2px 0;
  scale: 1.2;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsoluteText = styled(P)`
  position: absolute;
  font-size: 10px;
  font-weight: 300;
`;
