import { useEffect } from 'react';
import { useWindowEventListener } from './hooks/eventListeners/useWindowEventListener';
interface Options {
  triggerNow?: boolean;
}

export const useResize = (handleResize: () => void, options?: Options): void => {
  const triggerNow = Boolean(options?.triggerNow);
  useWindowEventListener('resize', handleResize);
  useEffect(() => {
    if (triggerNow) {
      handleResize();
    }
  }, [handleResize, triggerNow]);
};
