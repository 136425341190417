import { useStore } from '@/store/store';
import { useEffect, useState, ReactNode, useCallback } from 'react';
import fullscreenPng from '@/assets/toolbar/fullscreen.png';
import volumeUpPng from '@/assets/toolbar/volume-up.png';
import volumeOffPng from '@/assets/toolbar/volume-off.png';
import turnOffPng from '@/assets/toolbar/turn_off.png';
import unionPng from '@/assets/toolbar/union.png';
import { userAgentNeedsFalseFullscreen } from '@/lib/userAgentNeedsFalseFullscreen';
import { P } from '@/components/text/P';
import styled, { css } from 'styled-components';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { useToggle } from '@uidotdev/usehooks';
import { useIsTabFocused } from '../useIsTabFocused';
import { useDocumentEventListener } from '@/lib/hooks/eventListeners/useDocumentEventListener';
import { layoutDefinition } from '@/pages/layoutDefinition';

export const Sidebar = (): ReactNode => {
  const isMobile = useIsMobile();
  const [showToolbar, toggleShowToolbar] = useToggle(true);
  return (
    <Root $showBackground={showToolbar}>
      <ToolbarContainer>{showToolbar && <Toolbar />}</ToolbarContainer>
      {!isMobile && (
        <HideButton $rotated={!showToolbar} onClick={() => toggleShowToolbar()}>
          <img src={unionPng} />
        </HideButton>
      )}
    </Root>
  );
};

const Toolbar = (): ReactNode => {
  return (
    <$Toolbar>
      <FullscreenButton />
      <ToggleSoundButton />
      <ShutDownButton />
    </$Toolbar>
  );
};

const FullscreenButton = () => {
  const { setFullScreen } = useStore();
  const isMobile = useIsMobile();
  const goFullScreen = () => {
    if (!userAgentNeedsFalseFullscreen) {
      const player = document.querySelector('#genymotion');
      player?.requestFullscreen();
      const gmWrapper = document.querySelector('#genymotion .gm-wrapper');
      gmWrapper?.classList.add('gm-fullscreen');
    }
    setFullScreen(true);
  };
  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setFullScreen(false);
    }
  };
  useDocumentEventListener('fullscreenchange', handleFullScreenChange);
  return (
    <ButtonLi onClick={goFullScreen}>
      <img src={fullscreenPng} />
      {isMobile && <P variant="sm">Fullscreen</P>}
    </ButtonLi>
  );
};

const ToggleSoundButton = () => {
  const isMobile = useIsMobile();
  const deviceRendererApi = useStore((s) => s.deviceRendererApi);
  const [isSoundEnable, setSoundEnable] = useState(true);
  useMuteOnMobileTabBlur(isSoundEnable);
  const toggleSound = useCallback(() => {
    setSoundEnable((enable) => {
      if (!deviceRendererApi) {
        return enable; // No effect
      }
      if (enable) {
        deviceRendererApi.media.mute();
        return false;
      }
      deviceRendererApi.media.unmute();
      return true;
    });
  }, [deviceRendererApi]);

  return (
    <ButtonLi onClick={toggleSound}>
      <img src={isSoundEnable ? volumeUpPng : volumeOffPng} />
      {isMobile && <P variant="sm">{isSoundEnable ? 'Sound on' : 'Sound off'}</P>}
    </ButtonLi>
  );
};

const useMuteOnMobileTabBlur = (isSoundEnable: boolean) => {
  const isMobile = useIsMobile();
  const isTabFocused = useIsTabFocused();
  const deviceRendererApi = useStore((s) => s.deviceRendererApi);
  useEffect(() => {
    if (!isMobile) return;
    if (!isTabFocused) {
      deviceRendererApi?.media.mute();
    } else {
      if (isSoundEnable) {
        deviceRendererApi?.media.unmute();
      }
    }
  }, [isTabFocused, isMobile, deviceRendererApi, isSoundEnable]);
};

const ShutDownButton = () => {
  const isMobile = useIsMobile();
  const { setShowShutDownConfirmationModal } = useStore();
  return (
    <ButtonLi onClick={() => setShowShutDownConfirmationModal(true)}>
      <img src={turnOffPng} />
      {isMobile && <P variant="sm">End session</P>}
    </ButtonLi>
  );
};

const Root = styled.div<{ $showBackground: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 58px;
  pointer-events: all;
  height: 100%;
  position: relative;
  transition: all 0.2s ease 0s;
  &:before {
    // adds a background to the root div layout
    content: '';
    position: absolute;
    background: #87a0f910;
    backdrop-filter: blur(20px) saturate(180%);
    width: 0px;
    height: 100dvh;
    top: -64px;
    z-index: -1;
    transition: all 0.2s ease 0s;
    justify-self: flex-end;
    align-self: flex-end;
    ${(p) =>
      p.$showBackground &&
      css`
        width: 58px;
      `};
  }
  @media screen and (max-width: 768px) {
    height: ${layoutDefinition.sidebarHeight};
    width: 100%;
    flex-direction: row;
    margin-top: 0;
    background: #87a0f910;
    backdrop-filter: blur(20px) saturate(180%);
    &:before {
      width: 0;
      height: 0;
    }
  }
`;

const ToolbarContainer = styled.div`
  width: 100%;
`;

const $Toolbar = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ButtonLi = styled.button`
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  pointer-events: all;
  &:hover {
    background: #87a0f980;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-grow: 1;
    color: white;
    &:hover {
      background: none;
    }
  }
`;

const HideButton = styled(ButtonLi)<{ $rotated?: boolean }>`
  border-radius: 50%;
  ${(p) =>
    p.$rotated &&
    css`
      transform: rotate(180deg);
    `}
`;
