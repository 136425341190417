import { useRef } from 'react';

/**
 * return a singleton value for each key
 */
export const useSingletonInMap = <T>(key: string, createIfNotExist: () => T): T => {
  const map = useRef(new Map<string, T>()).current;
  const existingRef = map.get(key);
  if (existingRef) {
    return existingRef;
  }
  const newRef = createIfNotExist();
  map.set(key, newRef);
  return newRef;
};
