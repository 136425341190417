import { ReactNode } from 'react';
import styled from 'styled-components';

interface UserAvatarImgProps {
  src?: string;
  className?: string;
}

export const UserAvatarImg = ({ src, className }: UserAvatarImgProps): ReactNode => {
  if (src) {
    return <Img src={src} className={className} />;
  }
  return <Placeholder className={className} />;
};

const Img = styled.img`
  border-radius: 50%;
`;

const Placeholder = ({ className }: { className?: string }) => {
  return (
    <UserIconPlaceHolderSvg viewBox="0 -1 37 38" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55887 36 0.5 27.9411 0.5 18C0.5 8.05887 8.55887 0 18.5 0C28.4411 0 36.5 8.05887 36.5 18ZM23.8684 12.6316C23.8684 15.5965 21.4649 18 18.5 18C15.5351 18 13.1316 15.5965 13.1316 12.6316C13.1316 9.66671 15.5351 7.26318 18.5 7.26318C21.4649 7.26318 23.8684 9.66671 23.8684 12.6316ZM14.0263 20.6842C11.8026 20.6842 10 22.4869 10 24.7106C10 26.9342 11.8026 28.7369 14.0263 28.7369H22.9737C25.1974 28.7369 27 26.9342 27 24.7106C27 22.4869 25.1974 20.6842 22.9737 20.6842H14.0263Z"
        fill="#3653cc"
        stroke="#ffffff"
      />
    </UserIconPlaceHolderSvg>
  );
};

export const UserIconPlaceHolderSvg = styled.svg`
  path {
    fill-opacity: 0;
    transition: fill-opacity 0.5s ease-in-out;
    stroke-opacity: 1;
    transition: stroke-opacity 0.5s ease-in-out;
  }
`;
