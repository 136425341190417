import { AppMetadata } from '../entities/app';
import { queryClient } from '../queryClient';
import { TagSlug } from '../entities/tags';
import { searchApp as doSearchApp } from '../queries/searchApp';
import { AuthTokenSet } from '../queries/utils';

interface AppSearchFilter {
  queryText?: string;
  tags?: TagSlug[];
}

const queryKeys = {
  getQueryKey: (auth: AuthTokenSet, filter?: AppSearchFilter) => [auth, 'searchApp', filter],
};

export const searchApp = async (
  auth: AuthTokenSet,
  filter?: AppSearchFilter,
): Promise<AppMetadata[]> => {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.getQueryKey(auth, filter),
    queryFn: () => doSearchApp(auth, filter),
  });
};
