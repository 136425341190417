import { ReactNode, useEffect, useState } from 'react';
import { useFeedbackStore } from './store/store';
import { useStore } from '@/store/store';
import { FeedbackModal } from './FeedbackModal';

export const FeedbackRoot = (): ReactNode => {
  const [showFeedback, setShowFeedback] = useState(false);
  const ticketId = useStore((s) => s.lastTicket?.id);
  const { gaveFeedback } = useFeedbackStore();

  useEffect(() => {
    if (ticketId && !gaveFeedback) {
      setShowFeedback(true);
    }
  }, [ticketId, gaveFeedback]);

  return <FeedbackModal show={showFeedback} onRequestClose={() => setShowFeedback(false)} />;
};
