import { HydratedApp } from '@/api/entities/hydrated-app';
import styled from 'styled-components';
import { AppThumbnail } from '../home/AppThumbnail';
import { ReactNode } from 'react';

export const ResultThumbnailList = ({ apps }: { apps: HydratedApp[] }): ReactNode => {
  return (
    <$ResultThumbnailList>
      {apps.map((app) => (
        <AppThumbnail key={app.imageId} app={app} />
      ))}
    </$ResultThumbnailList>
  );
};

const $ResultThumbnailList = styled.div`
  width: 100%;
  padding: 20px 10px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 140px);
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
