import { fetchSocisJwt as doFetchSocisJwt } from '../queries/socisJwt';
import { queryClient } from '../queryClient';
import { AuthTokenSet } from '../queries/utils';
import { SocisJwt } from '../entities/socis-jwt';

const queryKeys = {
  fetchSocisJwt: (auth: AuthTokenSet) => [auth, 'socisJwt'],
};

export const fetchSocisJwt = async (auth: AuthTokenSet): Promise<SocisJwt> => {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.fetchSocisJwt(auth),
    queryFn: () => doFetchSocisJwt(auth),
  });
};
