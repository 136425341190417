import { Button } from '@/components/button/Button';
import { forwardRef, PropsWithChildren, ReactNode, useImperativeHandle, useRef } from 'react';
import { P } from '@/components/text/P';
import { createModal } from '@/components/modal/hoc';
import styled from 'styled-components';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import videoShadowMp4 from '@/assets/video-shadow.mp4';
import clockHomePng from '@/assets/clock-home.png';

type Props = {
  onRequestClose?: (mustShutdown?: boolean) => void;
  className?: string;
};

export const TutorialModalWindow = forwardRef(
  ({ onRequestClose }: Props, forwardedRef): ReactNode => {
    const rootRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    useImperativeHandle(forwardedRef, () => rootRef.current, []);
    return (
      <Root ref={rootRef}>
        <TutorialBanner>
          <LeftSide>
            <TitleBar>
              {!isMobile && <ClockIcon />}
              <P>Play for free to your favorite game during 30 minutes</P>
            </TitleBar>
            <List>
              <ListItem index={1}>Select the game you want to play</ListItem>
              <ListItem index={2}>Wait in line for a gaming session to become available</ListItem>
              <ListItem index={3}>
                You can play different games during 30 consecutive minutes.
              </ListItem>
            </List>
          </LeftSide>
          <RightSide>
            <video src={videoShadowMp4} autoPlay loop muted controls playsInline />
          </RightSide>
        </TutorialBanner>
        <Button onClick={onRequestClose}>Enter</Button>
      </Root>
    );
  },
);

const ListItem = ({ index, children }: PropsWithChildren<{ index: number }>) => {
  return (
    <$ListItem>
      <ListBulletIcon>{index}</ListBulletIcon>
      <ListText>{children}</ListText>
    </$ListItem>
  );
};

const Root = styled.div`
  position: relative;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TutorialBanner = styled.div`
  max-width: 1240px;
  width: 90%;
  background: #c8d7ff20;
  backdrop-filter: blur(20px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 4px 40px #3653cc70);
  margin-bottom: 36px;
  @media screen and (max-width: 768px) {
    width: 90%;
    flex-direction: column-reverse;
    justify-self: flex-start !important;
    filter: none;
    padding-bottom: 36px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  padding: 20px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin: 0;

    ul {
      display: flex;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const RightSide = styled.div`
  width: 53%;
  align-self: stretch;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin-left: -7%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: 0 8px 8px 0;
  }

  @media screen and (max-width: 768px) {
    clip-path: none;
    width: 100%;
    margin: 0;
    video {
      border-radius: 8px 8px 0 0;
      width: 100%;
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  ${P} {
    font-size: 28px;
    line-height: 32px;
    font-weight: 300;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
`;

const ClockIcon = styled.img.attrs({ src: clockHomePng, alt: 'a clock icon' })``;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 24px;
`;

const $ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 13px;
`;

const ListBulletIcon = styled(P)`
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-size: 24px;
  font-weight: 300;
  color: #3653cc;
`;

const ListText = styled(P).attrs({ variant: 'md' })`
  font-weight: 300;
  width: 60%;
`;

export const TutorialModal = createModal(TutorialModalWindow);
