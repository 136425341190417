import { z } from 'zod';

export type TagSlug = string;

export type TagMetadata = {
  name: string;
  slug: TagSlug;
};

export const tagZod = z.object({
  name: z.string().min(1),
  slug: z.string().min(1),
});
