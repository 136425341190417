import { ReactNode } from 'react';
import infoCircleSvg from '@/assets/info-circle.svg';
import styled from 'styled-components';
import { AuthPanel } from '../Auth/AuthPanel';
import { Clock } from './clock/Clock';
import { useToggle } from '@uidotdev/usehooks';
import { HamburgerMenu } from './HamburgerMenu';
import { MenuButton } from './MenuButton';
import { SearchBar } from './SearchBar/SearchBar';
import { homePagePath } from '@/routes/routePaths';
import { Link, useMatch } from 'react-router-dom';
import { useStore } from '@/store/store';
import { layoutDefinition } from '@/pages/layoutDefinition';

export const Menu = (): ReactNode => {
  const [showMenu, toggleShowMenu] = useToggle(false);
  return (
    <Header>
      <MenuList>
        <MenuButton onClick={toggleShowMenu} show={showMenu} />
        <HamburgerMenu show={showMenu} />
        <li>
          <BetaContainer>
            <Link to={homePagePath}>
              <BetaSpan>FREE TO PLAY</BetaSpan>
            </Link>
          </BetaContainer>
        </li>
        <ShowTutorialButton />
      </MenuList>
      <SearchBar />
      <AuthPanel />
      <Clock />
    </Header>
  );
};

const ShowTutorialButton = (): ReactNode => {
  const isHomePage = Boolean(useMatch(homePagePath));
  const setHideTutorial = useStore((s) => s.setHideTutorial);
  if (!isHomePage) {
    return null;
  }
  return <InfoCircleImg src={infoCircleSvg} onClick={() => setHideTutorial(false)} />;
};

const InfoCircleImg = styled.img`
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease-out 0s;
  &:hover {
    opacity: 1;
  }
`;

const Header = styled.header`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: ${layoutDefinition.headerHeight};
  padding: 0 50px;
  pointer-events: none;
  justify-content: space-between;
`;

export const MenuList = styled.ul`
  display: flex;
  align-items: center;
  color: white;
  list-style: none;
  gap: 30px;
  font-family: 'Nexa Text';
`;

export const BetaContainer = styled.div`
  background: white;
  height: 24px;
  padding: 0 8px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
export const BetaSpan = styled.span`
  background-image: linear-gradient(to right, #3b68ff 0%, #9747ff 100%);
  font-weight: 600;
  letter-spacing: 1px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Nexa Text';
`;
