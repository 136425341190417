import { HydratedApp } from '@/api/entities/hydrated-app';
import { sortBy } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { useLoadedSearchResults } from './searchLoader';
import {
  useSearchResultDisplayOptions,
  SearchResultDisplayComponent,
  SearchResultDisplaySortKind,
} from './searchOptions';
import { ResultCardList } from './ResultCardList';
import { ResultThumbnailList } from './ResultThumbnailList';

export const ResultList = (): ReactNode => {
  const searchResults = useLoadedSearchResults();
  const { sortKind, component } = useSearchResultDisplayOptions();
  const sortedApps = useMemo(() => sortApps(searchResults, sortKind), [searchResults, sortKind]);
  switch (component) {
    case SearchResultDisplayComponent.Card:
      return <ResultCardList apps={sortedApps} />;
    case SearchResultDisplayComponent.Thumbnail:
      return <ResultThumbnailList apps={sortedApps} />;
  }
};

const sortApps = (apps: HydratedApp[], sortKind: SearchResultDisplaySortKind): HydratedApp[] => {
  switch (sortKind) {
    case SearchResultDisplaySortKind.AtoZ:
      return sortBy(apps, (app) => app.name);
    case SearchResultDisplaySortKind.ZtoA:
      return sortBy(apps, (app) => app.name).reverse();
    case SearchResultDisplaySortKind.MostPopular:
      return apps; // TODO
    case SearchResultDisplaySortKind.MostRecent:
      return sortBy(apps, (app) => app.createdAt).reverse();
  }
};
