import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { AppCard } from './AppCard';
import styled from 'styled-components';
import { ReactNode } from 'react';

export const AppCardsInScroll = (): ReactNode => {
  const { catalog } = useLoadedCatalog();
  return (
    <$Cards>
      {catalog.map((app) => (
        <CardWrapper key={app.nameSlug}>
          <$AppCard app={app} />
        </CardWrapper>
      ))}
    </$Cards>
  );
};

const $AppCard = styled(AppCard)`
  @media screen and (max-width: 768px) {
    min-width: unset;
    width: calc(100% - 10px);
  }
`;

const $Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  pointer-events: all;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
  margin-top: 140px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  scroll-snap-align: start;
  padding: 0 0 0 50px;
  height: 300px;
  @media screen and (max-width: 768px) {
    padding: 0;
    flex-shrink: 0;
    width: 100vw;
    scroll-snap-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
