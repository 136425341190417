import styled, { css } from 'styled-components';
import { authBadgeBorderRadiusPx, authBadgeHeightPx, authBadgeWidthPx, Badge } from './Badge';
import { ReactNode } from 'react';
import { useAuth } from '@npm/react-oidc-context';
import { useLocation } from 'react-router-dom';
import { PostLoginState } from './ShadowAuthProvider';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import authPlaceholder from '@/assets/auth-placeholder.png';

export const SignedOutBadge = (): ReactNode => {
  const auth = useAuth();
  const location = useLocation();
  const handleClick = async () => {
    auth.signinRedirect({
      state: { pathOnSuccessfulLogin: location.pathname } satisfies PostLoginState,
    });
  };
  const isLoading = auth.isLoading && !auth.error;
  const disableButton = isLoading;
  const isMobile = useIsMobile();
  if (isMobile) {
    return <img src={authPlaceholder} onClick={handleClick} alt="Sign in" />;
  }
  return (
    <Border $isLoading={isLoading}>
      <$Badge
        onClick={handleClick}
        disabled={disableButton}
        $isLoading={isLoading}
        title="Sign in"
        subtitle="access full features"
      />
    </Border>
  );
};

const $Badge = styled(Badge)<{ $isLoading: boolean }>`
  position: relative;
  ${(props) =>
    props.$isLoading &&
    css`
      background: #394b7e !important;
    `}
`;

const borderWidthPx = 4;

const Border = styled.div<{ $isLoading: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${authBadgeBorderRadiusPx + 1}px;
  width: ${authBadgeWidthPx + borderWidthPx * 2}px;
  height: ${authBadgeHeightPx + borderWidthPx * 2}px;
  ${(props) =>
    props.$isLoading &&
    css`
      @keyframes rotate {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.75) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        height: 300px;
        width: 100px;
        transform: translate(0);
        position: absolute;
        transform-origin: center center;
        animation: rotate 2s linear forwards infinite;
        z-index: 0;
      }
    `}
`;
