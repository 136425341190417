import { getValidStoredShadowIamToken } from '@/components/Auth/ShadowAuthProvider';
import { AuthTokenSet } from './queries/utils';
import { useAuth } from '@/lib/npm/react-oidc-context';
import { useStore } from '@/store/store';
import { SocisJwt } from './entities/socis-jwt';

// Used by loaders
export const getApiAuth = async (socisJwt?: SocisJwt): Promise<AuthTokenSet> => {
  const iamToken = await getValidStoredShadowIamToken();
  return { socisJwt, iamToken };
};

// Used by reactquery hooks
export const useApiAuth = (): AuthTokenSet => {
  const { user, isAuthenticated } = useAuth();
  const socisJwt = useStore((s) => s.socisJwt);
  return { socisJwt, iamToken: isAuthenticated ? user?.access_token : undefined };
};
