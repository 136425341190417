/* eslint-disable react/no-unknown-property */
import { useRef, ReactNode, forwardRef, useImperativeHandle } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import { Object3DNode } from '@react-three/fiber';
import { ShaderMaterial, DoubleSide, AdditiveBlending } from 'three';

const vertexShader = `
 varying vec3 vNormal;
      varying vec3 vPosition;
      varying vec3 vPattern;
      varying vec2 vUv;
      uniform float time;

      #define PI 3.14159265358979
      #define MOD3 vec3(.1031,.11369,.13787)

      vec3 hash33(vec3 p3) {
        p3 = fract(p3 * MOD3);
          p3 += dot(p3, p3.yxz+19.19);
          return -1.0 + 2.0 * fract(vec3((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y, (p3.y+p3.z)*p3.x));
      }
float pnoise(vec3 p) {
  vec3 pi = floor(p);
  vec3 pf = p - pi;
  vec3 w = pf * pf * (3. - 2.0 * pf);
  return 	mix(
          mix(
                mix(dot(pf - vec3(0, 0, 0), hash33(pi + vec3(0, 0, 0))),
                      dot(pf - vec3(1, 0, 0), hash33(pi + vec3(1, 0, 0))),
                       w.x),
                mix(dot(pf - vec3(0, 0, 1), hash33(pi + vec3(0, 0, 1))),
                      dot(pf - vec3(1, 0, 1), hash33(pi + vec3(1, 0, 1))),
                       w.x),
                w.z),
          mix(
                  mix(dot(pf - vec3(0, 1, 0), hash33(pi + vec3(0, 1, 0))),
                      dot(pf - vec3(1, 1, 0), hash33(pi + vec3(1, 1, 0))),
                       w.x),
                   mix(dot(pf - vec3(0, 1, 1), hash33(pi + vec3(0, 1, 1))),
                      dot(pf - vec3(1, 1, 1), hash33(pi + vec3(1, 1, 1))),
                       w.x),
                w.z),
        w.y);
}

    float random2D(vec2 value)
    {
        return fract(sin(dot(value.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }


      void main() {
          // Position
          vUv = uv;
          float noiseMultiplier = clamp((abs(vUv.x)), 0.f, 1.f);
          float noise = pnoise(vPosition * 5.0);
          vPattern = vec3(noiseMultiplier);

          float displacement = noise * noiseMultiplier;
          vec3 newPosition = vPosition + vNormal * displacement;
          vec4 modelPosition = vec4(position, 1.0);
          gl_Position = projectionMatrix * modelViewMatrix * modelPosition;

          vec4 modelNormal = modelMatrix * vec4(normal, 0.0);
          vPosition = modelPosition.xyz;
          vNormal = modelNormal.xyz;
      }`;

const fragmentShader = `
  varying vec3 vNormal;
      varying vec3 vPosition;
      varying vec3 vPattern;
      varying vec2 vUv;
      uniform float time;


      #define PI 3.14159265358979
      #define MOD3 vec3(.1031,.11369,.13787)

      vec3 hash33(vec3 p3) {
        p3 = fract(p3 * MOD3);
          p3 += dot(p3, p3.yxz+19.19);
          return -1.0 + 2.0 * fract(vec3((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y, (p3.y+p3.z)*p3.x));
      }
float pnoise(vec3 p) {
  vec3 pi = floor(p);
  vec3 pf = p - pi;
  vec3 w = pf * pf * (3. - 2.0 * pf);
  return 	mix(
          mix(
                mix(dot(pf - vec3(0, 0, 0), hash33(pi + vec3(0, 0, 0))),
                      dot(pf - vec3(1, 0, 0), hash33(pi + vec3(1, 0, 0))),
                       w.x),
                mix(dot(pf - vec3(0, 0, 1), hash33(pi + vec3(0, 0, 1))),
                      dot(pf - vec3(1, 0, 1), hash33(pi + vec3(1, 0, 1))),
                       w.x),
                w.z),
          mix(
                  mix(dot(pf - vec3(0, 1, 0), hash33(pi + vec3(0, 1, 0))),
                      dot(pf - vec3(1, 1, 0), hash33(pi + vec3(1, 1, 0))),
                       w.x),
                   mix(dot(pf - vec3(0, 1, 1), hash33(pi + vec3(0, 1, 1))),
                      dot(pf - vec3(1, 1, 1), hash33(pi + vec3(1, 1, 1))),
                       w.x),
                w.z),
        w.y);
}

    float random2D(vec2 value)
    {
        return fract(sin(dot(value.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }



      void main()
      {


        vec3 normal = normalize(vNormal);
        if(!gl_FrontFacing)
            normal *= - 1.0;
        vec3 viewDirection = normalize(cameraPosition - vPosition);
        float fresnel = dot(viewDirection, normal);
        fresnel = pow(fresnel, 1.f + 0.1);
        float falloff = smoothstep(0., 3., fresnel);
        float pattern = clamp((abs(vUv.x - 1.)- .2) * 3.f, 0.f, 1.f) * 1.f;
        vec3 blueColor = vec3(0., 0.8156, 1.0);
        blueColor = vec3(0.0, 0.9156, 1.0);
        float scalar = 3.0;
        vec3 multipliedColor = blueColor * scalar;
        float noise = clamp(pnoise(vNormal * 0.1 + time * 0.15 ), 0.f, 1.f);
        vec3 color = smoothstep(0.8, noise, vUv.y) * multipliedColor;
        gl_FragColor = vec4(color, noise * falloff);


      }`;

const GodRay = shaderMaterial({ time: 0 }, vertexShader, fragmentShader);

extend({ GodRay });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      godRay: Object3DNode<ShaderMaterial, typeof ShaderMaterial>;
    }
  }
}

const GodRayComponent = forwardRef<ShaderMaterial, {}>((props, forwardedRef) => {
  const localRef = useRef<ShaderMaterial>(null);

  useImperativeHandle(forwardedRef, () => localRef.current as ShaderMaterial);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    if (localRef.current) {
      localRef.current.uniforms.time.value = time;
    }
  });

  return (
    <godRay
      ref={localRef}
      attach="material"
      side={DoubleSide}
      transparent={true}
      blending={AdditiveBlending}
      depthTest={false}
    />
  );
});

export const MyShaderMaterial = (): ReactNode => {
  const godRayMaterialRef = useRef<ShaderMaterial>(null);

  return <GodRayComponent ref={godRayMaterialRef} />;
};
