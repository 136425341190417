import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useVideoBackgroundSrc } from '@/components/Background/background-hooks';
import { Button } from '@/components/button/Button';
import styled, { css } from 'styled-components';
import { H3 } from '@/components/text/H';
import { P } from '@/components/text/P';
import { getAppRunPageUrl } from '@/routes/routePaths';
import { timeoutAnticipationTimeSec } from '../app/run/timeout';
import { TagBadge } from '@/components/tag/Tag';
import { HydratedApp } from '@/api/entities/hydrated-app';

interface AppDisplayProps {
  app: HydratedApp;
}

export const appDisplaySidePadding = 180;

export const AppDisplay = forwardRef(
  ({ app }: AppDisplayProps, ref: ForwardedRef<HTMLDivElement>): ReactNode => {
    useVideoBackgroundSrc(app.video);
    const isGameTimeExpired = app.remainingTimeSec <= timeoutAnticipationTimeSec;
    return (
      <Root ref={ref}>
        <AppBanner>
          <AppImg $timeExpired={isGameTimeExpired} src={app.icon} alt={app.name} />
          <AppDetails>
            <H3>{app.name}</H3>
            <Row>
              <EditorLink to="">
                <P variant="md">{app.editorName}</P>
              </EditorLink>
              <$TagList>
                {app.tags.map((tag) => (
                  <TagBadge key={tag.slug} tag={tag} variant="small" />
                ))}
              </$TagList>
            </Row>
            <ButtonBar>
              <Link to={getAppRunPageUrl(app.nameSlug)}>
                <PlayButton disabled={isGameTimeExpired}>Play Now</PlayButton>
              </Link>
            </ButtonBar>
          </AppDetails>
        </AppBanner>
      </Root>
    );
  },
);

const Root = styled.div`
  opacity: 1;
  margin-left: ${appDisplaySidePadding}px;
  margin-top: 150px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 100vw;
    margin: 0;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const AppBanner = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 0;
    scale: 1 !important;
    padding-top: 50px;
  }
`;

const AppDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    h3 {
      font-size: 36px;
    }
  }
`;

export const EditorLink = styled(Link)`
  color: #87a0f9;
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const PlayButton = styled(Button)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      filter: grayscale(100%) !important;
    `}
`;

const AppImg = styled.img<{ $timeExpired?: boolean }>`
  width: 160px;
  height: 160px;
  border-radius: 16px;
  /* box-shadow: 0px 4px 20px 0px #3653CC66; */
  filter: drop-shadow(0px 4px 20px #3653cc66);

  ${(p) =>
    p.$timeExpired &&
    css`
      filter: grayscale(100%) !important;
    `}

  @media screen and (max-width: 768px) {
    width: 116px;
    height: 116px;
    margin: 0;
    padding: 0;
  }
`;

const $TagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;
