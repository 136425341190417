import { env } from '@/env';
import { z } from 'zod';
import { AuthTokenSet, createHeaders } from './utils';
import { SocisJwt, socisJwtZod } from '../entities/socis-jwt';

const fetchJwtResponseZod = z.object({
  token: socisJwtZod,
});

export const fetchSocisJwt = async (auth: AuthTokenSet): Promise<SocisJwt> => {
  const response = await fetch(`${env.authUrl}v1/jwt/`, {
    method: 'POST',
    headers: createHeaders(auth),
  });
  const data = fetchJwtResponseZod.parse(await response.json());
  const socisJwt = data.token.replace(/["']/g, '');
  return socisJwt;
};
