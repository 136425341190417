import styled, { css } from 'styled-components';
import { P } from '../text/P';
import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { UserAvatarImg, UserIconPlaceHolderSvg } from './AvatarImg';

export const authBadgeWidthPx = 180;
export const authBadgeHeightPx = 50;
export const authBadgeBorderRadiusPx = 10;

interface BadgeProps {
  title?: string;
  subtitle?: string;
  icon?: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
}

export const Badge = forwardRef(
  (
    { title, subtitle, icon, onClick, isActive, disabled, className }: BadgeProps,
    forwardedRef,
  ): ReactNode => {
    const rootRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => rootRef.current);
    return (
      <$Badge
        ref={rootRef}
        onClick={disabled ? undefined : onClick}
        $isActive={isActive}
        $disabled={disabled}
        className={className}
      >
        <UserAvatarImg src={icon} />
        <BadgeTextBox>
          {title && <BadgeTitle>{title}</BadgeTitle>}
          {subtitle && <BadgeSubtitle>{subtitle}</BadgeSubtitle>}
        </BadgeTextBox>
      </$Badge>
    );
  },
);

const textCss = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.5s ease-in-out;
`;

const BadgeTitle = styled(P).attrs({ variant: 'sm' })`
  ${textCss}
  font-weight: bold;
`;

const BadgeSubtitle = styled(P).attrs({ variant: 'xs' })`
  ${textCss}
`;

const BadgeTextBox = styled.div`
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  min-width: 0; // Force the compression of its content.
`;

const hoverEffect = css`
  background: rgba(255, 255, 255, 1);
  cursor: pointer;

  ${BadgeTitle}, ${BadgeSubtitle} {
    color: #3653cc;
  }
  ${UserIconPlaceHolderSvg} {
    path {
      fill-opacity: 1;
      stroke-opacity: 0;
    }
  }
`;

const $Badge = styled.div<{ $disabled?: boolean; $isActive?: boolean }>`
  background: rgba(255, 255, 255, 0.1);
  border-radius: ${authBadgeBorderRadiusPx}px;
  padding: 8px 10px;
  width: ${authBadgeWidthPx}px;
  height: ${authBadgeHeightPx}px;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  backdrop-filter: blur(5px);
  user-select: none;
  transition: background 0.5s;

  ${(props) =>
    !props.$disabled &&
    css`
      &:hover {
        ${hoverEffect}
      }
    `}

  ${(props) =>
    props.$isActive &&
    css`
      ${hoverEffect}
    `}
`;
