import { forwardRef, useRef, useCallback, useImperativeHandle, KeyboardEvent } from 'react';
import styled from 'styled-components';

interface AppSearchInputProps {
  value?: string;
  id?: string;
  onChange?: (text: string) => void;
  onSubmit?: (text: string) => void;
  onBlur?: (text: string) => void;
  className?: string;
}

export const AppSearchInput = forwardRef(
  ({ value, onChange, onBlur, id, onSubmit, className }: AppSearchInputProps, forwardedRef) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          onSubmit?.((event.target as HTMLInputElement).value);
        }
      },
      [onSubmit],
    );

    useImperativeHandle(forwardedRef, () => inputRef.current, []);
    return (
      <$AppSearchInput
        className={className}
        ref={inputRef}
        id={id}
        placeholder="Find a game..."
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={(e) => onBlur?.(e.target.value)}
        maxLength={240}
        onKeyDown={handleKeyDown}
      />
    );
  },
);

const $AppSearchInput = styled.input`
  display: block;
  flex-grow: 1;
  font-family: 'Nexa Text';
  color: white;
  background: none;
  border: none;
  outline: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;
