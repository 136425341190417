import { useLoadedSearchResults } from './searchLoader';
import { P } from '@/components/text/P';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import styled from 'styled-components';
import { AppSortSelectInput } from './AppSortSelectInput';
import { ReactNode, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SearchResultDisplayComponent,
  SearchResultDisplaySortKind,
  useSearchResultDisplayOptions,
} from './searchOptions';
import { useStore } from '@/store/store';
import { DisplayKindRadioGroup } from './DisplayKindRadioGroup';
import { TagBadge } from '@/components/tag/Tag';

export const SearchParameterBar = (): ReactNode => {
  return (
    <$SearchParameterBar>
      <SearchParameters />
      <DisplayParameters />
    </$SearchParameterBar>
  );
};

const $SearchParameterBar = styled.div`
  height: 70px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const SearchParameters = () => {
  return (
    <$SearchParameters>
      <SelectedTags />
      <AppCount />
    </$SearchParameters>
  );
};

const $SearchParameters = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const AppCount = () => {
  const { catalog } = useLoadedCatalog();
  const apps = useLoadedSearchResults();
  return (
    <$AppCount>
      {apps.length} / {catalog.length} games
    </$AppCount>
  );
};

const $AppCount = styled(P).attrs({ variant: 'sm' })`
  text-transform: uppercase;
  font-weight: bold;
`;

const SelectedTags = () => {
  const location = useLocation();
  const { tags } = useLoadedCatalog();
  const searchParams = new URLSearchParams(location.search);
  const selectedTagSlugs = searchParams.getAll('tag');
  const selectedTags = tags.filter((t) => selectedTagSlugs.includes(t.slug));
  return (
    <div>
      {selectedTags.map((tag) => (
        <TagBadge key={tag.slug} tag={tag} />
      ))}
    </div>
  );
};

const DisplayParameters = () => {
  return (
    <$DisplayParameters>
      <AppSortSelect />
      <DisplayKindRadioToggle />
    </$DisplayParameters>
  );
};

const $DisplayParameters = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const AppSortSelect = () => {
  const { sortKind } = useSearchResultDisplayOptions();
  const setSearchResultDisplayOptions = useStore((s) => s.setSearchResultDisplayOptions);
  const handleSortChange = useCallback(
    (sortKind: SearchResultDisplaySortKind) => {
      setSearchResultDisplayOptions((state) => ({ ...state, sortKind }));
    },
    [setSearchResultDisplayOptions],
  );
  return <AppSortSelectInput value={sortKind} onChange={handleSortChange} />;
};

const DisplayKindRadioToggle = () => {
  const { component } = useSearchResultDisplayOptions();
  const setSearchResultDisplayOptions = useStore((s) => s.setSearchResultDisplayOptions);
  const handleChange = useCallback(
    (newValue: SearchResultDisplayComponent) => {
      setSearchResultDisplayOptions((state) => ({ ...state, component: newValue }));
    },
    [setSearchResultDisplayOptions],
  );
  return <DisplayKindRadioGroup value={component} onChange={handleChange} />;
};
