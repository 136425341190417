import { forwardRef, PropsWithChildren, ReactNode, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { P } from '../text/P';
import { isObject } from 'lodash';
import { Link, LinkProps } from 'react-router-dom';

type ButtonProps = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
  variant?: ButtonVariant;
  disabled?: boolean;
}>;

export const Button = ({
  onClick,
  className,
  children,
  variant = 'blue',
  disabled = false,
}: ButtonProps): ReactNode => {
  return (
    <Root onClick={onClick} disabled={disabled} className={className} $variant={variant}>
      {children && (
        <Inner>
          {isObject(children) ? (
            children
          ) : (
            <ButtonDefaultText $variant={variant}>{children}</ButtonDefaultText>
          )}
        </Inner>
      )}
    </Root>
  );
};

type ButtonVariant = 'blue' | 'white' | 'red' | 'transparent';

const variants = {
  blue: {
    backgroundColor: '#3653cc',
    hoverEffectBackgroundColor: '#34baf4',
    color: '#ffffff',
  },
  white: {
    backgroundColor: '#ffffff',
    hoverEffectBackgroundColor: '#34baf4',
    color: '#3653cc',
  },
  red: {
    backgroundColor: '#ff0000',
    hoverEffectBackgroundColor: '#ffc37e',
    color: '#ffffff',
  },
  transparent: {
    backgroundColor: '#00000000',
    hoverEffectBackgroundColor: '#ffffff22',
    color: '#ffffff',
  },
};

const Root = styled.button<{ $variant: ButtonVariant }>`
  padding: 10px 15px;
  border-radius: 100px;
  border: none;
  outline: none;
  position: relative;
  transition: all 0.3s ease-out;
  overflow: hidden;
  cursor: pointer;

  background: ${({ $variant }) => variants[$variant].backgroundColor};
  color: ${({ $variant }) => variants[$variant].color};

  &:active {
    filter: drop-shadow(0px 4px 20px ${({ $variant }) => variants[$variant].backgroundColor}66);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: radial-gradient(
      72.31% 69.17% at 50% 100%,
      ${({ $variant }) => variants[$variant].hoverEffectBackgroundColor} 0%,
      ${({ $variant }) => variants[$variant].backgroundColor} 100%
    );
    transform: translateY(100%);
    transition: transform ease-out 0.3s;
    z-index: 0;
  }

  &:not(:disabled):hover {
    &:before {
      transform: translateY(0);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(20%);
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonDefaultText = styled(P)<{ $variant?: ButtonVariant }>`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: ${({ $variant }) => variants[$variant ?? 'blue'].color};
`;

type LinkButtonProps = LinkProps &
  PropsWithChildren<{
    className?: string;
    variant?: ButtonVariant;
    disabled?: boolean;
  }>;

export const LinkButton = forwardRef(
  (
    { className, children, variant, disabled, ...linkProps }: LinkButtonProps,
    forwardedRef,
  ): ReactNode => {
    const rootRef = useRef<HTMLAnchorElement>(null);
    useImperativeHandle(forwardedRef, () => rootRef.current);
    return (
      <$Link ref={rootRef} {...linkProps}>
        <Button variant={variant} disabled={disabled} className={className}>
          {children}
        </Button>
      </$Link>
    );
  },
);

const $Link = styled(Link)<{ $disabled?: boolean }>`
  &:hover {
    text-decoration: none;
  }
  pointer-events: ${(p) => (p.$disabled ? 'none' : 'all')};
`;
