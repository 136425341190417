import { TicketLoaderState, TicketLoaderStatus, useTicketLoader } from './useTicketLoader';
import { UnexpectedPathError } from '@/lib/errors';
import { isHttpError } from '@/lib/isHttpError';
import {
  useGetActiveExperiences,
  useGetExperience,
  useGetViableExperiences,
} from '@/api/services/timeKeeper';
import { AppMetadata } from '@/api/entities/app';
import {
  ExperienceWithState,
  ExperienceStatus,
  isBillableExperience,
  isAssociatedExperience,
} from '@/api/entities/experience';
import { TicketId } from '@/api/entities/ticket';
import { useCallback, useRef, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { chain } from 'lodash';

export enum ExperienceLoaderStatus {
  Loading = 'Loading',
  LastExperienceSave = 'LastExperienceSave',
  Ticket = 'Ticket',
  Associated = 'Associated',
  Billable = 'Billable',
  Error = 'Error',
}

type ExperienceLoaderLoadingState = {
  status: ExperienceLoaderStatus.Loading;
  ticketId?: undefined;
  experience?: undefined;
};
type ExperienceLoaderLastExperienceSaveState = {
  status: ExperienceLoaderStatus.LastExperienceSave;
  ticketId?: undefined;
  experience?: undefined;
};
type ExperienceLoaderErrorState = {
  status: ExperienceLoaderStatus.Error;
  ticketId?: TicketId;
  experience?: ExperienceWithState;
  error: Error;
};
type ExperienceLoaderTicketState = {
  status: ExperienceLoaderStatus.Ticket;
  ticketId: TicketId;
  ticketLoaderState: TicketLoaderState;
};
type ExperienceLoaderAssociatedState = {
  status: ExperienceLoaderStatus.Associated;
  ticketId: TicketId;
  experience: ExperienceWithState;
};
type ExperienceLoaderBillableState = {
  status: ExperienceLoaderStatus.Billable;
  ticketId: TicketId;
  experience: ExperienceWithState<ExperienceStatus.Billable>;
};

export type ExperienceLoaderState =
  | ExperienceLoaderLoadingState
  | ExperienceLoaderLastExperienceSaveState
  | ExperienceLoaderErrorState
  | ExperienceLoaderTicketState
  | ExperienceLoaderAssociatedState
  | ExperienceLoaderBillableState;

export const useExperienceLoader = (app: AppMetadata): ExperienceLoaderState => {
  const hasCurrentlySavingExperienceQuery = useHasCurrentlySavingExperience(app);
  const prevViableExperienceQuery = useGetBestViableExperienceForApp(app);

  // Only get a ticket if:
  // There are no saving experience AND There is no viable experience.
  const shouldGetTicket =
    !hasCurrentlySavingExperienceQuery.isLoading &&
    !hasCurrentlySavingExperienceQuery.data &&
    !prevViableExperienceQuery.isLoading &&
    !prevViableExperienceQuery.data;

  const ticketLoaderState = useTicketLoader(app, { enable: shouldGetTicket });

  const ticketId = ticketLoaderState.ticket?.id ?? prevViableExperienceQuery.data?.ticketId;

  const experienceQuery = usePollExperience(
    ticketId,
    Boolean(
      ticketId &&
        (ticketLoaderState.status === TicketLoaderStatus.Unqueued || // Poll if we have an unqueued ticket
          prevViableExperienceQuery.data), // OR if we already have a viable experience
    ),
  );

  const error =
    hasCurrentlySavingExperienceQuery.error ||
    prevViableExperienceQuery.error ||
    (ticketLoaderState.status === TicketLoaderStatus.Error && ticketLoaderState.error) ||
    experienceQuery.error ||
    undefined;

  if (error) {
    return {
      status: ExperienceLoaderStatus.Error,
      ticketId: ticketLoaderState.ticket?.id,
      error,
    };
  }

  if (
    hasCurrentlySavingExperienceQuery.isLoading ||
    prevViableExperienceQuery.isLoading ||
    experienceQuery.isLoading ||
    ticketLoaderState.status === TicketLoaderStatus.Loading
  ) {
    return { status: ExperienceLoaderStatus.Loading };
  }

  if (hasCurrentlySavingExperienceQuery.data) {
    return { status: ExperienceLoaderStatus.LastExperienceSave };
  }

  const experience = experienceQuery.isSuccess && experienceQuery.data;

  if (experience) {
    if (isBillableExperience(experience)) {
      return {
        status: ExperienceLoaderStatus.Billable, // Yay!
        ticketId: experience.ticketId,
        experience,
      };
    }
    if (isAssociatedExperience(experience)) {
      return {
        status: ExperienceLoaderStatus.Associated,
        ticketId: experience.ticketId,
        experience,
      };
    }
    if (
      [ExperienceStatus.Terminated, ExperienceStatus.Saving, ExperienceStatus.Error].includes(
        experience.state.status,
      )
    ) {
      return {
        status: ExperienceLoaderStatus.Error,
        ticketId: experience.ticketId,
        experience,
        error: new Error(`Experience has an unexpected status ${experience.state.status}`),
      };
    }
  }
  if (ticketLoaderState.ticket) {
    return {
      status: ExperienceLoaderStatus.Ticket,
      ticketLoaderState,
      ticketId: ticketLoaderState.ticket.id,
    };
  }
  throw new UnexpectedPathError('We have no answer for this combination of state');
};

// It is possible that the experience was not created yet.
// In this case the request should return 404.
// We try again until get the experience. However, it is also possible
// the experience simply does not exist at all. Therefore we limit to 5 retries.
const experienceMaxFailureCount = 5;

const usePollExperience = (ticketId: TicketId | undefined, enable: boolean) => {
  const [keepRefetch, setKeepRefetch] = useState(true);
  const experienceQuery = useGetExperience(ticketId ?? '', {
    enabled: keepRefetch && enable,
    retryDelay: 1000,
    retry: (failureCount, error) => {
      const is404Error = isHttpError(error) && error.status === 404;
      const shouldRetry = is404Error && failureCount < experienceMaxFailureCount;
      if (!shouldRetry) {
        setKeepRefetch(false);
      }
      return shouldRetry;
    },
    refetchInterval: 2000,
  });
  return experienceQuery;
};

/**
 * Poll until found no currently saving experience for this app
 */
const useHasCurrentlySavingExperience = (app: AppMetadata): UseQueryResult<boolean> => {
  const keepPollingRef = useRef(true);
  const select = useCallback(
    (experiences: ExperienceWithState[]) =>
      Boolean(
        experiences.find(
          (e) => e.appUuid === app.uuid && e.state.status === ExperienceStatus.Saving,
        ),
      ),
    [app.uuid],
  );
  const hasSavingExperienceQuery = useGetActiveExperiences({
    enabled: keepPollingRef.current,
    refetchInterval: 10000,
    staleTime: 1000,
    select,
  });
  if (hasSavingExperienceQuery.isSuccess && !hasSavingExperienceQuery.data) {
    keepPollingRef.current = false;
  }
  return hasSavingExperienceQuery;
};

const experienceChoiceSortPredicate = ({ state: { status } }: ExperienceWithState) => {
  // Technically it is possible to obtain multiple experiences for a same app.
  // If that happens we need to take the most interesting.
  const priorityMap: Partial<Record<ExperienceStatus, number>> = {
    [ExperienceStatus.Billable]: 0,
    [ExperienceStatus.Associated]: 1,
  };
  return priorityMap[status] || 2;
};

export const useGetBestViableExperienceForApp = ({
  uuid: appUuId,
}: AppMetadata): UseQueryResult<ExperienceWithState | undefined> => {
  const select = useCallback(
    (experiences: ExperienceWithState[]) =>
      chain(experiences)
        .filter((e) => e.appUuid === appUuId)
        .sortBy(experienceChoiceSortPredicate)
        .first()
        .value(),
    [appUuId],
  );
  return useGetViableExperiences({ select, staleTime: 1000 });
};

export class ExperienceLoaderError extends Error {
  constructor(message?: string) {
    super(message || 'Experience loader error');
    this.name = ExperienceLoaderError.name;
  }
}
