import { useStore } from '@/store/store';
import { z } from 'zod';

export enum SearchResultDisplayComponent {
  Thumbnail = 'Thumbnail',
  Card = 'Card',
}

export enum SearchResultDisplaySortKind {
  AtoZ = 'AtoZ',
  ZtoA = 'ZtoA',
  MostPopular = 'MostPopular',
  MostRecent = 'MostRecent',
}

export type SearchResultDisplayOptions = {
  component?: SearchResultDisplayComponent;
  sortKind?: SearchResultDisplaySortKind;
};

export const useSearchResultDisplayOptions = (): Required<SearchResultDisplayOptions> => {
  const stored = useStore((s) => s.searchResultDisplayOptions);
  return {
    component: stored?.component ?? SearchResultDisplayComponent.Thumbnail,
    sortKind: stored?.sortKind ?? SearchResultDisplaySortKind.AtoZ,
  };
};

export const searchResultDisplayOptionsZod = z.object({
  component: z.nativeEnum(SearchResultDisplayComponent).optional(),
  sortKind: z.nativeEnum(SearchResultDisplaySortKind).optional(),
});
