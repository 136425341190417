import { useLoaderData } from 'react-router-dom';
import { fetchSocisJwt } from '../services/socisJwt';
import { useStore } from '@/store/store';
import { getApiAuth } from '../auth';
import { SocisJwt } from '../entities/socis-jwt';

export const socisJwtLoader = async (): Promise<{ socisJwt: SocisJwt }> => {
  const jwtInStore = useStore.getState().socisJwt;
  if (jwtInStore) {
    return { socisJwt: jwtInStore };
  }
  const auth = await getApiAuth();
  const socisJwt = await fetchSocisJwt(auth);
  useStore.setState((state) => ({ ...state, socisJwt }));
  return { socisJwt };
};

export const useLoadedSocisJwt = (): SocisJwt => {
  const { socisJwt } = useLoaderData() as { socisJwt?: SocisJwt };
  if (!socisJwt) {
    throw Error('Expected loaded socisJwt');
  }
  return socisJwt;
};
