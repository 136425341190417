import { useCallback, useEffect, useRef } from 'react';
import { useStore } from '@/store/store';
import { useDeleteTicketAndExperience } from '../useDeleteTicketAndExperience';
import { useIsTabFocused } from '../useIsTabFocused';

export const useAfkDetection = (afkTimeoutMs = 60000, afkDisconnectTimeoutMs = 120000): void => {
  useAfkTimer(afkTimeoutMs);
  useAfkDisconnectedTimer(afkDisconnectTimeoutMs);
};

const useAfkTimer = (timeoutMs: number) => {
  const setAfk = useStore((s) => s.setAfk);
  const handleAfkTimeout = useCallback(() => {
    setAfk(true);
  }, [setAfk]);
  const handleAfkTimerReset = useCallback(() => {
    setAfk(false);
  }, [setAfk]);
  useBlurredTabTimer(timeoutMs, handleAfkTimeout, handleAfkTimerReset);
};

const useAfkDisconnectedTimer = (timeoutMs: number) => {
  const deleteTicketAndExperience = useDeleteTicketAndExperience();
  const setAfkDisconnect = useStore((s) => s.setAfkDisconnect);
  const handleAfkDisconnectTimeout = useCallback(() => {
    setAfkDisconnect(true);
    deleteTicketAndExperience();
  }, [deleteTicketAndExperience, setAfkDisconnect]);
  useBlurredTabTimer(timeoutMs, handleAfkDisconnectTimeout);
};

const useBlurredTabTimer = (
  timeoutMs: number,
  onTimeOut: () => void,
  onTimerReset?: () => void,
) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isTabFocused = useIsTabFocused();
  useEffect(() => {
    if (isTabFocused) {
      clearTimeout(timeoutRef.current);
      onTimerReset?.();
    } else {
      timeoutRef.current = setTimeout(onTimeOut, timeoutMs);
    }
  }, [isTabFocused, onTimeOut, onTimerReset, timeoutMs]);
};
