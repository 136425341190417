import { ReactNode } from 'react';
import { AppThumbnail } from './AppThumbnail';
import styled from 'styled-components';
import { H6 } from '@/components/text/H';
import { ClockBadge } from './ClockBadge';
import { AppInfo } from './AppInfo';
import { useShowTransition } from '@/lib/hooks/useShowTransition';
import { HydratedApp } from '@/api/entities/hydrated-app';

export type ExpandedAppId = { scrollId: string; appUuid: string };

export const AppThumbnailScrollListView = ({
  title,
  catalog,
  expandedApp,
  onAppExpandRequest,
  displayTime,
  scrollId,
}: {
  title: string;
  catalog: HydratedApp[];
  expandedApp?: ExpandedAppId;
  onAppExpandRequest: (app: HydratedApp) => void;
  displayTime?: boolean;
  scrollId: string;
}): ReactNode => {
  const showAppInfo = expandedApp?.scrollId === scrollId;
  const [shouldAppInfoRender, ref] = useShowTransition(
    showAppInfo,
    { height: 0, overflow: 'hidden', ease: 'linear' },
    { height: 400, duration: 0.5, ease: 'linear' },
  );
  return (
    <$AppThumbnailScrollList>
      <H6>{title}</H6>
      <List>
        {catalog.map((app) => (
          <AppThumbnail
            key={app.uuid}
            app={app}
            selected={expandedApp?.appUuid === app.uuid && expandedApp?.scrollId === scrollId}
            grayedOut={expandedApp?.scrollId === scrollId && expandedApp?.appUuid !== app.uuid}
            onClick={() => onAppExpandRequest(app)}
            badge={app.remainingTimeSec < app.totalTime && displayTime && <ClockBadge app={app} />}
          />
        ))}
      </List>
      {shouldAppInfoRender && (
        <div ref={ref}>
          <AppInfo />
        </div>
      )}
    </$AppThumbnailScrollList>
  );
};

const $AppThumbnailScrollList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 25px;
  h6 {
    padding-left: 15px;
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Nexa Text', sans-serif;
    font-weight: 300;
    letter-spacing: 0em;
    line-height: 1.5em;
    padding: 0 20px;
    position: absolute;
  }
`;

const List = styled.div`
  display: flex;
  height: 250px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  pointer-events: all;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 0 20px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
