import { useLocation, useParams } from 'react-router-dom';
import { z } from 'zod';
import { HydratedApp } from '@/api/entities/hydrated-app';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';

export const useAppSlugFromPath = (): string | undefined => {
  const { appSlug: unsafeAppSlug } = useParams();
  return z.string().optional().parse(unsafeAppSlug);
};

export const useAppFromPath = (): HydratedApp | undefined => {
  const slug = useAppSlugFromPath();
  const { catalog } = useLoadedCatalog();
  const app = catalog.find((app) => app.nameSlug === slug);
  if (!app) {
    return undefined;
  }
  return app;
};

export const useDefinedAppFromPath = (): HydratedApp => {
  const app = useAppFromPath();
  const location = useLocation();
  if (!app) {
    throw new AppNotDefinedInPathError(location.pathname);
  }
  return app;
};

class AppNotDefinedInPathError extends Error {
  constructor(pathname: string) {
    super(`URL does not specify any app: ${pathname}`);
    this.name = AppNotDefinedInPathError.name;
  }
}
