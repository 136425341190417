import { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import { P } from '../../text/P';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { useStore } from '@/store/store';
import { ClockDigits } from './ClockDigits';
import { useToggle } from '@uidotdev/usehooks';

export const Clock = (): ReactNode => {
  const [displayTime, toggleDisplayTime] = useToggle(false);
  const timeHolderRef = useRef<HTMLDivElement>(null);
  const timeContentRef = useRef<HTMLDivElement>(null);
  const experienceState = useStore((s) => s.experienceState);

  const remainingTimeSec = experienceState ? experienceState.remainingTimeSec : undefined; // à changer une fois que le timer est bien exprimée en secondes

  useGSAP(() => {
    const tl = gsap.timeline();

    if (timeContentRef.current) {
      tl.from(timeContentRef.current, {
        duration: 0.15,
        opacity: 0,
        delay: 0.3,
      }).to(timeContentRef.current, {
        duration: 0.15,
        opacity: 1,
        delay: 0.3,
      });
    }

    if (timeHolderRef.current) {
      gsap.to(timeHolderRef.current, {
        duration: 0.3,
        ease: 'power4.inOut',
        width: displayTime ? '100%' : 54,
      });
    }

    const timeoutId = setTimeout(() => {
      toggleDisplayTime(false);
    }, 7000);

    return () => clearTimeout(timeoutId);
  }, [displayTime, toggleDisplayTime]);

  if (!remainingTimeSec) return null;

  const clockColor = experienceState
    ? getClockColor(remainingTimeSec, experienceState.totalTimeSec)
    : ClockColor.Transparent;

  return (
    <$Clock>
      <Time $clockColor={clockColor} ref={timeHolderRef} onClick={() => toggleDisplayTime()}>
        <TimeContent ref={timeContentRef}>
          {displayTime && remainingTimeSec && (
            <P variant="sm">Remaining time: {Math.floor(remainingTimeSec / 60)} minutes</P>
          )}
          {!displayTime && remainingTimeSec && <ClockDigits timeRemainingSec={remainingTimeSec} />}
        </TimeContent>
      </Time>
    </$Clock>
  );
};

const getClockColor = (timeRemaining: number, totalTimeMs: number): string => {
  if (timeRemaining > totalTimeMs / 2) {
    return ClockColor.Blue;
  } else if (timeRemaining > totalTimeMs / 4) {
    return ClockColor.Yellow;
  } else if (timeRemaining > 0) {
    return ClockColor.Red;
  }
  return ClockColor.Transparent;
};

const $Clock = styled.div`
  width: 246px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Time = styled.div<{ $clockColor: string }>`
  transition: all 0.3s ease 0s;
  width: 54px;
  height: 54px;
  border-radius: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ffffff00;
  background: ${({ $clockColor }) => $clockColor}4e;
  &:hover {
    border: 1px solid ${({ $clockColor }) => $clockColor};
  }
`;

const TimeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum ClockColor {
  Blue = '#87a0f9',
  Yellow = '#f9c440',
  Red = '#f94040',
  Transparent = '#00000000',
}
