import { z } from 'zod';

const httpErrorZod = z.object({
  status: z.number().min(0),
  statusText: z.string(),
});

type HttpError = z.infer<typeof httpErrorZod>;

export const isHttpError = (value: unknown): value is HttpError => {
  return httpErrorZod.safeParse(value).success;
};
