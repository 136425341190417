import { AppMetadata } from '../entities/app';
import { queryClient } from '../queryClient';
import { AuthTokenSet } from '../queries/utils';
import { fetchCatalog as doFetchCatalog, fetchTags as doFetchTags } from '../queries/catalog';
import { TagMetadata } from '../entities/tags';

const queryKeys = {
  fetchCatalog: (auth: AuthTokenSet) => [auth, 'catalog'],
  fetchTags: (auth: AuthTokenSet) => [auth, 'tags'],
};

export const fetchCatalog = async (auth: AuthTokenSet): Promise<AppMetadata[]> => {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.fetchCatalog(auth),
    queryFn: () => doFetchCatalog(auth),
  });
};

export const fetchTags = async (auth: AuthTokenSet): Promise<TagMetadata[]> => {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.fetchTags(auth),
    queryFn: () => doFetchTags(auth),
  });
};
