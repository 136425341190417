import { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

import { FaceSelect } from './FaceSelect';
import { useFeedbackStore, Status } from './store/store';
import { Button } from '@/components/button/Button';

export const Rating = (): ReactNode => {
  const modal = useRef<HTMLDivElement | null>(null);
  const [rating, setRating] = useState<number | undefined>(undefined);
  const { setStatus, pushAnswer } = useFeedbackStore();

  const onClickHandler = () => {
    const answer = {
      answerType: 'scale',
      questionId: 'nps',
      questionValue: 'How would you rate your experience?',
      answer: rating,
    };

    if (rating === undefined) return;
    pushAnswer(answer);
    if (rating <= 2) {
      setStatus(Status.Issue);
    } else {
      setStatus(Status.Recommend);
    }
  };

  return (
    <>
      <StyledRating ref={modal}>
        <FaceSelect value={rating} onChange={setRating} />
      </StyledRating>
      <$Button onClick={onClickHandler}>SUBMIT</$Button>
    </>
  );
};

const $Button = styled(Button)`
  width: 90%;
`;

const StyledRating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default Rating;
