import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { ExperienceWithApp } from '@/store/appSessionStoreSlice';
import { PropsWithChildren, useState, useCallback, ReactNode } from 'react';
import { useDefinedAppFromPath } from '../utils';
import { UseQueryResult } from '@tanstack/react-query';
import { AlreadyRunningAppModal } from './AlreadyRunningAppModal/AlreadyRunningAppModal';
import { useDeleteExperienceMutation, useGetViableExperiences } from '@/api/services/timeKeeper';
import { ExperienceWithState } from '@/api/entities/experience';
import { FatalErrorModal } from '@/components/modal/FatalErrorModal';
import { useNavigate } from 'react-router-dom';
import { homePagePath } from '@/routes/routePaths';
import { Button } from '@/components/button/Button';

export const AlreadyRunningAppGuard = ({ children }: PropsWithChildren): ReactNode => {
  const app = useDefinedAppFromPath();
  const { mutate: deleteExperience } = useDeleteExperienceMutation();

  const [inhibitModal, setInhibitModal] = useState(false);
  const otherRunningExperienceQuery = useRunningExperienceForOtherApp();
  const otherRunningExperienceTicketId = otherRunningExperienceQuery.data?.ticketId;
  const handleCandidateAppChosen = useCallback(() => {
    if (otherRunningExperienceTicketId) {
      setInhibitModal(true);
      deleteExperience(otherRunningExperienceTicketId);
    }
  }, [deleteExperience, otherRunningExperienceTicketId]);

  if (otherRunningExperienceQuery.isLoading) {
    return null;
  }

  const error = otherRunningExperienceQuery.error;

  if (error) {
    return <FatalErrorController message="Unable to get current gaming experiences" />;
  }

  const otherRunningApp = otherRunningExperienceQuery.data?.app;

  return (
    <>
      {otherRunningApp && (
        <AlreadyRunningAppModal
          runningApp={otherRunningApp}
          candidateApp={app}
          show={otherRunningExperienceQuery && !inhibitModal}
          onCandidateAppChosen={handleCandidateAppChosen}
        />
      )}
      {(!otherRunningApp || inhibitModal) && children}
    </>
  );
};

const FatalErrorController = ({ message }: { message: string }) => {
  const navigate = useNavigate();
  return (
    <FatalErrorModal
      show
      title="Could not start game"
      message={message}
      cta={<Button onClick={() => navigate(homePagePath)}>Back to home page</Button>}
    />
  );
};

const useRunningExperienceForOtherApp = (): UseQueryResult<ExperienceWithApp | undefined> => {
  const { uuid: candidateAppUuid } = useDefinedAppFromPath();
  const { catalog } = useLoadedCatalog();
  const select = useCallback(
    (experiences: ExperienceWithState[]) => {
      const experience = experiences.find((e) => e.appUuid !== candidateAppUuid);
      const app = catalog.find((a) => a.uuid === experience?.appUuid);
      return experience && app ? { ...experience, app } : undefined;
    },
    [candidateAppUuid, catalog],
  );
  return useGetViableExperiences({
    select,
    retry: 0,
    staleTime: 1000,
  });
};
