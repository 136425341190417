import { z } from 'zod';
import { AuthTokenSet, createHeaders } from './utils';
import { env } from '@/env';
import { AppMetadata, appZod, parseBackendApp } from '../entities/app';
import { TagMetadata, tagZod } from '../entities/tags';

const fetchCatalogResponseZod = z.array(appZod);

export const fetchCatalog = async (auth: AuthTokenSet): Promise<AppMetadata[]> => {
  const response = await fetch(`${env.catalogUrl}v1/application/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (response.status >= 400) {
    throw response;
  }
  const data = fetchCatalogResponseZod.parse(await response.json());
  return data.map(parseBackendApp);
};
const fetchCatalogTagResponseZod = z.array(tagZod);

export const fetchTags = async (auth: AuthTokenSet): Promise<TagMetadata[]> => {
  const response = await fetch(`${env.catalogUrl}v1/tag/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (response.status >= 400) {
    throw response;
  }
  const data = fetchCatalogTagResponseZod.parse(await response.json());
  return data.map((tag) => ({
    name: tag.name,
    slug: tag.slug,
  }));
};
