import styled from 'styled-components';
import { useFeedbackStore } from './store/store';
import { ReactNode } from 'react';
import { P } from '@/components/text/P';

export const BackButton = (): ReactNode => {
  const { undoStatus } = useFeedbackStore();

  return (
    <$BackButton onClick={undoStatus}>
      <P variant="sm">{'<'}&nbsp;Back</P>
    </$BackButton>
  );
};

const $BackButton = styled.button`
  all: unset;
  cursor: pointer;
  border-bottom: 1px solid #ffffff00;
  transition: all 0.2s ease 0s;
  &:hover {
    border-bottom: 1px solid #fff;
  }
  &:before {
    content: '';
    all: unset;
  }
`;
