import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { AppMetadata } from '../entities/app';
import { useCallback } from 'react';
import { queryClient } from '../queryClient';
import { useApiAuth } from '../auth';
import { ExperienceWithState } from '../entities/experience';
import { AppTimeMetadata } from '../entities/app-time-metadata';
import {
  deleteExperience as doDeleteExperience,
  getTimeRemaining as doGetTimeRemaining,
  fetchActiveExperiences as doFetchActiveExperiences,
  fetchExperience as doFetchExperience,
  TimeMetadataPerApp,
} from '../queries/timeKeeper';
import { TicketId } from '../entities/ticket';
import { AuthTokenSet, ReactQueryOptions } from '../queries/utils';
import { useIsExperienceViable } from '@/pages/app/useGetViableExperience';

const queryKeys = {
  getExperience: (auth: AuthTokenSet, ticketId: TicketId) => [auth, 'getExperience', { ticketId }],
  getActiveExperiences: (auth: AuthTokenSet) => [auth, 'getActiveExperiences'],
  getTimeRemaining: (auth: AuthTokenSet) => [auth, 'getTimeRemaining'],
};

export const getTimeRemaining = async <TData = TimeMetadataPerApp>(
  auth: AuthTokenSet,
  options?: ReactQueryOptions<TimeMetadataPerApp, TData>,
): Promise<TimeMetadataPerApp> => {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.getTimeRemaining(auth),
    queryFn: () => doGetTimeRemaining(auth),
    ...options,
  });
};

export const useGetTimeRemaining = <TData = TimeMetadataPerApp>(
  options?: ReactQueryOptions<TimeMetadataPerApp, TData>,
): UseQueryResult<TData> => {
  const auth = useApiAuth();
  return useQuery({
    queryKey: queryKeys.getTimeRemaining(auth),
    queryFn: () => doGetTimeRemaining(auth),
    ...options,
  });
};

export const useGetTimeRemainingForApp = (
  app: AppMetadata,
  options?: Exclude<ReactQueryOptions<TimeMetadataPerApp, AppTimeMetadata | undefined>, 'select'>,
): UseQueryResult<AppTimeMetadata> => {
  return useGetTimeRemaining({
    ...options,
    select: (timeDataPerApp: TimeMetadataPerApp) => {
      const timeForApp = timeDataPerApp[app.imageId];
      if (!timeForApp) {
        console.warn(`Backend sent no time info for app ${app.imageId}. Will provide fake data...`);
        return {
          remainingTimeSec: 100,
          usedTimeSec: 100,
        };
      }
      return timeForApp;
    },
  });
};

export const useGetExperience = (
  ticketId: TicketId,
  options?: ReactQueryOptions<ExperienceWithState>,
): UseQueryResult<ExperienceWithState> => {
  const auth = useApiAuth();
  return useQuery({
    queryKey: queryKeys.getExperience(auth, ticketId),
    queryFn: () => doFetchExperience(auth, ticketId),
    ...options,
  });
};

export const useDeleteExperienceMutation = (): UseMutationResult<void, unknown, TicketId> => {
  const auth = useApiAuth();
  return useMutation({
    mutationFn: (ticketId: TicketId) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.getActiveExperiences(auth) });
      queryClient.invalidateQueries({ queryKey: queryKeys.getExperience(auth, ticketId) });
      return doDeleteExperience(auth, ticketId);
    },
  });
};

export const useGetActiveExperiences = <TData = ExperienceWithState[]>(
  options: ReactQueryOptions<ExperienceWithState[], TData> = {},
): UseQueryResult<TData> => {
  const auth = useApiAuth();
  return useQuery({
    queryKey: queryKeys.getActiveExperiences(auth),
    queryFn: () => doFetchActiveExperiences(auth),
    ...options,
  });
};

export const useGetViableExperiences = <TData = ExperienceWithState[]>({
  select: optionsSelect,
  ...options
}: ReactQueryOptions<ExperienceWithState[], TData> = {}): UseQueryResult<TData> => {
  const isExperienceViable = useIsExperienceViable();
  const select = useCallback(
    (experiences: ExperienceWithState[]): TData => {
      const prefiltered = experiences.filter((e) => isExperienceViable(e));
      return optionsSelect ? optionsSelect(prefiltered) : (prefiltered as TData);
    },
    [isExperienceViable, optionsSelect],
  );
  return useGetActiveExperiences({ ...options, select });
};
