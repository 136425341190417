import { MutableRefObject, RefObject, useCallback } from 'react';
import { useValueRef } from './useValueRef';
import { useClickAway } from '@uidotdev/usehooks';

export const useOutsideClick = <T extends Element>(
  callback?: (event: Event) => void,
  exceptions?: RefObject<Node>[], // Ref of objects for which the callback will not be called
): MutableRefObject<T> => {
  const exceptionsRef = useValueRef(exceptions);

  const handleClickOutside = useCallback(
    (event: Event) => {
      const exceptions = exceptionsRef.current || [];
      for (const exceptionRef of exceptions) {
        if (exceptionRef.current?.contains(event.target as Node)) {
          return;
        }
      }
      callback?.(event);
    },
    [callback, exceptionsRef],
  );

  return useClickAway(handleClickOutside);
};
