import { HydratedApp } from '@/api/entities/hydrated-app';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { P } from '@/components/text/P';
import { timeoutAnticipationTimeSec } from '../app/run/timeout';

interface AppThumbnailProps {
  app: HydratedApp;
  badge?: ReactNode;
  selected?: boolean;
  grayedOut?: boolean;
  onClick?: () => void;
}

export const AppThumbnail = ({
  app,
  badge,
  selected,
  grayedOut,
  onClick,
}: AppThumbnailProps): ReactNode => {
  const isAppTimeExpired = app.remainingTimeSec <= timeoutAnticipationTimeSec && Boolean(badge);
  return (
    <$AppThumbnail
      $selected={selected}
      $grayedOut={grayedOut}
      $isAppTimeExpired={isAppTimeExpired}
      onClick={onClick}
    >
      <AppIcon src={app.icon} />
      <AppName>{app.name}</AppName>
      {badge}
    </$AppThumbnail>
  );
};

const AppIcon = styled.img`
  width: calc(100% - 20px);
  border-radius: 8px;
  z-index: 5;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
`;

const AppName = styled(P).attrs({ variant: 'sm' })`
  position: absolute;
  display: -webkit-box;
  top: 130px;
  text-transform: uppercase;
  transition: all 0.3s cubic-bezier(0.67, 0.7, 0.58, 1.85);
  opacity: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 4;
  width: calc(100% - 40px);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 auto;
  text-align: center;
`;

const $AppThumbnail = styled.div<{
  $selected?: boolean;
  $grayedOut?: boolean;
  $isAppTimeExpired?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: all;
  scroll-snap-align: center;
  flex: none;
  width: 140px;
  height: 250px;
  cursor: pointer;
  text-decoration: none !important;
  position: relative;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  ${(p) =>
    p.$selected &&
    css`
      transform: scale(1.1);
      ${AppIcon} {
        filter: drop-shadow(0px 0px 20px #3b69ffc6);
      }
      ${AppName} {
        opacity: 1;
        margin-top: 60px;
      }
    `}

  &:hover {
    transform: scale(1.1);
    ${AppIcon} {
      transform: translate(0, -5px);
    }
    ${AppName} {
      opacity: 1;
      margin-top: 60px;
    }
  }
  ${(p) =>
    p.$grayedOut &&
    css`
      transform: scale(1);
      opacity: 1;
      mix-blend-mode: luminosity;
      opacity: 0.5;
      ${AppName} {
        opacity: 0;
      }
      &:hover {
        ${AppName} {
          opacity: 0;
        }
      }
    `}
  ${(p) =>
    p.$isAppTimeExpired &&
    css`
      transform: scale(1);
      opacity: 1;
      ${AppIcon} {
        filter: grayscale();
      }
      &:hover {
        transform: scale(1);
      }
    `}
`;
