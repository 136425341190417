import { ReactNode } from 'react';
import gsap from 'gsap';
import { AppDisplay } from '../home/AppDisplay';
import {
  OutletTransitionContext,
  useSwitchTransitionOutletContext,
} from '@/components/animation/SwitchTransitionOutlet';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { useStore } from '@/store/store';

export const HomePage = (): ReactNode => {
  const { catalog } = useLoadedCatalog();
  const homePageCarouselApp = useStore((state) => state.homePageCarouselApp);
  const app = catalog.find((app) => app.uuid === homePageCarouselApp);
  const outletMetadata = useSwitchTransitionOutletContext(transitions);
  if (!app) {
    return null;
  }
  return <AppDisplay ref={outletMetadata.ref} app={app} />;
};

const transitions: OutletTransitionContext = {
  onEnter: (node, onComplete) => {
    gsap.from(node, {
      opacity: 0,
      y: 10,
      duration: 0.3,
      onComplete,
    });
  },
  onExit: (node, onComplete) => {
    gsap.to(node, {
      opacity: 0,
      duration: 0.3,
      onComplete,
    });
  },
};
