import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { MobileSearchBar } from './MobileSearchBar';
import { ReactNode, useCallback, useState } from 'react';
import { DesktopSearchBar } from './DesktopSearchBar';
import { useNavigate, useParams } from 'react-router-dom';
import { getSearchPageUrl } from '@/routes/routePaths';

export const SearchBar = (): ReactNode => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { queryText } = useParams();
  const handleSubmit = useCallback(
    (queryText?: string) => {
      navigate(getSearchPageUrl({ queryText }));
    },
    [navigate],
  );
  const [queryTextValue, setQueryTextValue] = useState(queryText ?? '');
  const SearchBarComponent = isMobile ? MobileSearchBar : DesktopSearchBar;
  return (
    <SearchBarComponent
      value={queryTextValue}
      onChange={setQueryTextValue}
      onSubmit={handleSubmit}
    />
  );
};

export interface SearchBarProps {
  value?: string | undefined;
  onChange?: (value: string) => void;
  onSubmit?: (text: string | undefined) => void;
}
