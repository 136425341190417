import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiAuth } from '../auth';
import { TicketId } from '../entities/ticket';
import { FetchTicketResponse, getTicket, deleteTicket } from '../queries/ticket';
import { AuthTokenSet, ReactQueryOptions } from '../queries/utils';

const queryKeys = {
  getTicket: (auth: AuthTokenSet, ticketId: TicketId) => [auth, 'getTicket', ticketId],
};

export const useGetTicket = (
  ticketId: TicketId,
  options?: ReactQueryOptions<FetchTicketResponse>,
): UseQueryResult<FetchTicketResponse> => {
  const auth = useApiAuth();
  return useQuery({
    queryKey: queryKeys.getTicket(auth, ticketId),
    queryFn: () => getTicket(auth, ticketId),
    ...options,
  });
};

export const useDeleteTicketMutation = (): UseMutationResult<void, unknown, TicketId> => {
  const auth = useApiAuth();
  return useMutation({
    mutationFn: async (ticketId: TicketId) => {
      await deleteTicket(auth, ticketId);
    },
  });
};
