import { ComponentType, ForwardRefExoticComponent } from 'react';
import gsap from 'gsap';
import { useShowTransition } from '@/lib/hooks/useShowTransition';
import { ModalOverlay } from './ModalOverlay';

interface ShowTransition {
  from: gsap.TweenVars;
  to: gsap.TweenVars;
}

interface ShowTransitionProps {
  show?: boolean;
}

const defaultModalTransition: ShowTransition = {
  from: { y: 20, opacity: 0 },
  to: { y: 0, opacity: 1, duration: 0.3 },
};

/**
 * Animate the apparition of a component.
 * @param Component A component that should appear when props.show turns true.
 * @param transition Appearing transition. By default, it does a small slide-up and fade-in.
 * @returns A new component that wraps your component.
 */
export function modalWindowWithTransition<WindowProps extends {} = {}>(
  Component: ForwardRefExoticComponent<WindowProps>,
  transition: ShowTransition = defaultModalTransition,
): ComponentType<WindowProps & ShowTransitionProps> {
  return ({ show, ...props }: WindowProps & ShowTransitionProps) => {
    const [renderOverlay, windowRef] = useShowTransition(
      show ?? false,
      transition.from,
      transition.to,
    );
    if (!renderOverlay) {
      return null;
    }
    return <Component ref={windowRef} {...(props as WindowProps)} />;
  };
}

const defaultOverlayTransition: ShowTransition = {
  from: { opacity: 0 },
  to: { opacity: 1, duration: 0.5, ease: 'power2.inOut' },
};

/**
 * Wrap a component inside an overlay that covers the user's viewport.
 * @param Component A component that should appear when props.show turns true.
 * @param transition Overlay appearing transition. By default, it fades in.
 * @returns A new component that wraps your component.
 */
export function wrappedInOverlay<WindowProps extends {} = {}>(
  Component: ComponentType<WindowProps & ShowTransitionProps>,
  transition: ShowTransition = defaultOverlayTransition,
): ComponentType<WindowProps & ShowTransitionProps> {
  const OverlayedComponent = ({ show, ...props }: WindowProps & ShowTransitionProps) => {
    const [renderOverlay, overlayRef] = useShowTransition(
      show ?? false,
      transition.from,
      transition.to,
    );
    if (!renderOverlay) {
      return null;
    }
    return (
      <ModalOverlay ref={overlayRef}>
        <Component show={show} {...(props as WindowProps)} />
      </ModalOverlay>
    );
  };
  return OverlayedComponent;
}

interface Options {
  windowTransition?: ShowTransition;
  overlayTransition?: ShowTransition;
}

/**
 * Create an animated modal inside an overlay.
 * @param WindowComponent A modal window component with whatever you want in it.
 * @param options.windowTransition Window appearing transition. By default, it does a small slide-up and fade-in.
 * @param options.overlayTransition Overlay appearing transition. By default, it fades in.
 * @returns A new component that wraps your component.
 */
export function createModal<WindowProps extends {} = {}>(
  WindowComponent: ForwardRefExoticComponent<WindowProps>,
  options?: Options,
): ComponentType<WindowProps & ShowTransitionProps> {
  return wrappedInOverlay(
    modalWindowWithTransition(WindowComponent, options?.overlayTransition),
    options?.overlayTransition,
  );
}
