import { useState, ChangeEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { useFeedbackStore, Status } from './store/store';
import { Button } from '@/components/button/Button';
import { P } from '@/components/text/P';

export const Email = (): ReactNode => {
  const { setStatus, pushAnswer } = useFeedbackStore();
  const [email, setEmail] = useState('');

  const answer = {
    answerType: 'email',
    questionId: 'email',
    questionValue:
      'Enter your email if you want to receive news or special offers from our services',
    answer: email,
  };

  const onClickHandler = () => {
    if (email) pushAnswer(answer);
    setStatus(Status.Share);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <Content>
        <InputSection>
          <Explanation variant="sm">
            Enter your email if you want to receive news or special offers from our services
          </Explanation>
          <InputContainer>
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleInputChange}
            />
          </InputContainer>
        </InputSection>
        <$Button onClick={onClickHandler}>{!email ? 'DISMISS' : 'SUBMIT'}</$Button>
      </Content>
    </>
  );
};

const Explanation = styled(P)`
  opacity: 0.5;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 90%;
  text-align: center;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 33px 0px;
`;

const InputContainer = styled.div`
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #9ba9e620;
  border-radius: 8px;
  input {
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    border: none;
    outline: none;
    width: 100%;
    height: 33px;
    padding: 0 8px;
    &::placeholder {
      font-family: Nexa Text;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
      opacity: 0.5;
    }
  }
`;

const $Button = styled(Button)`
  width: 100%;
`;
