import errorSvg from '@/assets/error.svg';
import { forwardRef, ReactNode } from 'react';
import { Button } from '@/components/button/Button';
import { P } from '@/components/text/P';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';

interface Props {
  error?: Error;
  onGameRestartButtonClick?: () => void;
}

export const QueueErrorModalWindow = forwardRef(
  ({ onGameRestartButtonClick }: Props, forwardedRef): ReactNode => {
    return (
      <ModalWindow ref={forwardedRef}>
        <ModalContent>
          <img src={errorSvg} alt="warning icon" />
          <ModalTitle>Uh oh, something went wrong</ModalTitle>
          <P variant="sm">
            The waiting list encountered a fatal strike and crashed 💥.
            <br />
            Our valiant Ninja is already sharpening his katana to get things back up and running 🥷.
          </P>
        </ModalContent>
        <ModalCta>
          <Button onClick={onGameRestartButtonClick}>Restart the game</Button>
        </ModalCta>
      </ModalWindow>
    );
  },
);

export const QueueErrorModal = createModal(QueueErrorModalWindow);
