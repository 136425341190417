import { ReactNode } from 'react';
import { AppThumbnailScrollList } from './AppThumbnailScrollList';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';

export const LastGamePlayedScrollList = (): ReactNode => {
  const { catalog } = useLoadedCatalog();

  const lastGamePlayed = catalog.filter((app) => app.usedTimeSec > 0);
  if (lastGamePlayed.length === 0) {
    return null;
  }
  return (
    <AppThumbnailScrollList
      title={'last game played'}
      catalog={lastGamePlayed}
      displayTime={true}
    />
  );
};
