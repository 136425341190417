import { TagMetadata } from './tags';
import { TimeMetadataPerApp } from '../queries/timeKeeper';
import { AppMetadata } from './app';
import { AppTimeMetadata } from './app-time-metadata';

export type HydratedApp = AppMetadata &
  AppTimeMetadata & {
    totalTime: number;
  } & { tags: TagMetadata[] };

export const hydrateApps = (
  apps: AppMetadata[],
  timeMetadataPerGame: TimeMetadataPerApp,
  tags: TagMetadata[],
): HydratedApp[] => {
  return apps.map((app) => {
    const timeMetadata = timeMetadataPerGame[app.imageId];
    if (!timeMetadata) {
      throw Error(`No time metadata found for app ${app.imageId}`);
    }
    return {
      ...app,
      ...timeMetadata,
      totalTime: timeMetadata.usedTimeSec + timeMetadata.remainingTimeSec,
      tags: tags.filter((t) => app.tagSlugs.includes(t.slug)),
    };
  });
};
