import { forwardRef, ReactNode, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { H6 } from '../text/H';
import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { ExperienceWithApp } from '@/store/appSessionStoreSlice';
import { UseQueryResult } from '@tanstack/react-query';
import { AppMetadata } from '@/api/entities/app';
import { LinkButton } from '../button/Button';
import { getAppRunPageUrl } from '@/routes/routePaths';
import { useBoolState } from '@/lib/hooks/useBoolState';
import { useUniqueMountId } from '@/lib/hooks/useUniqueMountId';
import { first } from 'lodash';
import { CloseButton } from '../modal/inner-components';
import { ModalWindow } from '../modal/ModalWindow';
import { modalWindowWithTransition } from '../modal/hoc';
import { useGetViableExperiences } from '@/api/services/timeKeeper';
import { ExperienceWithState } from '@/api/entities/experience';

interface Props {
  app: AppMetadata;
  onRequestClose?: () => void;
  className?: string;
}

export const RunningExperienceToastWindow = forwardRef(
  ({ app, onRequestClose, className }: Props, forwardedRef): ReactNode => {
    return (
      <$ModalWindow ref={forwardedRef} className={className}>
        <CloseButton onClick={onRequestClose} />
        <H6>{app.name} is running...</H6>
        <Container>
          <AppImg src={app.icon} />
          <LinkButton to={getAppRunPageUrl(app.nameSlug)}>Return to game</LinkButton>
        </Container>
      </$ModalWindow>
    );
  },
);

const $ModalWindow = styled(ModalWindow)`
  background: rgba(255, 255, 255, 0.1);
  right: 10px;
  width: 300px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const AppImg = styled.img`
  border-radius: 10px;
  height: 50px;
`;

const useViableExperience = (): UseQueryResult<ExperienceWithApp | undefined> => {
  const { catalog } = useLoadedCatalog();
  const select = useCallback(
    (experiences: ExperienceWithState[]) => {
      const experience = first(experiences); // If we find multiple, we just take one.
      const app = catalog.find((a) => a.uuid === experience?.appUuid);
      return experience && app ? { ...experience, app } : undefined;
    },
    [catalog],
  );
  return useGetViableExperiences({
    select,
    refetchInterval: 5000,
    staleTime: 1000,
    queryKey: [useUniqueMountId()], // Dont use anyone's cache.
  });
};

export const RunningExperienceToastController = (): ReactNode => {
  const [modalWasClosed, setModalWasClosed] = useBoolState(false);
  const { data: experience, isLoading, error } = useViableExperience();
  useEffect(() => {
    if (error) {
      console.warn(error);
    }
  }, [error]);

  if (!experience) {
    return null;
  }
  return (
    <$RunningExperienceToast
      show={Boolean(!modalWasClosed && !isLoading && experience)}
      app={experience.app}
      onRequestClose={setModalWasClosed}
    />
  );
};

const $RunningExperienceToast = styled(modalWindowWithTransition(RunningExperienceToastWindow))`
  position: absolute;
  top: 65px;
`;
