import { ReactNode, RefObject } from 'react';
import { useOutlet, useOutletContext } from 'react-router-dom';
import { z } from 'zod';

type Transition = (node: HTMLDivElement, done: () => void) => void;

export type OutletTransitionContext = {
  onEnter?: Transition;
  onExit?: Transition;
};

export type OutletContext = OutletTransitionContext & {
  ref: RefObject<HTMLDivElement>;
};

/**
 * A typed version of react-router-dom's useOutlet().
 * @param outletMetadata The ref for the currently displayed outlet. This ref must be bound by the outlet to its own root element.
 * @returns The outlet element.
 */
export const useSwitchTransitionOutlet = (outletMetadata: OutletContext): ReactNode => {
  return useOutlet(outletMetadata);
};

const ctxZod = z.object({});

/**
 * @returns A context containing a ref to bind to the root element. This is necessarly for the switch transition to handle its children.
 */
export const useSwitchTransitionOutletContext = (
  transitionContext?: OutletTransitionContext,
): OutletContext => {
  const ctx = useOutletContext<OutletContext>();
  if (ctxZod.safeParse(ctx).error) {
    throw Error('Invalid SwitchTransitionOutlet');
  }
  if (transitionContext) {
    Object.assign(ctx, transitionContext);
  }
  return ctx;
};

const transitionEndEventName = 'transitionEnd';

export const addTransitionEndListener = (node: HTMLDivElement, done: () => void): void => {
  node.addEventListener(transitionEndEventName, done, false);
};

export const dispatchTransitionEndEvent = (node: HTMLDivElement): void => {
  node.dispatchEvent(new Event(transitionEndEventName));
};
