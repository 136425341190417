import { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const variants = {
  h1: css`
    font-size: 80px;
  `,
  h2: css`
    font-size: 64px;
  `,
  h3: css`
    font-size: 48px;
  `,
  h4: css`
    font-size: 32px;
  `,
  h5: css`
    font-size: 24px;
  `,
  h6: css`
    font-size: 20px;
  `,
} as const;

type Prop = PropsWithChildren<{
  variant?: keyof typeof variants;
}>;

export const H: FC<Prop> = styled<'h5', Prop>('h5')
  .withConfig({
    shouldForwardProp: (propName) => !['variant'].includes(propName), // Dont forward `variant` prop to the dom
  })
  .attrs((p) => ({ as: p.variant }))`
  font-family: Nexa Text;
  letter-spacing: -0.03em;
  font-weight: 400;
  line-height: 1.2em;
  ${({ variant = 'h5' }) => variants[variant]}
`;

export const H1 = styled(H).attrs({ as: 'h1', variant: 'h1' })``;

export const H2 = styled(H).attrs({ as: 'h2', variant: 'h2' })``;

export const H3 = styled(H).attrs({ as: 'h3', variant: 'h3' })``;

export const H4 = styled(H).attrs({ as: 'h4', variant: 'h4' })``;

export const H5 = styled(H).attrs({ as: 'h5', variant: 'h5' })``;

export const H6 = styled(H).attrs({ as: 'h6', variant: 'h6' })``;
