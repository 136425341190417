import styled from 'styled-components';
import searchIcon from '@/assets/search.png';
import { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { AppSearchInput } from './AppSearchInput';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';
import { CloseButton, ModalCta, ModalTitle } from '@/components/modal/inner-components';
import { Button } from '@/components/button/Button';
import { SearchBarProps } from './SearchBar';

export const MobileSearchBar = (props: SearchBarProps): ReactNode => {
  const [showMobileInput, setShowMobileInput] = useState(false);
  return (
    <InputContainer>
      <img src={searchIcon} onClick={() => setShowMobileInput(true)} />
      <SearchModal
        show={showMobileInput}
        onRequestClose={() => setShowMobileInput(false)}
        {...props}
      />
    </InputContainer>
  );
};

const InputContainer = styled.label`
  display: flex;
  justify-content: stretch;
  align-items: center;
  img {
    cursor: pointer;
    display: block;
    flex-grow: 0;
  }
`;

export const AppSearchModalWindow = forwardRef(
  (
    { onRequestClose, onSubmit, value, onChange }: SearchBarProps & { onRequestClose?: () => void },
    forwardedRef,
  ): ReactNode => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      inputRef.current?.focus();
    }, []);
    const handleSubmit = useCallback(
      (text?: string) => {
        onRequestClose?.();
        onSubmit?.(text);
      },
      [onSubmit, onRequestClose],
    );
    return (
      <$ModalWindow ref={forwardedRef}>
        <CloseButton onClick={onRequestClose} />
        <ModalTitle>Search an app</ModalTitle>
        <$AppSearchInput ref={inputRef} value={value} onChange={onChange} onSubmit={handleSubmit} />
        <ModalCta>
          <Button onClick={() => handleSubmit(value)}>Search</Button>
        </ModalCta>
      </$ModalWindow>
    );
  },
);

const $ModalWindow = styled(ModalWindow)`
  width: calc(100% - 20px);
  min-width: 300px;
`;

const SearchModal = createModal(AppSearchModalWindow);

const $AppSearchInput = styled(AppSearchInput)`
  text-align: center;
`;
