import { ReactNode } from 'react';
import styled, { css, RuleSet } from 'styled-components';
import { P } from '../text/P';
import { getSearchPageUrl } from '@/routes/routePaths';
import { LinkButton } from '../button/Button';
import { TagMetadata } from '@/api/entities/tags';

type Variant = 'big' | 'small';

interface TagBadgeProps {
  tag: TagMetadata;
  variant?: Variant;
}

export const TagBadge = ({ tag, variant }: TagBadgeProps): ReactNode => {
  return <$Tag $variant={variant}>{tag.name}</$Tag>;
};

export const TagLink = ({ tag, variant }: TagBadgeProps): ReactNode => {
  return (
    <TagLinkView $variant={variant} to={getSearchPageUrl({ tags: [tag.slug] })}>
      {tag.name}
    </TagLinkView>
  );
};

const cssPerVariant: Record<Variant, RuleSet> = {
  big: css`
    padding: 0 24px;
    height: 50px;
  `,
  small: css`
    padding: 0 12px;
    height: 30px;
  `,
};

const tagCss = (variant?: Variant) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(40px);
  text-transform: uppercase;
  font-weight: normal;
  ${cssPerVariant[variant ?? 'big']}
`;

const $Tag = styled(P).attrs({ variant: 'md' })<{ $variant?: Variant }>`
  ${(p) => tagCss(p.$variant)}
`;

export const TagLinkView = styled(LinkButton)<{ $variant?: Variant }>`
  ${(p) => tagCss(p.$variant)}
`;
