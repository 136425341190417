import { forwardRef, ReactNode } from 'react';
import { LinkButton } from '@/components/button/Button';
import { ModalWindow } from '@/components/modal/ModalWindow';
import styled from 'styled-components';
import queueSvg from '@/assets/modals/queue.svg';
import { ModalTitle } from '@/components/modal/inner-components';
import { homePagePath } from '@/routes/routePaths';
import { P } from '@/components/text/P';
import { createModal } from '@/components/modal/hoc';

export const QueueIsFullErrorModalWindow = forwardRef((props, forwardedRef): ReactNode => {
  return (
    <ModalWindow ref={forwardedRef}>
      <Content>
        <ModalIcon src={queueSvg} alt="queue is full icon" />
        <ModalTitle>The queue is full</ModalTitle>
        <$P>
          Unfortunately the queue is full and you will not be able to access this game. Please
          return to the game selection to choose another or restart the game in a few moments
        </$P>
        <$LinkButton to={homePagePath}>Back to game selection</$LinkButton>
      </Content>
    </ModalWindow>
  );
});

export const QueueIsFullErrorModal = createModal(QueueIsFullErrorModalWindow);

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
  padding: 10px 30px;
`;

const $LinkButton = styled(LinkButton)`
  width: 100%;
`;

const ModalIcon = styled.img``;

const $P = styled(P)`
  opacity: 0.5;
  text-align: center;
`;
