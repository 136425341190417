import styled from 'styled-components';
import { Button } from '../button/Button';
import { H5 } from '../text/H';
import closePng from '@/assets/close.png';

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
  gap: 20px;
  h5 {
    color: white;
  }
  p {
    opacity: 0.75;
    text-align: center;
  }
`;

export const ModalCta = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const ModalCtaButton = styled(Button)`
  width: 80%;
`;

export const ModalTitle = styled(H5)`
  text-align: center;
`;

export const CloseButton = styled.img.attrs({ src: closePng })`
  display: block;
  position: absolute;
  padding: 8px;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }
`;
