import { TagMetadata } from '../entities/tags';
import { AuthTokenSet } from '../queries/utils';
import { hydrateApps, HydratedApp } from '../entities/hydrated-app';
import { fetchCatalog, fetchTags } from './catalog';
import { getTimeRemaining } from './timeKeeper';

export type HydratedCatalog = {
  catalog: HydratedApp[];
  tags: TagMetadata[];
};

export const fetchHydratedCatalog = async (auth: AuthTokenSet): Promise<HydratedCatalog> => {
  const [apps, durations, tags] = await Promise.all([
    fetchCatalog(auth),
    getTimeRemaining(auth, { staleTime: 10000 }),
    fetchTags(auth),
  ]);
  return { catalog: hydrateApps(apps, durations, tags), tags };
};
