import { ReactElement } from 'react';
import logoShadowPng from '@/assets/logo/logoShadow.png';
import { P } from '../text/P';
import styled from 'styled-components';
import { shadowWebsite } from '@/lib/shadowUrls';
import { Link } from 'react-router-dom';

export const Footer = (): ReactElement => {
  return (
    <$Footer>
      <Link to={shadowWebsite.homePageUrl}>
        <ShadowLogo />
      </Link>
      <P variant="sm">
        © 2024 <Link to={shadowWebsite.homePageUrl}>Shadow</Link> | Free Minded
      </P>
    </$Footer>
  );
};

const $Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-items: center;
  height: 55px;
  backdrop-filter: blur(5px);
  width: 100%;
`;

const ShadowLogo = styled.img.attrs({ src: logoShadowPng })`
  height: 18px;
  margin-bottom: 3px;
`;
