/* eslint-disable @typescript-eslint/no-unused-vars */

import mousePng from '@/assets/gameController/mouse-.png';
import arrowUpPng from '@/assets/gameController/arrow-up.png';
import arrowDownPng from '@/assets/gameController/arrow-down.png';
import bothClickPng from '@/assets/gameController/both-click.png';
import leftClickPng from '@/assets/gameController/left-click.png';
import x2Pvg from '@/assets/gameController/x2.svg';
import rightClickPng from '@/assets/gameController/right-click.png';
import { ReactNode } from 'react';
import { KeyMappingPanelTitle as Title, KeyboardMappingRow as Row } from './layout';

// TODO Figure out what we want for the mouse

export const MouseController = (): ReactNode => {
  return (
    <>
      <Title>Mouse</Title>
      <ul>{/* <Row keyVal={key} description={description} /> */}</ul>
    </>
  );
};
