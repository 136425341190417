import { Link } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import styled from 'styled-components';
import clockAlertSvg from '@/assets/clock-alert.svg';
import shadowAdPng from '@/assets/shadow-ad.png';
import { forwardRef, ReactNode } from 'react';
import { P } from '@/components/text/P';
import { shadowWebsite } from '@/lib/shadowUrls';
import { createModal } from '@/components/modal/hoc';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';

interface Props {
  onEndSessionButtonClick?: () => void;
}

export const TimeOutModalWindow = forwardRef(
  ({ onEndSessionButtonClick, ...props }: Props, forwardedRef): ReactNode => {
    return (
      <ModalWindow ref={forwardedRef} {...props}>
        <ModalContent>
          <img src={clockAlertSvg} alt="shutdown icon" />
          <ModalTitle>Oh no, No time left!</ModalTitle>
          <P variant="sm">
            Your free 10 minute game session is over, we hope you&apos;ve enjoyed the experience.
            You can still play other games by going back to the launcher.
          </P>
        </ModalContent>
        <ModalCta>
          <Button onClick={onEndSessionButtonClick}>Back to game selection</Button>
        </ModalCta>
        <DiscoverShadowBox>
          <img src={shadowAdPng} alt="discover Shadow PC" />
          <Link to={shadowWebsite.offersUrl}>
            <P variant="sm">Discover Shadow PC</P>
          </Link>
        </DiscoverShadowBox>
      </ModalWindow>
    );
  },
);

export const TimeOutModal = createModal(TimeOutModalWindow);

const DiscoverShadowBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  a {
    color: white;
    text-decoration: none;
  }
`;
