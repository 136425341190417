import shutdownSvg from '@/assets/modals/shutdown.svg';
import { Button } from '@/components/button/Button';
import { forwardRef, ReactNode } from 'react';
import { P } from '@/components/text/P';
import {
  CloseButton,
  ModalContent,
  ModalTitle,
  ModalCta,
} from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';

type Props = {
  onRequestClose?: (mustShutdown?: boolean) => void;
  className?: string;
};

export const AppShutDownConfirmationModalWindow = forwardRef(
  ({ onRequestClose, ...props }: Props, forwardRef): ReactNode => {
    return (
      <ModalWindow ref={forwardRef} {...props}>
        <CloseButton onClick={() => onRequestClose?.()} />
        <ModalContent>
          <img src={shutdownSvg} alt="shutdown icon" />
          <ModalTitle>End gaming&apos;s session</ModalTitle>
          <P variant="sm">
            The game you’re actually playing will turn off and you’ll go back to the Launcher. if
            you have any game time left, you can always restart the game to play it{' '}
          </P>
        </ModalContent>
        <ModalCta>
          <Button variant="red" onClick={() => onRequestClose?.(true)}>
            End session now
          </Button>
          <Button variant="transparent" onClick={() => onRequestClose?.()}>
            Cancel
          </Button>
        </ModalCta>
      </ModalWindow>
    );
  },
);

export const AppShutDownConfirmationModal = createModal(AppShutDownConfirmationModalWindow);
