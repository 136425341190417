import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { useLastDefined } from '@/lib/hooks/useLastDefined';

import { socisJwtLoader } from '@/api/loaders/socisJwtLoader';
import { getApiAuth } from '@/api/auth';
import { HydratedCatalog, fetchHydratedCatalog } from '@/api/services/fetchCatalogWithTimes';
import { searchApp } from '@/api/services/searchApp';
import { HydratedApp, hydrateApps } from '@/api/entities/hydrated-app';
import { SocisJwt } from '@/api/entities/socis-jwt';
import { getTimeRemaining } from '@/api/services/timeKeeper';

export const searchLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<
  HydratedCatalog & {
    socisJwt: SocisJwt;
    searchResult: HydratedApp[];
  }
> => {
  const queryText = params.queryText || undefined;
  const url = new URL(request.url);
  const tags = url.searchParams.getAll('tag');
  const filters = { queryText, tags };

  const { socisJwt } = await socisJwtLoader();
  const auth = await getApiAuth(socisJwt);
  const [hydratedCatalog, durations, searchResult] = await Promise.all([
    await fetchHydratedCatalog(auth),
    await getTimeRemaining(auth),
    await searchApp(auth, filters),
  ]);
  return {
    ...hydratedCatalog,
    socisJwt,
    searchResult: hydrateApps(searchResult, durations, hydratedCatalog.tags),
  };
};

export const useLoadedSearchResults = (): HydratedApp[] => {
  const loaderData = useLoaderData() as { searchResult?: HydratedApp[] };
  const lastLoaderData = useLastDefined(loaderData); // Transition-proof
  const { searchResult } = lastLoaderData;
  if (!searchResult) {
    throw Error('Expected loaded search resulsts');
  }
  return searchResult;
};
