import { z } from 'zod';

const baseEffectZod = z.object({
  initialX: z.number(),
  initialY: z.number(),
  name: z.string().optional(),
  description: z.string().optional(),
});

type IBaseEffectZod = z.ZodType<z.infer<typeof baseEffectZod>>;

const keyEffectZod = <E extends IBaseEffectZod>(effectZod: E) =>
  z.object({
    key: z.string().min(0),
    effect: effectZod,
    name: z.string().optional(),
    description: z.string().optional(),
  });

const distanceZod = z.object({
  distanceX: z.number(),
  distanceY: z.number(),
});

const keyEffectListZod = <E extends IBaseEffectZod>(effectZod: E) =>
  z.object({
    keys: z.array(keyEffectZod(effectZod)),
    name: z.string().optional(),
    description: z.string().optional(),
  });

export const keyMappingZod = z.object({
  dpad: z.array(keyEffectListZod(baseEffectZod.and(distanceZod))).optional(),
  tap: z.array(keyEffectZod(baseEffectZod)).optional(),
  swipe: z.array(keyEffectZod(baseEffectZod.and(distanceZod))).optional(),
});
