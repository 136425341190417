import { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';
import { v6 } from 'uuid';
import { SearchResultDisplayComponent } from './searchOptions';
import cardSvg from '@/assets/searchPage/card.svg';
import iconSvg from '@/assets/searchPage/icon.svg';

interface DisplayKindRadioGroupProps {
  value?: SearchResultDisplayComponent;
  onChange?: (val: SearchResultDisplayComponent) => void;
}

export const DisplayKindRadioGroup = ({
  value,
  onChange,
}: DisplayKindRadioGroupProps): ReactNode => {
  return (
    <$DisplayKindRadioToggle>
      <DisplayKindRadioInput
        thisValue={SearchResultDisplayComponent.Card}
        groupValue={value}
        iconSrc={cardSvg}
        onChange={onChange}
      />
      <DisplayKindRadioInput
        thisValue={SearchResultDisplayComponent.Thumbnail}
        groupValue={value}
        iconSrc={iconSvg}
        onChange={onChange}
      />
    </$DisplayKindRadioToggle>
  );
};

const $DisplayKindRadioToggle = styled.div`
  display: flex;
  height: 40px;
  label:first-child {
    border-radius: 20px 0 0 20px;
    padding-left: 15px;
  }
  label:last-child {
    border-radius: 0 20px 20px 0;
    padding-right: 15px;
  }
`;

const DisplayKindRadioInput = ({
  groupValue,
  onChange,
  thisValue,
  iconSrc,
}: {
  groupValue?: SearchResultDisplayComponent;
  onChange?: (val: SearchResultDisplayComponent) => void;
  thisValue: SearchResultDisplayComponent;
  iconSrc: string;
}) => {
  const id = useRef(v6()).current;
  const checked = thisValue === groupValue;
  return (
    <$label htmlFor={id} $checked={checked}>
      <img src={iconSrc} />
      <$radioInput checked={checked} onChange={() => onChange?.(thisValue)} id={id} />
    </$label>
  );
};

const $label = styled.label<{ $checked: boolean }>`
  height: 100%;
  display: flex;
  cursor: pointer;
  transition: background 0.3s;
  padding: 8px 10px;
  --idleBgColor: rgba(255, 255, 255, 0.1);
  --selectedBgColor: rgba(59, 104, 255, 1);
  ${(p) =>
    p.$checked
      ? css`
          background: var(--selectedBgColor);
        `
      : css`
          background: var(--idleBgColor);
          &:hover {
            background: color-mix(in hsl, var(--selectedBgColor), var(--idleBgColor));
          }
        `}
  img {
    display: block;
    width: 100%;
  }
`;

const $radioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
`;
