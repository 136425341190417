import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { ReactNode, useMemo } from 'react';
import { AppThumbnailScrollList } from './AppThumbnailScrollList';
import { sortBy } from 'lodash';

export const LastGameAddedScrollList = (): ReactNode => {
  const { catalog } = useLoadedCatalog();

  const lastGamesAdded = useMemo(
    () => sortBy(catalog, (game) => game.createdAt).reverse(),
    [catalog],
  );

  return <AppThumbnailScrollList title={'last games added'} catalog={lastGamesAdded} />;
};
