import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import { useDetectGPU } from '@react-three/drei';
import styled from 'styled-components';
import { useState, Suspense, ReactNode } from 'react';
import { sleep } from '@/lib/sleep';
import { Godray } from './Godray';
import { Particles } from './Particles';
import gradientSvg from '@/assets/gradient.svg';

export const AnimatedCanvasBackground = (): ReactNode => {
  // `useDetectGPU()` seems to suspend the component, so we need to wrap it.
  return (
    <Suspense fallback={null}>
      <Inner />
    </Suspense>
  );
};

const Inner = () => {
  const { tier } = useDetectGPU();
  return <Root>{tier > 0 ? <BubbleCanvas /> : <LowGpuFallbackBg />}</Root>;
};

const BubbleCanvas = () => {
  const { tier } = useDetectGPU();
  const [pointerOut, setPointerOut] = useState(false);
  const handlePointerOut = async () => {
    await sleep(0);
    setPointerOut(true);
  };
  const handlePointerMove = async () => {
    await sleep(0);
    if (pointerOut) {
      setPointerOut(false);
    }
  };
  return (
    <$Canvas
      dpr={[1, 1]}
      camera={{ fov: 50, position: [0, 0, 30], near: 0.1, far: 1000 }}
      onPointerOut={handlePointerOut}
      onPointerMove={handlePointerMove}
    >
      <color
        // eslint-disable-next-line react/no-unknown-property
        attach="background"
        // eslint-disable-next-line react/no-unknown-property
        args={['#1858e1']}
      />
      <Particles count={1000} />
      <Godray />
      {/* <MouseParticles pointerOut={pointerOut} count={isMobile ? 0 : 50} opacity={0.5} size={0.3} /> */}
      {/* <Bonus /> */}
      {tier > 2 && (
        <EffectComposer>
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.0001} mipmapBlur intensity={1} />
        </EffectComposer>
      )}
    </$Canvas>
  );
};

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: 2s fadeIn;
`;

const $Canvas = styled(Canvas)`
  position: absolute;
  opacity: 0.4;
  width: 100%;
  height: 100%;
`;

const LowGpuFallbackBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${gradientSvg});
  background-size: cover;
`;
