import { useLoadedCatalog } from '@/api/loaders/catalogLoader';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { HydratedApp } from '@/api/entities/hydrated-app';
import { appDisplaySidePadding } from './AppDisplay';
import { sampleSize } from 'lodash';
import { useStore } from '@/store/store';
import { useMatch } from 'react-router-dom';
import { homePagePath } from '@/routes/routePaths';

export const Slider = (): ReactNode => {
  const { catalog } = useLoadedCatalog();
  const homePageCarouselApp = useStore((state) => state.homePageCarouselApp);
  const setHomePageCarouselApp = useStore((state) => state.setHomePageCarouselApp);
  const isHomePagePath = Boolean(useMatch(homePagePath));

  const threeRandomApps = useMemo(() => {
    return sampleSize(catalog, 3);
  }, [catalog]);

  const [currentAppIndex, setCurrentAppIndex] = useState(0);

  useEffect(() => {
    setHomePageCarouselApp(threeRandomApps[currentAppIndex]?.uuid);

    const interval = setInterval(() => {
      setCurrentAppIndex((prevIndex) => (prevIndex + 1) % threeRandomApps.length);
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, [threeRandomApps, homePageCarouselApp, setHomePageCarouselApp, currentAppIndex]);

  if (!homePageCarouselApp || !isHomePagePath) {
    return null;
  }
  return (
    <$Slider>
      {threeRandomApps.map((app: HydratedApp, index) => (
        <Tab
          selected={currentAppIndex === index}
          key={app.nameSlug}
          onClick={() => setCurrentAppIndex(index)}
        />
      ))}
    </$Slider>
  );
};

const Tab = styled.button<{ selected: boolean }>`
  border: none;
  outline: none;
  width: 70px;
  height: 4px;
  background: ${({ selected }) => (selected ? '#3653CC' : '#575757')};
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    background: ${({ selected }) => (selected ? '#3653CC' : '#3653CC60')};
  }
  &:before {
    all: unset;
  }
`;

const $Slider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${appDisplaySidePadding}px;
  width: 100%;
  gap: 4px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 32px 0;
    margin-top: 150px;
  }
`;
