import styled, { css } from 'styled-components';

const variants = {
  xl: css`
    font-size: 20px;
  `,
  lg: css`
    font-size: 18px;
  `,
  md: css`
    font-size: 16px;
  `,
  sm: css`
    font-size: 14px;
  `,
  xs: css`
    font-size: 12px;
  `,
} as const;

interface Prop {
  variant?: keyof typeof variants;
}

export const P = styled<'p', Prop>('p').withConfig({
  shouldForwardProp: (propName) => !['variant'].includes(propName), // Dont forward `variant` prop to the dom
})`
  font-family: 'Nexa Text', sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.5em;
  ${({ variant = 'sm' }) => variants[variant]}
`;
