import { initial, last } from 'lodash';
import { create } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import { z } from 'zod';

export enum Status {
  Issue = 'Issue',
  Rating = 'Rating',
  Other = 'Other',
  Crash = 'Crash',
  Email = 'Email',
  Share = 'Share',
  Recommend = 'Recommend',
}

export interface FeedbackData {
  ticket_uuid: string;
  campaign_id: string;
  language: string;
  answers: Answer[];
}

export interface Answer {
  answerType: string;
  questionId: string;
  questionValue: string;
  answer: string | number | boolean | undefined;
}

interface FeedbackStore {
  status: Status;
  answers: Answer[];
  history: Status[];
  gaveFeedback: boolean;
  setStatus: (status: Status) => void;
  undoStatus: () => void;
  pushAnswer: (answer: Answer) => void;
  setGaveFeedback: (gaveFeedback: boolean) => void;
}

export const useFeedbackStore = create<FeedbackStore>()(
  persist(
    (set, get) => ({
      status: Status.Rating,
      answers: [],
      history: [],
      gaveFeedback: false,
      setStatus: (status: Status) => {
        const currentFeedbackState = get().status;
        set((state) => ({
          status,
          history: [...state.history, currentFeedbackState],
        }));
      },
      undoStatus: () => {
        const history = get().history;
        if (history.length > 0) {
          const previousState = last(history);
          set((state) => ({
            status: previousState!,
            history: initial(state.history),
            answers: initial(state.answers),
          }));
        }
      },
      pushAnswer: (answer: Answer) => set((state) => ({ answers: [...state.answers, answer] })),
      setGaveFeedback: (gaveFeedback: boolean) => set({ gaveFeedback }),
    }),
    {
      name: 'feedback-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        gaveFeedback: state.gaveFeedback,
      }),
      merge: (unsafePersistedState, currentState) => {
        const parsedState = z.object({ gaveFeedback: z.boolean() }).safeParse(unsafePersistedState);
        if (!parsedState.success) {
          return currentState;
        }
        return {
          ...currentState,
          gaveFeedback: parsedState.data.gaveFeedback,
        };
      },
    } satisfies PersistOptions<FeedbackStore, { gaveFeedback: boolean }>,
  ),
);
