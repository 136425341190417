import { DependencyList, useEffect, useRef } from 'react';

type Destructor = () => void;
type EffectCallback<> = (done: () => void) => Destructor | void;

// Like a UseEffect, but stops being activated after done() is called.
// The optional destructor is called only at component unmount.
export const useEffectUntil = (effect: EffectCallback, deps?: DependencyList): void => {
  const isDoneRef = useRef(false);
  const destructorRef = useRef<Destructor>();
  useEffect(() => {
    if (!isDoneRef.current) {
      const destructor = effect(() => {
        isDoneRef.current = true;
      });
      if (destructor) {
        destructorRef.current = destructor;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  useEffect(() => {
    return () => {
      destructorRef.current?.();
    };
  }, []);
};
