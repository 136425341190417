import { ReactNode, useMemo } from 'react';
import { KeyMappingPanelTitle as Title, KeyboardMappingRow as Row } from './layout';
import { chain } from 'lodash';
import { KeyMappingConfig } from '@genymotion/device-web-player';

interface KeyboardListProps {
  mapping: KeyMappingConfig;
}

export const KeyboardList = ({ mapping }: KeyboardListProps): ReactNode => {
  const rowProps = useMemo(
    () => [
      ...(mapping?.dpad || []).flatMap((keyEffectList) =>
        chain(keyEffectList.keys)
          .map((keyEffect) => ({
            key: keyEffect.key,
            description: keyEffect.name || keyEffect.description || '',
          }))
          .flatten()
          .value(),
      ),
      ...(mapping?.tap || []).map((keyEffect) => ({
        key: keyEffect.key,
        description: keyEffect.name || keyEffect.description || '',
      })),
      ...(mapping?.swipe || []).map((keyEffect) => ({
        key: keyEffect.key,
        description: keyEffect.name || keyEffect.description || '',
      })),
    ],
    [mapping],
  );

  return (
    <>
      <Title>Keyboard</Title>
      <ul>
        {rowProps.map(({ key, description }) => (
          <Row key={key} keyVal={key} description={description} />
        ))}
      </ul>
    </>
  );
};
