import { Cylinder } from '@react-three/drei';

import { ReactNode } from 'react';
import { MyShaderMaterial } from './materials/GodRay';

export const Godray = (): ReactNode => {
  return (
    <Cylinder
      args={[1, 0, 2, 248, 128, true]}
      position={[-7, 2, 0]}
      rotation={[Math.PI, 0, -0.8]}
      scale={30}
    >
      <MyShaderMaterial />
    </Cylinder>
  );
};
