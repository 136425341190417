import { ReactNode } from 'react';
import styled from 'styled-components';

// Use this page to test your component.
// Please reset it before commiting your feature.
export const TestPage = (): ReactNode => {
  return <Root></Root>;
};

const Root = styled.div``;
