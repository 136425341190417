import { useStore } from '@/store/store';

const phoneUserAgents = [
  'iPhone', // IPhones automatically deny fullscreen privileges.
  'Android,', // Android fullscreen has a issue that shifts the input positions.
];

export const userAgentNeedsFalseFullscreen = Boolean(
  phoneUserAgents.find((agent) => agent === navigator.userAgent),
);

/**
 * const [fullScreen, falseFullScreen, trueFullScreen] = useFullScreen();
 */
export const useFullScreen = (): [boolean, boolean, boolean] => {
  const fullScreen = Boolean(useStore((s) => s.fullScreen));
  return [
    fullScreen,
    fullScreen && !userAgentNeedsFalseFullscreen,
    fullScreen && userAgentNeedsFalseFullscreen,
  ];
};
