import {
  ExperienceWithState,
  isBillableExperience,
  isAssociatedExperience,
} from '@/api/entities/experience';
import { useStore } from '@/store/store';
import { useCallback } from 'react';

export const hasExperienceViableStatus = (experience: ExperienceWithState): boolean =>
  isBillableExperience(experience) || isAssociatedExperience(experience);

// A `viable` experience is an experience that meets the following conditions:
// - Is in Billable or Associated status
// - Has not been deleted
//
// We need to keep track of the recently deleted experiences because the server
// does not instantly report an experience as `terminating` after we send the DELETE instruction.

type IsExperienceViable = (experience: ExperienceWithState) => boolean;

export const useIsExperienceViable = (): IsExperienceViable => {
  const wasExperienceDeleted = useStore((s) => s.wasExperienceDeleted);
  return useCallback(
    (experience: ExperienceWithState) =>
      !wasExperienceDeleted(experience.ticketId) && hasExperienceViableStatus(experience),
    [wasExperienceDeleted],
  );
};
