import { useState, ReactNode } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import thumbUpPng from '@/assets/feedback/thumb-up.png';
import thumbDownPng from '@/assets/feedback/thumb-down.png';
import { useFeedbackStore, Status, Answer } from './store/store';
import { Button } from '@/components/button/Button';
import { P } from '@/components/text/P';

export const Recommend = (): ReactNode => {
  const { setStatus, pushAnswer } = useFeedbackStore();

  enum Recommend {
    Yes = 'Yes',
    No = 'No',
  }

  const [doYouRecommend, setDoYouRecommend] = useState<Recommend>(Recommend.No);

  const answer: Answer = {
    answerType: 'bool',
    questionId: 'recommend',
    questionValue: 'Do you recommend this game?',
    answer: doYouRecommend.toLowerCase(),
  };

  const onClickHandler = () => {
    pushAnswer(answer);
    if (doYouRecommend === Recommend.Yes) {
      setStatus(Status.Email);
    } else {
      setStatus(Status.Share);
    }
  };

  return (
    <>
      <ButtonContainer>
        <ChoiceButton>
          <ImgContainer
            className={clsx(doYouRecommend === Recommend.Yes && 'selected')}
            onClick={() => setDoYouRecommend(Recommend.Yes)}
          >
            <img src={thumbUpPng} alt="thumb up" />
          </ImgContainer>
          <P variant="xl">YES</P>
        </ChoiceButton>
        <ChoiceButton>
          <ImgContainer
            className={clsx(doYouRecommend === Recommend.No && 'selected')}
            onClick={() => setDoYouRecommend(Recommend.No)}
          >
            <img src={thumbDownPng} alt="thumb down" />
          </ImgContainer>
          <P variant="xl">NO</P>
        </ChoiceButton>
      </ButtonContainer>
      <Button onClick={onClickHandler}>Submit</Button>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ChoiceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  .selected {
    background: #3654cc;
  }
  p {
    font-weight: 600;
    color: white;
    opacity: 1;
  }
`;

const ImgContainer = styled.div`
  background: #3654cc50;
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  &:hover {
    background: #3654cc;
  }
`;
