import { ReactElement } from 'react';
import { router } from './routes/Route';
import { RouterProvider } from 'react-router-dom';
import { ShadowAuthProvider } from '@/components/Auth/ShadowAuthProvider';
import { F2pQueryClientProvider } from '@/api/queryClient';

export default function App(): ReactElement {
  return (
    <F2pQueryClientProvider>
      <ShadowAuthProvider>
        <RouterProvider router={router} />
      </ShadowAuthProvider>
    </F2pQueryClientProvider>
  );
}
