import { ReactNode, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useStore } from '@/store/store';
import { VirtualMachine } from './VirtualMachine';
import { Sidebar } from './Sidebar';
import { AppShutDownConfirmationModal } from './Modals/AppShutDownConfirmationModal';
import crossPng from '@/assets/cross.png';
import { useAfkDetection } from './useAfkDetection';
import { useFullScreen, userAgentNeedsFalseFullscreen } from '@/lib/userAgentNeedsFalseFullscreen';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { useDeleteTicketAndExperience } from '../useDeleteTicketAndExperience';
import { homePagePath } from '@/routes/routePaths';
import { useNavigate } from 'react-router-dom';
import { AfkModal } from './Modals/AfkModal';
import { TimeOutModal } from './Modals/TimeOutModal';
import { ExperienceErrorModal } from './Modals/ExperienceErrorModal';
import { isExperienceConsideredTimedout, timeoutAnticipationTimeSec } from '../timeout';
import { useGetExperience } from '@/api/services/timeKeeper';
import { ExperienceStatus } from '@/api/entities/experience';

export const VirtualMachinePage = (): ReactNode => {
  const isMobile = useIsMobile();
  const [, falseFullScreen] = useFullScreen();
  usePeriodicRefreshExperienceStateInStore();
  return (
    <Root $isMobile={isMobile}>
      <VirtualMachine />
      {falseFullScreen ? <ExitFullscreenButton /> : <Sidebar />}
      <TimeoutModalController />
      <AfkController />
      <ShutDownConfirmationModalController />
      <ExperienceErrorModalController />
    </Root>
  );
};

const heartbeatIntervalSec = Math.min(30, timeoutAnticipationTimeSec);

const usePeriodicRefreshExperienceStateInStore = () => {
  const experience = useStore((s) => s.experience);
  const setExperienceState = useStore((s) => s.setExperienceState);
  const experienceQuery = useGetExperience(experience?.ticketId ?? '', {
    refetchInterval: heartbeatIntervalSec * 1000,
    enabled: Boolean(experience),
    staleTime: heartbeatIntervalSec * 1000,
  });
  useEffect(() => {
    if (experienceQuery.data) {
      setExperienceState(experienceQuery.data.state);
    }
  }, [experience, experienceQuery.data, setExperienceState]);
};

const ExitFullscreenButton = () => {
  const setFullScreen = useStore((s) => s.setFullScreen);
  const exitFullScreen = () => {
    setFullScreen(false);
  };
  return (
    <$ExitFullscreenButton className="exitFullScreen" onClick={exitFullScreen}>
      <img src={crossPng} />
    </$ExitFullscreenButton>
  );
};

const TimeoutModalController = () => {
  const navigate = useNavigate();
  const deleteTicketAndExperience = useDeleteTicketAndExperience();
  const setFullScreen = useStore((s) => s.setFullScreen);
  const handleEndSession = () => {
    navigate(homePagePath);
    deleteTicketAndExperience();
  };
  const experienceState = useStore((s) => s.experienceState);
  const isTimeout = experienceState && isExperienceConsideredTimedout(experienceState);
  if (isTimeout) {
    if (!userAgentNeedsFalseFullscreen) {
      document.exitFullscreen();
    }
    setFullScreen(false);
  }
  return <TimeOutModal show={isTimeout} onEndSessionButtonClick={handleEndSession} />;
};

const AfkController = () => {
  useAfkDetection();
  const navigate = useNavigate();
  const afk = useStore((s) => s.afk);
  const afkDisconnect = useStore((s) => s.afkDisconnect);
  const handleEndSessionClick = useCallback(() => {
    navigate(homePagePath);
  }, [navigate]);
  return (
    <AfkModal
      show={afk || afkDisconnect}
      isAfkDisconnected={afkDisconnect}
      onEndSessionButtonClick={handleEndSessionClick}
    />
  );
};

const ExperienceErrorModalController = () => {
  const navigate = useNavigate();
  const afkDisconnect = useStore((s) => s.afkDisconnect);
  const experienceState = useStore((s) => s.experienceState);
  const isExperienceTerminated = experienceState?.status === ExperienceStatus.Terminated;
  const isTimeout = experienceState && isExperienceConsideredTimedout(experienceState);
  return (
    <ExperienceErrorModal
      show={!isTimeout && !afkDisconnect && isExperienceTerminated}
      onEndButtonClick={() => navigate(homePagePath)}
      reason={experienceState?.terminationReason}
    />
  );
};

const ShutDownConfirmationModalController = () => {
  const afkDisconnect = useStore((s) => s.afkDisconnect);
  const experienceState = useStore((s) => s.experienceState);
  const showShutDownConfirmationModal = useStore((s) => s.showShutDownConfirmationModal);
  const setShowShutDownConfirmationModal = useStore((s) => s.setShowShutDownConfirmationModal);
  const deleteTicketAndExperience = useDeleteTicketAndExperience();
  const navigate = useNavigate();
  const isExperienceTerminated = experienceState?.status === ExperienceStatus.Terminated;
  return (
    <AppShutDownConfirmationModal
      show={Boolean(!isExperienceTerminated && !afkDisconnect && showShutDownConfirmationModal)}
      onRequestClose={(mustShutdown) => {
        setShowShutDownConfirmationModal(false);
        if (mustShutdown) {
          navigate(homePagePath);
          deleteTicketAndExperience();
        }
      }}
    />
  );
};

const Root = styled.main<{ $isMobile?: boolean }>`
  display: flex;
  flex-grow: 1;
  min-width: 0; // Prevent overflow of children (flex never dares to shrink its own children)
  min-height: 0; // Prevent overflow of children (flex never dares to shrink its own children)
  ${(props) =>
    props.$isMobile &&
    css`
      flex-direction: column;
    `}
`;

const $ExitFullscreenButton = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  right: 25px;
  pointer-events: all;
  background: white;
  border-radius: 50%;
  padding: 10px;
  img {
    width: 28px;
  }
`;
