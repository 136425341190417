import { ReactNode } from 'react';
import { Background } from '@/components/Background/Background';
import { Outlet } from 'react-router-dom';
import { useStore } from '@/store/store';
import { Menu } from '@/components/Header/Menu';

export const RootLayout = (): ReactNode => {
  const showBackground = useStore((s) => s.showBackground);
  return (
    <>
      {showBackground && <Background />}
      <Menu />
      <Outlet />
    </>
  );
};
