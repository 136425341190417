import { AppUuid } from '../entities/app';
import { createHeaders, AuthTokenSet } from './utils';
import { env } from '@/env';
import { TicketWithState, TicketId, parseTicket } from '../entities/ticket';

export type FetchTicketResponse = TicketWithState;

export const createTicket = async (
  auth: AuthTokenSet,
  appUuid: AppUuid,
): Promise<FetchTicketResponse> => {
  const response = await fetch(`${env.reservationUrl}v1/ticket/`, {
    headers: createHeaders(auth),
    method: 'POST',
    body: JSON.stringify({ region: 'fr', application_uuid: appUuid }),
  });
  if (!response.ok) {
    throw response;
  }
  const json = await response.json();
  return parseTicket(json);
};

export const getTicket = async (
  auth: AuthTokenSet,
  ticketId: TicketId,
): Promise<FetchTicketResponse> => {
  const response = await fetch(`${env.reservationUrl}v1/ticket/${ticketId}/`, {
    headers: createHeaders(auth),
    method: 'GET',
  });
  if (!response.ok) {
    throw response;
  }
  const json = await response.json();
  return parseTicket(json);
};

export const deleteTicket = async (auth: AuthTokenSet, ticketId: TicketId): Promise<void> => {
  const url = `${env.reservationUrl}v1/ticket/${ticketId}/`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: createHeaders(auth),
  });
  if (!response.ok) {
    throw response;
  }
};
