import { useLoaderData } from 'react-router-dom';
import { socisJwtLoader } from './socisJwtLoader';
import { useLastDefined } from '@/lib/hooks/useLastDefined';
import { TagMetadata } from '../entities/tags';
import { getApiAuth } from '../auth';
import { fetchHydratedCatalog } from '../services/fetchCatalogWithTimes';
import { HydratedApp } from '../entities/hydrated-app';
import { SocisJwt } from '../entities/socis-jwt';

export const catalogLoader = async (): Promise<{
  catalog: HydratedApp[];
  socisJwt: SocisJwt;
  tags: TagMetadata[];
}> => {
  const { socisJwt } = await socisJwtLoader();
  const auth = await getApiAuth(socisJwt);
  const { catalog, tags } = await fetchHydratedCatalog(auth);
  return { catalog, socisJwt, tags };
};

export const useLoadedCatalog = (): { catalog: HydratedApp[]; tags: TagMetadata[] } => {
  const loaderData = useLoaderData() as { catalog?: HydratedApp[]; tags?: TagMetadata[] };
  const lastLoaderData = useLastDefined(loaderData); // Transition-proof

  const { catalog, tags } = lastLoaderData;

  if (!catalog || !tags) {
    throw new Error('Expected loaded catalogs and tags');
  }

  return { catalog, tags };
};
