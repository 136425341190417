import { H4 } from '@/components/text/H';
import { P } from '@/components/text/P';
import { ReactNode, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import styled from 'styled-components';
import errorSvg from '@/assets/error.svg';
import { LinkButton } from '@/components/button/Button';
import { homePagePath } from '@/routes/routePaths';
import { isHttpError } from '@/lib/isHttpError';
import { env } from '@/env';

export const ErrorPage = (): ReactNode => {
  const error = useRouteError();
  useEffect(() => {
    if (env.isStoryBook) {
      return;
    }
    console.error(error);
  }, [error]);

  const { title, message } = createPropsFromThrownValue(error);

  return (
    <Root>
      <ErrorImg />
      <StatusText>{title}</StatusText>
      {message && <Message>{message}</Message>}
      <BackToHomeButton />
    </Root>
  );
};

interface ErrorPageProps {
  title: string;
  message?: string;
}

const devAppendage = (error: Error) => {
  if (error.message === 'Failed to fetch') {
    return '. Did you turn on your Shadow VPN? 😉';
  }
  return undefined;
};

const createPropsFromThrownValue = (error: unknown): ErrorPageProps => {
  if (isHttpError(error)) {
    return {
      title: `${error.status} ${error.statusText}`,
      message: httpCodeToMessage[error.status],
    };
  }
  if (error instanceof Error) {
    return {
      title: error.name || 'Error',
      message: `${error.message}${(env.isDev && devAppendage(error)) || ''}`,
    };
  }
  return {
    title: 'An unknown error happened',
  };
};

const httpCodeToMessage: Record<number, string> = {
  [404]: 'Looks like this page does not exist, sorry... 😳',
  [500]: 'Something went wrong in our servers, sorry... 😳',
  [503]: 'Seems like our servers are not available at the moment, sorry... 😳',
};

const BackToHomeButton = () => {
  return <$BackToHomeLink to={homePagePath}>Back to homepage</$BackToHomeLink>;
};

const Root = styled.div`
  margin-top: 20px;
  width: 500px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusText = styled(H4)`
  text-align: center;
`;

const Message = styled(P).attrs({ variant: 'lg' })`
  margin-top: 20px;
  text-align: center;
`;

const ErrorImg = styled.img.attrs({ src: errorSvg })`
  display: block;
  height: 100px;
  margin: 20px;
`;

const $BackToHomeLink = styled(LinkButton)`
  margin-top: 50px;
`;
