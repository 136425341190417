import styled, { css } from 'styled-components';
import { Dispatch, forwardRef, ReactNode, useEffect, useState } from 'react';
import { P } from '@/components/text/P';
import shutdownSvg from '@/assets/modals/shutdown.svg';
import { Button } from '@/components/button/Button';
import { useNavigate } from 'react-router-dom';
import { getAppRunPageUrl, homePagePath } from '@/routes/routePaths';
import { AppLink } from './AppLink';
import { useHover } from '@uidotdev/usehooks';
import { Badge } from './Badge';
import { AppMetadata } from '@/api/entities/app';
import { ModalContent, ModalTitle, ModalCta } from '@/components/modal/inner-components';
import { ModalWindow } from '@/components/modal/ModalWindow';
import { createModal } from '@/components/modal/hoc';

interface Props {
  runningApp: AppMetadata;
  candidateApp: AppMetadata;
  onCandidateAppChosen?: () => void;
}

type HoveredChoice = 'running' | 'candidate' | undefined;

export const AlreadyRunningAppModalWindow = forwardRef(
  ({ runningApp, candidateApp, onCandidateAppChosen }: Props, forwardedRef): ReactNode => {
    const [hoveredChoice, setHoveredChoice] = useState<HoveredChoice>();
    return (
      <ModalWindow ref={forwardedRef}>
        <ModalContent>
          <ModalTitle>Hm, you already have a game running...</ModalTitle>
          <Explaination>What do we do, boss?</Explaination>
          <RunningAppLink
            app={runningApp}
            hoveredChoice={hoveredChoice}
            setHoveredChoice={setHoveredChoice}
          />
          <CandidateAppLink
            onClick={onCandidateAppChosen}
            app={candidateApp}
            hoveredChoice={hoveredChoice}
            setHoveredChoice={setHoveredChoice}
          />
        </ModalContent>
        <ModalCta>
          <BackButton />
        </ModalCta>
      </ModalWindow>
    );
  },
);

export const AlreadyRunningAppModal = createModal(AlreadyRunningAppModalWindow);

const Explaination = styled(P).attrs({ variant: 'md' })`
  text-align: center;
`;

const RunningAppLink = ({
  app,
  hoveredChoice,
  setHoveredChoice,
}: {
  app: AppMetadata;
  hoveredChoice: HoveredChoice;
  setHoveredChoice: Dispatch<HoveredChoice>;
}) => {
  const [ref, isHover] = useHover();
  useEffect(() => {
    setHoveredChoice(isHover ? 'running' : undefined);
  }, [isHover, setHoveredChoice]);
  return (
    <RunningAppLinkContainer ref={ref}>
      <ShutdownBadge show={hoveredChoice === 'candidate'} />
      <$RunningAppLink
        to={getAppRunPageUrl(app.nameSlug)}
        app={app}
        text={hoveredChoice === 'candidate' ? 'Shutdown' : 'Return to'}
        $greyedOut={hoveredChoice === 'candidate'}
      />
    </RunningAppLinkContainer>
  );
};

const RunningAppLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const $RunningAppLink = styled(AppLink)<{ $greyedOut?: boolean }>`
  ${(p) =>
    p.$greyedOut &&
    css`
      opacity: 0.5;
      filter: grayscale(0.8);
    `}
`;

const CandidateAppLink = ({
  app,
  hoveredChoice,
  setHoveredChoice,
  onClick,
}: {
  app: AppMetadata;
  hoveredChoice: HoveredChoice;
  setHoveredChoice: Dispatch<HoveredChoice>;
  onClick?: () => void;
}) => {
  const [ref, isHover] = useHover();
  useEffect(() => {
    setHoveredChoice(isHover ? 'candidate' : undefined);
  }, [isHover, setHoveredChoice]);
  return (
    <$CandidateAppLink
      ref={ref}
      app={app}
      onClick={onClick}
      text="Play"
      $greyedOut={hoveredChoice === 'running'}
    />
  );
};

const $CandidateAppLink = styled(AppLink)<{ $greyedOut?: boolean }>`
  ${(p) =>
    p.$greyedOut &&
    css`
      opacity: 0.5;
      filter: grayscale(0.8);
    `}
`;

const ShutdownBadge = ({ show }: { show: boolean }) => <Badge imgSrc={shutdownSvg} show={show} />;

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <$BackButton onClick={() => navigate(homePagePath)}>Back to the game selection</$BackButton>
  );
};

const $BackButton = styled(Button).attrs({ variant: 'transparent' })``;
