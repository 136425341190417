import { createRef, ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import { useStore } from '@/store/store';
import { SwitchTransition, Transition } from 'react-transition-group';
import gsap from 'gsap';
import { useSingletonInMap } from '@/lib/hooks/useSingletonInMap';

const transitionDurationSec = 0.5;

export const AppVideoBackground = (): ReactNode => {
  const videoBackgroundSrc = useStore((s) => s.videoBackgroundSrc);
  const nodeRef = useSingletonInMap<RefObject<HTMLDivElement>>(videoBackgroundSrc ?? '', createRef);
  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={videoBackgroundSrc ?? ''}
        timeout={transitionDurationSec * 1000}
        nodeRef={nodeRef}
        onEnter={() => {
          gsap.from(nodeRef.current, {
            opacity: 0,
            duration: transitionDurationSec,
          });
        }}
        onExit={() => {
          gsap.to(nodeRef.current, {
            opacity: 0,
            duration: transitionDurationSec,
          });
        }}
      >
        <Root ref={nodeRef}>
          {videoBackgroundSrc && (
            <>
              <GradientLayer />
              <Video autoPlay={videoAutoplay} muted loop playsInline>
                <source src={videoBackgroundSrc} type="video/mp4" />
              </Video>
              <BottomGradientLayer />
            </>
          )}
        </Root>
      </Transition>
    </SwitchTransition>
  );
};

const videoAutoplay = !localStorage.getItem('disable-app-video-autoplay');

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Video = styled.video`
  width: 80%;
  height: 80vh;
  object-fit: cover;
  opacity: 1;
  filter: brightness(0.8);
  opacity: 0.8;
  mix-blend-mode: luminosity;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const GradientLayer = styled.div`
  background: linear-gradient(92.88deg, #101322 29.05%, rgba(16, 19, 34, 0) 85.64%);
  height: 80vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const BottomGradientLayer = styled.div`
  background: linear-gradient(0deg, #10132200 0%, #101322 50%, #10132200 100%);
  height: 20vh;
  width: 100%;
  position: absolute;
  top: 70vh;
`;
