import { createRef, ReactNode } from 'react';
import { FeedbackRoot } from './feedback/FeedbackRoot';
import { FadeInOutSwitchTransition } from '@/components/animation/FadeInOutSwitchTransition';
import styled from 'styled-components';
import {
  OutletContext,
  useSwitchTransitionOutlet,
} from '@/components/animation/SwitchTransitionOutlet';
import { useLocation } from 'react-router-dom';
import { useSingletonInMap } from '@/lib/hooks/useSingletonInMap';
import { useShowBackground } from '@/components/Background/background-hooks';
import { Slider } from './Slider';
import { QuickAccess } from './QuickAccess';
import { Footer } from '@/components/Footer/Footer';
import { useIsMobile } from '@/lib/hooks/useIsMobile';
import { RunningExperienceToastController } from '@/components/RunningExperienceToast/RunningExperienceToast';
import { TutorialModal } from './TutorialModal';
import { useStore } from '@/store/store';
import { LastGameAddedScrollList } from './LastGamesAddedScrollList';
import { MostPopularScrollList } from './MostPopularScrollList';
import { LastGamePlayedScrollList } from './LastGamePlayedScrollList';
import { TagLinkScrollList } from './TagLinkScrollList';
import { AppVideoBackground } from '@/components/Background/AppVideoBackground/AppVideoBackground';
import { AppCardsInScroll } from './appCards/AppCardsInScroll';

export const HomePageLayout = (): ReactNode => {
  useShowBackground();
  const isMobile = useIsMobile();
  const pathname = useLocation().pathname;
  const outletContext = useSingletonInMap<OutletContext>(pathname, () => ({
    ref: createRef(),
  }));
  const outlet = useSwitchTransitionOutlet(outletContext);
  return (
    <>
      <Home>
        <AppVideoBackground />
        <OutletContainer>
          <FadeInOutSwitchTransition mode="out-in" outletContext={outletContext}>
            <PositionAbsolute>{outlet}</PositionAbsolute>
          </FadeInOutSwitchTransition>
        </OutletContainer>
        <Content>
          <Slider />
          <AppCardsInScroll />
          <TagLinkScrollList />
          <AppScrollLists>
            <LastGamePlayedScrollList />
            <MostPopularScrollList />
            <LastGameAddedScrollList />
          </AppScrollLists>
          <QuickAccess />
          <Footer />
        </Content>
        <TutorialModalController />
      </Home>
      {!isMobile && <RunningExperienceToastController />}
      <FeedbackRoot />
    </>
  );
};

const TutorialModalController = () => {
  const hideTutorial = useStore((s) => s.hideTutorial);
  const setHideTutorial = useStore((s) => s.setHideTutorial);

  return <TutorialModal show={!hideTutorial} onRequestClose={() => setHideTutorial(true)} />;
};

const Home = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const OutletContainer = styled.div`
  position: relative;
  flex: 1;
  align-self: stretch;
  min-height: 350px;
`;

const PositionAbsolute = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const AppScrollLists = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: center;
`;
