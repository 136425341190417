import { StateCreator } from 'zustand';
import { z } from 'zod';
import { CombinedStoreSlices } from './store';
import { PersistSlice } from './types';
import {
  SearchResultDisplayOptions,
  searchResultDisplayOptionsZod,
} from '@/pages/search/searchOptions';
import { Dispatch, SetStateAction } from 'react';
import { isFunction } from 'lodash';
import { SocisJwt, socisJwtZod } from '@/api/entities/socis-jwt';

export interface MainStoreSlice {
  socisJwt?: SocisJwt; // TODO Move to httpOnly Cookie once the backend can serve it that way
  showBackground?: boolean;
  videoBackgroundSrc?: string;
  hideTutorial?: boolean;
  searchResultDisplayOptions?: SearchResultDisplayOptions;
  setJwt: (socisJwt: SocisJwt | undefined) => void;
  setShowBackground: (showBackground: boolean) => void;
  setVideoBackgroundSrc: (videoBackgroundSrc: string | undefined) => void;
  setHideTutorial: (hideTutorial: boolean) => void;
  setSearchResultDisplayOptions: Dispatch<
    SetStateAction<MainStoreSlice['searchResultDisplayOptions']>
  >;
}

const create: StateCreator<CombinedStoreSlices, [], [], MainStoreSlice> = (set) => ({
  setJwt: (socisJwt) => set({ socisJwt }),
  setShowBackground: (showBackground) => set({ showBackground }),
  setVideoBackgroundSrc: (videoBackgroundSrc) => set({ videoBackgroundSrc }),
  setHideTutorial: (hideTutorial) => set({ hideTutorial }),
  setSearchResultDisplayOptions: (val) =>
    set(({ searchResultDisplayOptions: state }) => ({
      searchResultDisplayOptions: isFunction(val) ? val(state) : val,
    })),
});

export type MainStorePersistedState = {
  socisJwt?: SocisJwt;
  hideTutorial?: boolean;
  searchResultDisplayOptions?: SearchResultDisplayOptions;
};

// Select the fields we want to persist into the localStorage
const partialize = ({
  socisJwt,
  hideTutorial,
  searchResultDisplayOptions,
}: CombinedStoreSlices): MainStorePersistedState => ({
  socisJwt,
  hideTutorial,
  searchResultDisplayOptions,
});

const storePersistedStateZod = z
  .object({
    socisJwt: socisJwtZod.optional(),
    hideTutorial: z.boolean().optional(),
    searchResultDisplayOptions: searchResultDisplayOptionsZod.optional(),
  })
  .optional();

// Validate the persistant storage and merge it to the current state.
const merge = (
  unsafePersistedState: unknown,
  currentState: CombinedStoreSlices,
): CombinedStoreSlices => {
  const parseResult = storePersistedStateZod.safeParse(unsafePersistedState);
  if (!parseResult.success) {
    console.warn(`Invalid persistant store: ${parseResult.error.message}`);
    return currentState;
  }
  if (!parseResult.data) {
    // No storage available
    return currentState;
  }
  const persistantState = parseResult.data;

  return {
    ...currentState,
    socisJwt: persistantState.socisJwt,
    hideTutorial: persistantState.hideTutorial,
    searchResultDisplayOptions: persistantState.searchResultDisplayOptions,
  };
};

export const mainStorePersistOptions: PersistSlice<
  CombinedStoreSlices,
  MainStoreSlice,
  MainStorePersistedState
> = { create, partialize, merge };
