import { ReactNode, forwardRef, ReactElement, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

type OverlayProps = {
  className?: string;
  children?: ReactNode;
};

// Overlay that covers the user's viewport.
export const ModalOverlay = forwardRef(
  ({ className, children }: OverlayProps, forwardedRef): ReactElement | null => {
    const overlayRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => overlayRef.current);
    return (
      <Root ref={overlayRef} className={className}>
        {children}
      </Root>
    );
  },
);

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #10132294;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  pointer-events: all;
  backdrop-filter: blur(20px);
`;
